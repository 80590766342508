define("pixeditor/components/pop-in/logout", ["exports", "@glimmer/component", "@sentry/ember"], function (_exports, _component, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
    @title="Déconnexion"
    @onCloseButtonClick={{@onDeny}}
  >
    <:content>
      <p data-test-logout-message >Voulez-vous vraiment vous déconnecter ?</p>
    </:content>
    <:footer>
      <PixButton data-test-logout-cancel-button
                 @backgroundColor="transparent-light"
                 @isBorderVisible={{true}}
                 @triggerAction={{@onDeny}}
      >
        Non
      </PixButton>
      <PixButton data-test-logout-ok-button
                 @triggerAction={{this.logout}}
      >
        Oui
      </PixButton>
    </:footer>
  </PixModal>
  */
  {
    "id": "LBZThe0y",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[\"Déconnexion\",[30,1]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[14,\"data-test-logout-message\",\"\"],[12],[1,\"Voulez-vous vraiment vous déconnecter ?\"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-logout-cancel-button\",\"\"]],[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,1]]],[[\"default\"],[[[[1,\"\\n      Non\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-logout-ok-button\",\"\"]],[[\"@triggerAction\"],[[30,0,[\"logout\"]]]],[[\"default\"],[[[[1,\"\\n      Oui\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@onDeny\"],false,[\"pix-modal\",\"pix-button\"]]",
    "moduleName": "pixeditor/components/pop-in/logout.hbs",
    "isStrictMode": false
  });

  let LogoutComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class LogoutComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "window", _descriptor, this);

      _initializerDefineProperty(this, "auth", _descriptor2, this);
    }

    logout() {
      event.preventDefault();
      this.auth.key = undefined;
      Sentry.configureScope(scope => scope.setUser(null));
      this.window.reload();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "window", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "logout", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "logout"), _class.prototype)), _class));
  _exports.default = LogoutComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LogoutComponent);
});