define("pixeditor/controllers/competence-management/new", ["exports", "@sentry/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompetenceManagementNewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class CompetenceManagementNewController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "idGenerator", _descriptor, this);

      _initializerDefineProperty(this, "loader", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _defineProperty(this, "creation", true);
    }

    get competence() {
      return this.model.competence;
    }

    cancelEdit() {
      this.store.deleteRecord(this.competence);
      this.edition = false;
      this.notify.message('Création de la compétence annulée');
      this.router.transitionTo('index');
    }

    async save() {
      const area = this.model.area;

      try {
        const framework = await area.framework;
        this.loader.start();
        await this._createCompetence(area);
        this.notify.message('Compétence créée');
        await this._createWorkbench(framework.name);
        this.notify.message('Atelier créé');
        this.edition = false;
        this.router.transitionTo('competence.skills', this.competence, {
          queryParams: {
            view: 'workbench'
          }
        });
      } catch (error) {
        _ember.default.captureException(error);

        console.log(error);
        this.notify.error('Erreur lors de la création de la compétence');
      } finally {
        this.loader.stop();
      }
    }

    async _createCompetence(area) {
      this.competence.area = area;
      await this.competence.save();
    }

    async _createWorkbench(frameworkName) {
      const theme = await this._createThemeWorkbench(this.competence, frameworkName);
      const tube = await this._createTubeWorkbench(theme, this.competence, frameworkName);
      await this._createSkillWorkbench(tube, frameworkName);
    }

    async _createThemeWorkbench(competence, frameworkName) {
      const themeWorkbenchName = this._getThemeWorkbenchName(this.competence.code, frameworkName);

      const workbenchTheme = this.store.createRecord('theme', {
        name: themeWorkbenchName,
        competence,
        index: 0
      });
      return await workbenchTheme.save();
    }

    _getThemeWorkbenchName(competenceCode, frameworkName) {
      const code = competenceCode.replace('.', '_');
      return `workbench_${frameworkName}_${code}`;
    }

    async _createTubeWorkbench(theme, competence, frameworkName) {
      const title = `Tube pour l'atelier de la compétence ${this.competence.code} ${frameworkName}`;
      const tubeWorkbench = this.store.createRecord('tube', {
        name: '@workbench',
        title,
        theme,
        competence,
        pixId: this.idGenerator.newId('tube')
      });
      return await tubeWorkbench.save();
    }

    async _createSkillWorkbench(tube, frameworkName) {
      const description = `Acquis pour l'atelier de la compétence ${this.competence.code} ${frameworkName}`;
      const skillWorkbench = this.store.createRecord('skill', {
        name: '@workbench',
        description,
        tube,
        pixId: this.idGenerator.newId('skill')
      });
      return await skillWorkbench.save();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "idGenerator", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loader", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancelEdit", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = CompetenceManagementNewController;
});