define("pixeditor/services/storage", ["exports", "fetch", "@sentry/ember"], function (_exports, _fetch, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let StorageService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, (_class = class StorageService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "config", _descriptor, this);

      _initializerDefineProperty(this, "filePath", _descriptor2, this);

      _initializerDefineProperty(this, "auth", _descriptor3, this);
    }

    async uploadFile(_ref) {
      let {
        file,
        filename,
        date = Date,
        isAttachment = false
      } = _ref;
      filename = filename || file.name;
      return this._callAPIWithRetry(async token => {
        const headers = {
          'X-Auth-Token': token,
          'Content-Type': file.type
        };
        let finalName = filename;

        if (isAttachment) {
          finalName = this._hashFileName(filename);
          headers['Content-Disposition'] = this._getContentDispositionHeader(filename);
        }

        const url = this.config.storagePost + date.now() + '/' + encodeURIComponent(finalName);
        await file.uploadBinary(url, {
          method: 'PUT',
          headers
        });
        return {
          url,
          filename,
          size: file.size,
          type: file.type
        };
      });
    }

    _getContentDispositionHeader(filename) {
      return `attachment; filename="${encodeURIComponent(filename)}"`;
    }

    _hashFileName(filename) {
      const pos = filename.lastIndexOf('.');
      const extension = filename.substr(pos);
      let hash = '';

      for (let i = 0; i < 10; i++) {
        const charCode = Math.round(Math.random() * 25 + 65);
        hash += String.fromCharCode(charCode);
      }

      return hash + extension;
    }

    async cloneFile(url) {
      let date = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Date;
      let fetchFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _fetch.default;
      const path = url.replace(this.config.storagePost, '');
      const filename = url.split('/').reverse()[0];
      const cloneUrl = `${this.config.storagePost}${date.now()}/${filename}`;
      return this._callAPIWithRetry(async token => {
        await fetchFn(cloneUrl, {
          method: 'PUT',
          headers: {
            'X-Auth-Token': token,
            'X-Copy-From': `${this.config.storageBucket}/${path}`
          }
        });
        return cloneUrl;
      });
    }

    async _callAPIWithRetry(fn) {
      let renewToken = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      const token = await this.getStorageToken(renewToken);

      try {
        return await fn(token);
      } catch (error) {
        if (error.status === 401 && !renewToken) {
          return this._callAPIWithRetry(fn, true);
        } else {
          throw error;
        }
      }
    }

    async getStorageToken(renew) {
      let fetchFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _fetch.default;

      try {
        const config = this.config;

        if (!renew && this._hasStorageToken()) {
          return config.storageToken;
        }

        const response = await fetchFn('/api/file-storage-token', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${this.auth.key}`
          }
        });

        if (!response.ok) {
          console.error('could not get storage token');
          return false;
        }

        const json = await response.json();
        config.storageToken = json.token;
        return config.storageToken;
      } catch (error) {
        console.error(error);

        _ember.default.captureException(error);

        return false;
      }
    }

    _hasStorageToken() {
      return typeof this.config.storageToken !== 'undefined';
    }

    async renameFile(url, filename) {
      let fetchFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _fetch.default;
      return this._callAPIWithRetry(async token => {
        await fetchFn(url, {
          method: 'POST',
          headers: {
            'X-Auth-Token': token,
            'Content-Disposition': this._getContentDispositionHeader(filename)
          }
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filePath", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = StorageService;
});