define("pixeditor/models/challenge", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ChallengeModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('number'), _dec3 = (0, _model.attr)({
    readOnly: true
  }), _dec4 = (0, _model.attr)('number'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)({
    defaultValue: function () {
      return [];
    }
  }), _dec8 = (0, _model.attr)('date'), _dec9 = (0, _model.belongsTo)('skill'), _dec10 = (0, _model.hasMany)('attachment', {
    inverse: 'challenge'
  }), _dec11 = Ember.inject.service('store'), _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember._tracked, (_class = class ChallengeModel extends _model.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "airtableId", _descriptor, this);

      _initializerDefineProperty(this, "instruction", _descriptor2, this);

      _initializerDefineProperty(this, "alternativeInstruction", _descriptor3, this);

      _initializerDefineProperty(this, "type", _descriptor4, this);

      _initializerDefineProperty(this, "format", _descriptor5, this);

      _initializerDefineProperty(this, "proposals", _descriptor6, this);

      _initializerDefineProperty(this, "solution", _descriptor7, this);

      _initializerDefineProperty(this, "solutionToDisplay", _descriptor8, this);

      _initializerDefineProperty(this, "t1Status", _descriptor9, this);

      _initializerDefineProperty(this, "t2Status", _descriptor10, this);

      _initializerDefineProperty(this, "t3Status", _descriptor11, this);

      _initializerDefineProperty(this, "pedagogy", _descriptor12, this);

      _initializerDefineProperty(this, "author", _descriptor13, this);

      _initializerDefineProperty(this, "declinable", _descriptor14, this);

      _initializerDefineProperty(this, "version", _descriptor15, this);

      _initializerDefineProperty(this, "genealogy", _descriptor16, this);

      _initializerDefineProperty(this, "status", _descriptor17, this);

      _initializerDefineProperty(this, "preview", _descriptor18, this);

      _initializerDefineProperty(this, "timer", _descriptor19, this);

      _initializerDefineProperty(this, "embedURL", _descriptor20, this);

      _initializerDefineProperty(this, "embedTitle", _descriptor21, this);

      _initializerDefineProperty(this, "embedHeight", _descriptor22, this);

      _initializerDefineProperty(this, "alternativeVersion", _descriptor23, this);

      _initializerDefineProperty(this, "accessibility1", _descriptor24, this);

      _initializerDefineProperty(this, "accessibility2", _descriptor25, this);

      _initializerDefineProperty(this, "spoil", _descriptor26, this);

      _initializerDefineProperty(this, "responsive", _descriptor27, this);

      _initializerDefineProperty(this, "locales", _descriptor28, this);

      _initializerDefineProperty(this, "area", _descriptor29, this);

      _initializerDefineProperty(this, "autoReply", _descriptor30, this);

      _initializerDefineProperty(this, "focusable", _descriptor31, this);

      _initializerDefineProperty(this, "updatedAt", _descriptor32, this);

      _initializerDefineProperty(this, "skill", _descriptor33, this);

      _initializerDefineProperty(this, "files", _descriptor34, this);

      _initializerDefineProperty(this, "myStore", _descriptor35, this);

      _initializerDefineProperty(this, "config", _descriptor36, this);

      _initializerDefineProperty(this, "idGenerator", _descriptor37, this);

      _initializerDefineProperty(this, "_definedBaseName", _descriptor38, this);
    }

    get illustration() {
      return this.files.find(file => file.type === 'illustration' && !file.isDeleted);
    }

    get attachments() {
      return this.files.filter(file => file.type === 'attachment' && !file.isDeleted);
    }

    get isPrototype() {
      return this.genealogy === 'Prototype 1';
    }

    get isWorkbench() {
      const skill = this.skill;

      if (skill) {
        return skill.get('name') === '@workbench';
      }

      return false;
    }

    get isValidated() {
      return this.status === 'validé';
    }

    get skillName() {
      return this.skill.get('name');
    }

    get isDraft() {
      return this.status === 'proposé';
    }

    get isArchived() {
      return this.status === 'archivé';
    }

    get isObsolete() {
      return this.status === 'périmé';
    }

    get notDeclinable() {
      const declinable = this.declinable;
      return declinable && declinable === 'non';
    }

    get statusCSS() {
      const status = this.status;

      switch (status) {
        case 'validé':
          return 'validated';

        case 'proposé':
          return 'suggested';

        case 'archivé':
          return 'archived';

        case 'périmé':
          return 'deleted';

        default:
          return '';
      }
    }

    get isLive() {
      return this.isDraft || this.isValidated;
    }

    get alternatives() {
      if (!this.isPrototype || this.isWorkbench) {
        return [];
      }

      const currentVersion = this.version;
      const skill = this.skill;

      if (skill) {
        return skill.get('alternatives').filter(alternative => {
          return alternative.version === currentVersion;
        }).sort((a, b) => {
          return a.alternativeVersion > b.alternativeVersion;
        });
      } else {
        return [];
      }
    }

    get relatedPrototype() {
      if (this.isPrototype) {
        return null;
      }

      const currentVersion = this.version;
      const skill = this.skill;

      if (skill) {
        return skill.get('prototypes').find(prototype => prototype.version === currentVersion);
      }

      return null;
    }

    get productionAlternatives() {
      return this.alternatives.filter(alternative => alternative.isValidated);
    }

    get archivedAlternatives() {
      return this.alternatives.filter(alternative => alternative.isArchived);
    }

    get draftAlternatives() {
      return this.alternatives.filter(alternative => alternative.isDraft);
    }

    get isTextBased() {
      const type = this.type;
      return ['QROC', 'QROCM', 'QROCM-ind', 'QROCM-dep'].includes(type);
    }

    get timerOn() {
      const timer = this.timer;
      return timer && timer > 0 ? true : false;
    }

    set timerOn(value) {
      const timer = this.timer;

      if (value) {
        if (!timer || timer === 0) {
          this.timer = 1;
        }
      } else {
        if (timer && timer > 0) {
          this.timer = 0;
        }
      }

      return value;
    }

    get authorText() {
      const author = this.author;

      if (author) {
        return author.join(', ');
      }

      return '';
    }

    get skillLevel() {
      return this.skill.get('level');
    }

    get attachmentBaseName() {
      if (this._definedBaseName) {
        return this._definedBaseName;
      }

      return this._firstAttachmentBaseName;
    }

    get _firstAttachmentBaseName() {
      const attachments = this.attachments;

      if (attachments && attachments.length > 0) {
        return attachments[0].filename.replace(/\.[^/.]+$/, '');
      }

      return null;
    }

    set attachmentBaseName(value) {
      this._definedBaseName = value;
      return value;
    }

    archive() {
      this.status = 'archivé';
      return this.save();
    }

    obsolete() {
      this.status = 'périmé';
      return this.save();
    }

    validate() {
      this.status = 'validé';
      return this.save();
    }

    async duplicate() {
      const ignoredFields = ['skill', 'author', 'airtableId'];

      if (this.isPrototype) {
        ignoredFields.push('version');
      } else {
        ignoredFields.push('alternativeVersion');
      }

      const data = this._getJSON(ignoredFields);

      data.author = [this.config.author];
      data.status = 'proposé';
      data.skill = this.skill;
      data.id = this.idGenerator.newId('challenge');
      const newChallenge = this.myStore.createRecord(this.constructor.modelName, data);
      await this._cloneAttachments(newChallenge);
      return newChallenge;
    }

    async copyForDifferentSkill() {
      const ignoredFields = ['skill', 'airtableId'];

      const data = this._getJSON(ignoredFields);

      data.status = 'proposé';
      data.id = this.idGenerator.newId('challenge');
      const newChallenge = this.myStore.createRecord(this.constructor.modelName, data);
      await this._cloneAttachments(newChallenge);
      return newChallenge;
    }

    async derive() {
      const alternative = await this.duplicate();
      alternative.version = this.version;
      alternative.genealogy = 'Décliné 1';
      return alternative;
    }

    getNextAlternativeVersion() {
      return this.alternatives.reduce((current, alternative) => {
        const version = alternative.alternativeVersion;

        if (!isNaN(version)) {
          return Math.max(current, version);
        } else {
          return current;
        }
      }, 0) + 1;
    }

    baseNameUpdated() {
      return this._firstAttachmentBaseName !== this.attachmentBaseName;
    }

    _getJSON(fieldsToRemove) {
      const data = this.toJSON({
        idIncluded: false
      });

      if (data.illustration) {
        const illustration = data.illustration[0];
        data.illustration = [{
          url: illustration.url,
          filename: illustration.filename
        }];
      }

      if (data.attachments) {
        data.attachments = data.attachments.map(value => {
          return {
            url: value.url,
            filename: value.filename
          };
        });
      }

      if (fieldsToRemove) {
        fieldsToRemove.forEach(current => {
          if (data[current]) {
            delete data[current];
          }
        });
      }

      return data;
    }

    async _cloneAttachments(newChallenge) {
      await this.files;
      this.files.map(attachment => {
        const data = attachment.toJSON({
          idIncluded: false
        });
        this.store.createRecord('attachment', { ...data,
          challenge: newChallenge,
          cloneBeforeSave: true
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "airtableId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "instruction", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "alternativeInstruction", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "type", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "format", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "proposals", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "solution", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "solutionToDisplay", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "t1Status", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "t2Status", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "t3Status", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "pedagogy", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "author", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "declinable", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "genealogy", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "status", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "preview", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "timer", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "embedURL", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "embedTitle", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "embedHeight", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "alternativeVersion", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "accessibility1", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "accessibility2", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "spoil", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "responsive", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "locales", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "area", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "autoReply", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "focusable", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "updatedAt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "skill", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "files", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "myStore", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "idGenerator", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "_definedBaseName", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ChallengeModel;
});