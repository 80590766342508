define("pixeditor/components/pop-in/tutorial", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal @title="Créer un tutoriel"
            @onCloseButtonClick={{@close}}
  >
    <:content>
      <Form::Tutorial @tutorial={{@tutorial}} />
    </:content>
    <:footer>
      <PixButton @backgroundColor="transparent-light"
                 @isBorderVisible={{true}}
                 @triggerAction={{@close}}
      >
        {{t 'common.cancel'}}
      </PixButton>
      <PixButton data-test-save-tutorial-button
                 @triggerAction={{@saveTutorial}}
                 @isDisabled={{this.hasEmptyMandatoryField}}
      >
        Enregistrer
        <i class="save icon"></i>
      </PixButton>
    </:footer>
  </PixModal>
  */
  {
    "id": "IS0OTwf9",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[\"Créer un tutoriel\",[30,1]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@tutorial\"],[[30,2]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],null,[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,1]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,3],[\"common.cancel\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,2],[[24,\"data-test-save-tutorial-button\",\"\"]],[[\"@triggerAction\",\"@isDisabled\"],[[30,3],[30,0,[\"hasEmptyMandatoryField\"]]]],[[\"default\"],[[[[1,\"\\n      Enregistrer\\n      \"],[10,\"i\"],[14,0,\"save icon\"],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@close\",\"@tutorial\",\"@saveTutorial\"],false,[\"pix-modal\",\"form/tutorial\",\"pix-button\",\"t\"]]",
    "moduleName": "pixeditor/components/pop-in/tutorial.hbs",
    "isStrictMode": false
  });

  class PopInTutorialComponent extends _component.default {
    get hasEmptyMandatoryField() {
      const tutorial = this.args.tutorial;
      return this._fieldIsEmpty(tutorial.language) || this._fieldIsEmpty(tutorial.title) || this._fieldIsEmpty(tutorial.link) || this._fieldIsEmpty(tutorial.source) || this._fieldIsEmpty(tutorial.format) || this._fieldIsEmpty(tutorial.duration);
    }

    _fieldIsEmpty(field) {
      return field === undefined || field.trim() === '';
    }

  }

  _exports.default = PopInTutorialComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopInTutorialComponent);
});