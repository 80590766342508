define("pixeditor/components/competence/grid/cell-i18n", ["exports", "@glimmer/component", "pixeditor/helpers/convert-language-as-flag"], function (_exports, _component, _convertLanguageAsFlag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="skill-cell i18n">
    <LinkTo @route="competence.i18n.single" @model={{@skill}} class="skill-cell__link">
      <div class="skill-i18n-name">
        {{@skill.name}}
      </div>
      <div class="flags">
        {{#each this.languagesAndFlags as |languageAndFlag| }}
          <i class="{{languageAndFlag.flag}} flag" title="{{languageAndFlag.language}}"></i>
        {{/each}}
      </div>
    </LinkTo>
  </td>
  
  
  */
  {
    "id": "/T33quYS",
    "block": "[[[10,\"td\"],[14,0,\"skill-cell i18n\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"skill-cell__link\"]],[[\"@route\",\"@model\"],[\"competence.i18n.single\",[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"skill-i18n-name\"],[12],[1,\"\\n      \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"flags\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"languagesAndFlags\"]]],null]],null],null,[[[1,\"        \"],[10,\"i\"],[15,0,[29,[[30,2,[\"flag\"]],\" flag\"]]],[15,\"title\",[29,[[30,2,[\"language\"]]]]],[12],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@skill\",\"languageAndFlag\"],false,[\"link-to\",\"each\",\"-track-array\"]]",
    "moduleName": "pixeditor/components/competence/grid/cell-i18n.hbs",
    "isStrictMode": false
  });

  class CompetenceGridCellI18nComponent extends _component.default {
    get languagesAndFlags() {
      const languages = this.args.skill.languages;
      return languages.map(language => {
        return {
          language,
          flag: (0, _convertLanguageAsFlag.convertLanguageAsFlag)([language])
        };
      });
    }

  }

  _exports.default = CompetenceGridCellI18nComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CompetenceGridCellI18nComponent);
});