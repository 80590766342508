define("pixeditor/mirage/factories/attachment", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    url: 'data:,',
    filename: 'file',
    mimeType: 'image/png',
    type: 'illustration',
    size: 123
  });

  _exports.default = _default;
});