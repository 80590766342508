define("pixeditor/components/list/sorted", ["exports", "pixeditor/config/environment", "@glimmer/component"], function (_exports, _environment, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SortedList = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class SortedList extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ascending", _descriptor, this);

      _initializerDefineProperty(this, "sortField", _descriptor2, this);

      _initializerDefineProperty(this, "sorts", _descriptor3, this);

      _defineProperty(this, "sortHandlers", {});
    }

    sort(itemA, itemB, sorts, compare, sortEmptyLast) {
      const valuePath = sorts[0].valuePath;
      const isAscending = sorts[0].isAscending;
      const handler = sorts[0].handler;
      let valueA = itemA[valuePath];
      let valueB = itemB[valuePath];

      if (handler) {
        switch (handler.type) {
          case 'array':
            valueA = valueA[0];
            valueB = valueB[0];
            break;

          case 'int':
            valueA = parseInt(valueA);
            valueB = parseInt(valueB);
            break;

          case 'date':
            if (handler.field) {
              valueA = new Date(itemA[handler.field]);
              valueB = new Date(itemB[handler.field]);
            } else {
              valueA = new Date(valueA);
              valueB = new Date(valueB);
            }

            break;
        }
      }

      if (sortEmptyLast) {
        sortEmptyLast = isAscending;
      }

      return isAscending ? compare(valueA, valueB, sortEmptyLast) : -compare(valueA, valueB, sortEmptyLast);
    } // Fix EmberTable rendering for tests


    get renderAll() {
      return _environment.default.environment === 'test';
    }

    sortBy(params) {
      if (params.length > 0) {
        const field = params[0].valuePath;
        this.sortField = field;

        if (this.sortHandlers[field]) {
          params[0].handler = this.sortHandlers[field];
        }

        this.ascending = params[0].isAscending;
        this.sorts = params;
      } else {
        this.ascending = !this.ascending;
        this.sorts = [{
          valuePath: this.sortField,
          isAscending: this.ascending
        }];
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ascending", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sortField", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sorts", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortBy", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "sortBy"), _class.prototype)), _class));
  _exports.default = SortedList;
});