define("pixeditor/services/file-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FilePathService extends Ember.Service {
    getExtension(path) {
      return path.split('.').pop();
    }

    getBaseName(path) {
      return path.replace(/\.[^/.]+$/, '');
    }

  }

  _exports.default = FilePathService;
});