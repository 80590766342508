define("pixeditor/components/pop-in/login-form", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
    @title="Connexion"
    @onCloseButtonClick={{this.validateApiKey}}
  >
    <:content >
      <form
        action="submit"
        class="ui form"
        >
      <div class="field">
        <label>Pour vous identifier, merci de saisir votre clé personnelle.</label>
        <div class="ui input">
          <Input id="login-api-key" @value={{this.apiKey}} />
        </div>
        {{#if this.isErrorMessagePresent}}
          <div class="ui negative message" id="login-form-error-message">
            {{this.errorMessage}}
          </div>
        {{/if}}
      </div>
      <PixButton data-test-login-button
                 @type="submit"
                 @triggerAction={{this.validateApiKey}}
      >
        Me connecter
      </PixButton>
      </form>
    </:content>
  </PixModal>
  */
  {
    "id": "eCF9yWkU",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[\"Connexion\",[30,0,[\"validateApiKey\"]]]],[[\"content\"],[[[[1,\"\\n    \"],[10,\"form\"],[14,\"action\",\"submit\"],[14,0,\"ui form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"Pour vous identifier, merci de saisir votre clé personnelle.\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ui input\"],[12],[1,\"\\n        \"],[8,[39,1],[[24,1,\"login-api-key\"]],[[\"@value\"],[[30,0,[\"apiKey\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"isErrorMessagePresent\"]],[[[1,\"        \"],[10,0],[14,0,\"ui negative message\"],[14,1,\"login-form-error-message\"],[12],[1,\"\\n          \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[8,[39,3],[[24,\"data-test-login-button\",\"\"]],[[\"@type\",\"@triggerAction\"],[\"submit\",[30,0,[\"validateApiKey\"]]]],[[\"default\"],[[[[1,\"\\n      Me connecter\\n    \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[],false,[\"pix-modal\",\"input\",\"if\",\"pix-button\"]]",
    "moduleName": "pixeditor/components/pop-in/login-form.hbs",
    "isStrictMode": false
  });

  let PopinLoginForm = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, (_class = class PopinLoginForm extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "config", _descriptor, this);

      _initializerDefineProperty(this, "auth", _descriptor2, this);

      _initializerDefineProperty(this, "apiKey", _descriptor3, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor4, this);

      _initializerDefineProperty(this, "isErrorMessagePresent", _descriptor5, this);
    }

    _closeModal() {
      this.args.close();
      this.args.update();
    }

    async validateApiKey() {
      event.preventDefault();
      this.auth.key = this.apiKey;

      try {
        await this.config.load();

        this._closeModal();
      } catch (error) {
        this.isErrorMessagePresent = true;
        this.errorMessage = 'La clé saisie n\'a pas pu être validée. Vérifiez votre connexion ou contactez l\'équipe de développement.';

        if (this._isUnauthorizedError(error)) {
          this.auth.key = undefined;
          this.errorMessage = 'La clé saisie n\'est pas valide. Vérifiez votre saisie.';
        }
      }
    }

    _isUnauthorizedError(error) {
      return error && error.errors && error.errors[0] && error.errors[0].code === 401;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "auth", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiKey", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isErrorMessagePresent", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validateApiKey", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "validateApiKey"), _class.prototype)), _class));
  _exports.default = PopinLoginForm;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopinLoginForm);
});