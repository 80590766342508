define("pixeditor/stories/pix-filter-banner.stories", ["exports", "@storybook/addon-actions"], function (_exports, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterBanner = _exports.argTypes = void 0;

  const filterBanner = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixFilterBanner @title={{title}} @details={{details}} @clearFiltersLabel={{clearFiltersLabel}} @onClearFilters={{onClearFilters}}>
              <PixSelect @options={{this.options}} @onChange={{this.onChange}} />
              <PixSelect @options={{this.options}} @onChange={{this.onChange}} />
            </PixFilterBanner>
          
      */
      {
        "id": "K1qCMFIO",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@title\",\"@details\",\"@clearFiltersLabel\",\"@onClearFilters\"],[[36,1],[36,2],[36,3],[36,4]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@options\",\"@onChange\"],[[30,0,[\"options\"]],[30,0,[\"onChange\"]]]],null],[1,\"\\n        \"],[8,[39,5],null,[[\"@options\",\"@onChange\"],[[30,0,[\"options\"]],[30,0,[\"onChange\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-filter-banner\",\"title\",\"details\",\"clearFiltersLabel\",\"onClearFilters\",\"pix-select\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.filterBanner = filterBanner;
  filterBanner.args = {
    title: 'Filtres',
    details: 'Des détails sur le filtre',
    clearFiltersLabel: 'Effacer les filtres',
    options: [{
      value: '1',
      label: 'Tomate'
    }, {
      value: '2',
      label: 'Mozza'
    }],
    onChange: (0, _addonActions.action)('select-onchange')
  };
  const argTypes = {
    title: {
      name: 'title',
      description: 'Titre du filtre',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    details: {
      name: 'details',
      description: 'Détails du filtre',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    clearFiltersLabel: {
      name: 'clearFiltersLabel',
      description: 'libellé du bouton',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    onClearFilters: {
      name: 'onClearFilters',
      description: 'fonction à appeler pour déclencher l’action de suppression des filtres',
      type: {
        required: false
      },
      defaultValue: (0, _addonActions.action)('onClearFilters')
    }
  };
  _exports.argTypes = argTypes;
});