define("pixeditor/components/field/select", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{concat "field" (if @edition "" " disabled")}} ...attributes>
    <label>{{@title}}</label>
  {{#if @multiple}}
    <PowerSelectMultiple @placeholder={{if @defaultText @defaultText ""}} @options={{@options}} @searchEnabled={{false}} @disabled={{if @edition false true}} @selected={{@value}} @onChange={{@setValue}} as |option|>
      {{#if option.label}}
        {{option.label}}
      {{else}}
        {{option}}
      {{/if}}
    </PowerSelectMultiple>
  {{else}}
    <PowerSelect @placeholder={{if @defaultText @defaultText ""}} @searchEnabled={{false}} @options={{@options}} @disabled={{if @edition false true}} @selected={{if @value @value "" }} @onChange={{@setValue}} as |option|>
      {{#if option.label}}
        {{option.label}}
      {{else}}
        {{option}}
      {{/if}}
    </PowerSelect>
  {{/if}}
  </div>
  
  */
  {
    "id": "XvkvwVfI",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"field\",[52,[30,1],\"\",\" disabled\"]],null]],[17,2],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[30,3]],[13],[1,\"\\n\"],[41,[30,4],[[[1,\"  \"],[8,[39,2],null,[[\"@placeholder\",\"@options\",\"@searchEnabled\",\"@disabled\",\"@selected\",\"@onChange\"],[[52,[30,5],[30,5],\"\"],[30,6],false,[52,[30,1],false,true],[30,7],[30,8]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,9,[\"label\"]],[[[1,\"      \"],[1,[30,9,[\"label\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,9]],[1,\"\\n\"]],[]]],[1,\"  \"]],[9]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@placeholder\",\"@searchEnabled\",\"@options\",\"@disabled\",\"@selected\",\"@onChange\"],[[52,[30,5],[30,5],\"\"],false,[30,6],[52,[30,1],false,true],[52,[30,7],[30,7],\"\"],[30,8]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,10,[\"label\"]],[[[1,\"      \"],[1,[30,10,[\"label\"]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,10]],[1,\"\\n\"]],[]]],[1,\"  \"]],[10]]]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@edition\",\"&attrs\",\"@title\",\"@multiple\",\"@defaultText\",\"@options\",\"@value\",\"@setValue\",\"option\",\"option\"],false,[\"concat\",\"if\",\"power-select-multiple\",\"power-select\"]]",
    "moduleName": "pixeditor/components/field/select.hbs",
    "isStrictMode": false
  });

  class Select extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "value", false);
    }

  }

  _exports.default = Select;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Select);
});