define("pixeditor/components/form/note", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui content">
    <form class="ui form">
      <div class="field">
        <Field::Select @title="Statut" @value={{@entry.status}} @options={{this.options.status}} @edition={{@edition}} @setValue={{fn (mut @entry.status)}}/>
      </div>
      <div class="field {{if @edition "" "disabled"}}">
        <label for="note">Texte</label>
        <div class="ui input">
          <Textarea id="note" @value={{@entry.text}} rows="4" readonly={{not @edition}} />
        </div>
      </div>
    </form>
  </div>
  <div class="actions">
    <button class="ui right button" {{on "click" @close}} type="button">
      Retour
    </button>
    {{#if @edition}}
    <button class="ui green right button" {{on "click" @save}} type="button">
      Enregistrer
    </button>
    {{else}}
      {{#if @mayEdit}}
      <button class="ui green right button" {{on "click" @edit}} type="button">
        Modifier
      </button>
      {{/if}}
    {{/if}}
  </div>
  
  */
  {
    "id": "/UGcMa8O",
    "block": "[[[10,0],[14,0,\"ui content\"],[12],[1,\"\\n  \"],[10,\"form\"],[14,0,\"ui form\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@title\",\"@value\",\"@options\",\"@edition\",\"@setValue\"],[\"Statut\",[30,1,[\"status\"]],[30,0,[\"options\",\"status\"]],[30,2],[28,[37,1],[[28,[37,2],[[30,1,[\"status\"]]],null]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"field \",[52,[30,2],\"\",\"disabled\"]]]],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"note\"],[12],[1,\"Texte\"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ui input\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,1,\"note\"],[24,\"rows\",\"4\"],[16,\"readonly\",[28,[37,5],[[30,2]],null]]],[[\"@value\"],[[30,1,[\"text\"]]]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"ui right button\"],[24,4,\"button\"],[4,[38,6],[\"click\",[30,3]],null],[12],[1,\"\\n    Retour\\n  \"],[13],[1,\"\\n\"],[41,[30,2],[[[1,\"  \"],[11,\"button\"],[24,0,\"ui green right button\"],[24,4,\"button\"],[4,[38,6],[\"click\",[30,4]],null],[12],[1,\"\\n    Enregistrer\\n  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,5],[[[1,\"    \"],[11,\"button\"],[24,0,\"ui green right button\"],[24,4,\"button\"],[4,[38,6],[\"click\",[30,6]],null],[12],[1,\"\\n      Modifier\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]],[13],[1,\"\\n\"]],[\"@entry\",\"@edition\",\"@close\",\"@save\",\"@mayEdit\",\"@edit\"],false,[\"field/select\",\"fn\",\"mut\",\"if\",\"textarea\",\"not\",\"on\"]]",
    "moduleName": "pixeditor/components/form/note.hbs",
    "isStrictMode": false
  });

  class NoteForm extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "options", {
        status: ['en cours', 'terminé', 'archive']
      });
    }

  }

  _exports.default = NoteForm;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NoteForm);
});