define("pixeditor/components/target-profile/threshold-row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
    <td>Équivalent niveau {{@level}}</td>
    <td data-test-threshold >{{this.threshold}}%</td>
    <td data-test-skill-count >{{this.skillsCountByLevel}}</td>
  </tr>
  */
  {
    "id": "tS8a81pU",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[12],[1,\"Équivalent niveau \"],[1,[30,1]],[13],[1,\"\\n  \"],[10,\"td\"],[14,\"data-test-threshold\",\"\"],[12],[1,[30,0,[\"threshold\"]]],[1,\"%\"],[13],[1,\"\\n  \"],[10,\"td\"],[14,\"data-test-skill-count\",\"\"],[12],[1,[30,0,[\"skillsCountByLevel\"]]],[13],[1,\"\\n\"],[13]],[\"@level\"],false,[]]",
    "moduleName": "pixeditor/components/target-profile/threshold-row.hbs",
    "isStrictMode": false
  });

  class ThresholdRow extends _component.default {
    get skillsCountByLevel() {
      const skills = this.args.selectedSkills;
      const skillsByLevel = skills.filter(skill => {
        return skill.level === this.args.level;
      });
      return skillsByLevel.length;
    }

    get skillsCountByLevelMax() {
      const skills = this.args.selectedSkills;
      const skillsByLevel = skills.filter(skill => {
        return skill.level <= this.args.level;
      });
      return skillsByLevel.length;
    }

    get threshold() {
      return Math.round(this.skillsCountByLevelMax / this.args.selectedSkills.length * 100);
    }

  }

  _exports.default = ThresholdRow;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ThresholdRow);
});