define("pixeditor/components/pop-in/pdf-entries", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
    @title={{t 'target_profile.pdf_export.title'}}
    @onCloseButtonClick={{this.closeModal}}
  >
    <:content>
      <form class="ui form" {{on "submit" this.validate}}>
        <div class="field">
          <Field::Input @title={{t 'target_profile.pdf_export.field.title'}}
                        @value={{this.title}}
                        @edition={{true}}
                        data-test-pdf-title-field
          />
        </div>
        <div class="field">
          <Field::Select @title={{t 'target_profile.pdf_export.field.language'}}
                         @value={{this.selectedLanguage}}
                         @options={{this.options.language}}
                         @edition={{true}}
                         @setValue={{this.setLanguage}}
                         data-test-pdf-language-field
          />
        </div>
      </form>
    </:content>
    <:footer>
      <PixButton @backgroundColor="transparent-light"
                 @isBorderVisible={{true}}
                 @triggerAction={{this.closeModal}}
      >
        {{t 'common.cancel'}}
      </PixButton>
      <PixButton data-test-validate-pdf-entries
                 @triggerAction={{this.validate}}
      >
        <i class="checkmark icon"
           aria-hidden="true"
        ></i>
        {{t 'common.validate'}}
      </PixButton>
    </:footer>
  </PixModal>
  */
  {
    "id": "AP55Kbvy",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[[28,[37,1],[\"target_profile.pdf_export.title\"],null],[30,0,[\"closeModal\"]]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[11,\"form\"],[24,0,\"ui form\"],[4,[38,2],[\"submit\",[30,0,[\"validate\"]]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n        \"],[8,[39,3],[[24,\"data-test-pdf-title-field\",\"\"]],[[\"@title\",\"@value\",\"@edition\"],[[28,[37,1],[\"target_profile.pdf_export.field.title\"],null],[30,0,[\"title\"]],true]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,\"data-test-pdf-language-field\",\"\"]],[[\"@title\",\"@value\",\"@options\",\"@edition\",\"@setValue\"],[[28,[37,1],[\"target_profile.pdf_export.field.language\"],null],[30,0,[\"selectedLanguage\"]],[30,0,[\"options\",\"language\"]],true,[30,0,[\"setLanguage\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],null,[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,0,[\"closeModal\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[\"common.cancel\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,5],[[24,\"data-test-validate-pdf-entries\",\"\"]],[[\"@triggerAction\"],[[30,0,[\"validate\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"i\"],[14,0,\"checkmark icon\"],[14,\"aria-hidden\",\"true\"],[12],[13],[1,\"\\n      \"],[1,[28,[35,1],[\"common.validate\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[],false,[\"pix-modal\",\"t\",\"on\",\"field/input\",\"field/select\",\"pix-button\"]]",
    "moduleName": "pixeditor/components/pop-in/pdf-entries.hbs",
    "isStrictMode": false
  });

  let PopinPDFEntries = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class PopinPDFEntries extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "options", {
        language: [{
          value: 'en',
          label: 'Anglais'
        }, {
          value: 'fr',
          label: 'Français'
        }]
      });

      _initializerDefineProperty(this, "title", _descriptor, this);

      _initializerDefineProperty(this, "language", _descriptor2, this);
    }

    get selectedLanguage() {
      if (this.language) {
        return this.language;
      }

      return this.options.language.find(option => option.value === 'fr');
    }

    setLanguage(language) {
      this.language = language;
    }

    validate(e) {
      e.preventDefault();
      this.args.validateAction(this.title, this.selectedLanguage.value);
      this.closeModal();
    }

    closeModal() {
      this.args.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Liste des thèmes et des sujets abordés dans Pix';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setLanguage", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setLanguage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validate", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = PopinPDFEntries;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopinPDFEntries);
});