define("pixeditor/components/competence/competence-header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="ui main-title{{this.liteClass}}">
    <h1 class="ui left floated header">
      <LinkTo @route="competence-management.single" @model={{@competence}} class="competence-management-link">{{@competence.name}}</LinkTo>
    </h1>
    <div class="selection ui small right floated header select-section">
      <PowerSelect @dropdownClass="select-section-dropdown" @searchEnabled={{false}} @options={{this.sections}} @selected={{this.selectedSection}} @onChange={{@selectSection}} as |section|>
        {{section.title}}
      </PowerSelect>
    </div>
    {{#if this.displayLanguageFilter}}
      <div class="selection ui small right floated header language-filter">
        <PowerSelect @dropdownClass="language-filter-dropdown" @searchEnabled={{false}} @options={{this.languageOptions}} @selected={{this.selectedLanguageToFilter}} @onChange={{@selectLanguageToFilter}} as |languageOption|>
          {{#if languageOption.local}}
            {{languageOption.language}} <i class="flag {{convert-language-as-flag languageOption.local}}"></i>
          {{else}}
            {{languageOption.title}}
          {{/if}}
        </PowerSelect>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "AklBgflM",
    "block": "[[[10,0],[15,0,[29,[\"ui main-title\",[30,0,[\"liteClass\"]]]]],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ui left floated header\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"competence-management-link\"]],[[\"@route\",\"@model\"],[\"competence-management.single\",[30,1]]],[[\"default\"],[[[[1,[30,1,[\"name\"]]]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"selection ui small right floated header select-section\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@dropdownClass\",\"@searchEnabled\",\"@options\",\"@selected\",\"@onChange\"],[\"select-section-dropdown\",false,[30,0,[\"sections\"]],[30,0,[\"selectedSection\"]],[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3,[\"title\"]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"displayLanguageFilter\"]],[[[1,\"    \"],[10,0],[14,0,\"selection ui small right floated header language-filter\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@dropdownClass\",\"@searchEnabled\",\"@options\",\"@selected\",\"@onChange\"],[\"language-filter-dropdown\",false,[30,0,[\"languageOptions\"]],[30,0,[\"selectedLanguageToFilter\"]],[30,4]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,5,[\"local\"]],[[[1,\"          \"],[1,[30,5,[\"language\"]]],[1,\" \"],[10,\"i\"],[15,0,[29,[\"flag \",[28,[37,3],[[30,5,[\"local\"]]],null]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,5,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"      \"]],[5]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@competence\",\"@selectSection\",\"section\",\"@selectLanguageToFilter\",\"languageOption\"],false,[\"link-to\",\"power-select\",\"if\",\"convert-language-as-flag\"]]",
    "moduleName": "pixeditor/components/competence/competence-header.hbs",
    "isStrictMode": false
  });

  let CompetenceHeader = (_dec = Ember.inject.service, (_class = class CompetenceHeader extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "config", _descriptor, this);

      _defineProperty(this, "sections", [{
        title: 'Epreuves',
        id: 'challenges'
      }, {
        title: 'Acquis',
        id: 'skills'
      }, {
        title: 'Qualité',
        id: 'quality'
      }, {
        title: 'International',
        id: 'i18n'
      }]);

      _defineProperty(this, "languageOptions", [{
        local: false,
        title: 'Filtre par langue'
      }, {
        language: 'Anglais',
        local: 'en'
      }, {
        language: 'Espagnol',
        local: 'es'
      }, {
        language: 'Francophone',
        local: 'fr'
      }, {
        language: 'Franco Français',
        local: 'fr-fr'
      }, {
        language: 'Italie',
        local: 'it'
      }, {
        language: 'Portugais',
        local: 'pt'
      }]);
    }

    get liteClass() {
      return this.config.lite ? ' lite ' : '';
    }

    get selectedSection() {
      const section = this.args.section;
      return this.sections.find(el => el.id === section);
    }

    get selectedLanguageToFilter() {
      const languageFilter = this.args.languageFilter;
      return this.languageOptions.find(languagesOption => languagesOption.local === languageFilter);
    }

    get displayLanguageFilter() {
      if (this.args.section === 'skills') {
        return this.args.view === 'production' || this.args.view === 'draft';
      }

      return this.args.section === 'challenges' && this.args.view === 'production';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CompetenceHeader;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CompetenceHeader);
});