define("pixeditor/components/pop-in/single-entry", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
    @title={{@title}}
    @onCloseButtonClick={{this.closeModal}}
  >
    <:content>
      <label>{{@label}}</label>
      <Field::Input @value={{this.value}} @edition={{true}} />
    </:content>
    <:footer>
      <PixButton
        @backgroundColor="transparent-light"
        @isBorderVisible={{true}}
        @triggerAction={{this.closeModal}}
      >
        Annuler
      </PixButton>
      <PixButton @triggerAction={{this.validate}}>Valider</PixButton>
    </:footer>
  </PixModal>
  */
  {
    "id": "6S1Q5Ln4",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[[30,1],[30,0,[\"closeModal\"]]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[10,\"label\"],[12],[1,[30,2]],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@value\",\"@edition\"],[[30,0,[\"value\"]],true]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],null,[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,0,[\"closeModal\"]]]],[[\"default\"],[[[[1,\"\\n      Annuler\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,2],null,[[\"@triggerAction\"],[[30,0,[\"validate\"]]]],[[\"default\"],[[[[1,\"Valider\"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@title\",\"@label\"],false,[\"pix-modal\",\"field/input\",\"pix-button\"]]",
    "moduleName": "pixeditor/components/pop-in/single-entry.hbs",
    "isStrictMode": false
  });

  let PopinSingleEntry = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PopinSingleEntry extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "value", _descriptor, this);

      if (this.args.labelValue) {
        this.value = this.args.labelValue;
      }
    }

    validate(e) {
      e.preventDefault();
      this.args.setValue(this.value);
      this.closeModal();
    }

    closeModal() {
      this.value = '';
      this.args.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "validate", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "validate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = PopinSingleEntry;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopinSingleEntry);
});