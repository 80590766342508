define("pixeditor/serializers/config", ["exports", "pixeditor/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ConfigSerializer extends _application.default {
    extractId() {
      return 'pix-editor-global-config';
    }

  }

  _exports.default = ConfigSerializer;
});