define("pixeditor/controllers/competence/tubes/single", ["exports", "@sentry/ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SingleController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.controller('competence'), _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, (_class = class SingleController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "edition", _descriptor, this);

      _initializerDefineProperty(this, "displaySelectLocation", _descriptor2, this);

      _defineProperty(this, "creation", false);

      _defineProperty(this, "wasMaximized", false);

      _initializerDefineProperty(this, "parentController", _descriptor3, this);

      _initializerDefineProperty(this, "access", _descriptor4, this);

      _initializerDefineProperty(this, "config", _descriptor5, this);

      _initializerDefineProperty(this, "loader", _descriptor6, this);

      _initializerDefineProperty(this, "notify", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);
    }

    get tube() {
      return this.model;
    }

    get maximized() {
      return this.parentController.leftMaximized;
    }

    get mayAccessAirtable() {
      return this.access.mayAccessAirtable();
    }

    get mayEdit() {
      return this.access.mayEditSkills();
    }

    get mayMove() {
      return this.access.mayMoveTube(this.tube);
    }

    get disableSaveButton() {
      if (!this.creation) {
        return false;
      }

      return this.isEmptyMandatoryField;
    }

    get airtableUrl() {
      return `${this.config.airtableUrl}${this.config.tableTubes}/${this.tube.id}`;
    }

    maximize() {
      this.parentController.maximizeLeft(true);
    }

    minimize() {
      this.parentController.maximizeLeft(false);
    }

    close() {
      if (this.edition) {
        this.cancelEdit();
      }

      this.parentController.send('closeChildComponent');
    }

    edit() {
      this.wasMaximized = this.maximized;
      this.send('maximize');
      this.edition = true;
    }

    cancelEdit() {
      this.edition = false;
      const tube = this.tube;
      tube.rollbackAttributes();
      const previousState = this.wasMaximized;

      if (!previousState) {
        this.send('minimize');
      }

      this.notify.message('Modification annulée');
    }

    save() {
      this.loader.start();
      const tube = this.tube;
      return tube.save().then(() => {
        this.edition = false;
        this.loader.stop();
        this.notify.message('Tube mis à jour');
        return tube.hasMany('rawSkills').reload();
      }).catch(error => {
        console.error(error);

        _ember.default.captureException(error);

        this.loader.stop();
        this.notify.error('Erreur lors de la mise à jour du tube');
      });
    }

    selectCompetence() {
      this.displaySelectLocation = true;
    }

    closeSelectCompetence() {
      this.displaySelectLocation = false;
    }

    setCompetence(newCompetence, newTheme) {
      const tube = this.tube;
      this.loader.start();
      tube.competence = newCompetence;
      tube.theme = newTheme;
      return tube.save().then(() => {
        this.loader.stop();
        this.notify.message('Tube mis à jour');
        this.router.transitionTo('competence.tubes.single', newCompetence, tube);
      }).catch(error => {
        console.error(error);

        _ember.default.captureException(error);

        this.loader.stop();
        this.notify.error('Erreur lors de la mise à jour du tube');
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "edition", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "displaySelectLocation", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parentController", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "access", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loader", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "maximize", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "maximize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "minimize", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "minimize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelEdit", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCompetence", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "selectCompetence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeSelectCompetence", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "closeSelectCompetence"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setCompetence", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "setCompetence"), _class.prototype)), _class));
  _exports.default = SingleController;
});