define("pixeditor/components/pop-in/confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
    @title={{@title}}
    @onCloseButtonClick={{@onDeny}}
  >
    <:content>
      {{@content}}
    </:content>
    <:footer>
      <PixButton
        @backgroundColor="transparent-light"
        @isBorderVisible={{true}}
        @triggerAction={{@onDeny}}
      >
        Annuler
      </PixButton>
      <PixButton @triggerAction={{@onApprove}}>Oui</PixButton>
    </:footer>
  </PixModal>
  
  */
  {
    "id": "mxq263Kz",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[[30,1],[30,2]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[1,[30,3]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,1],null,[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,2]]],[[\"default\"],[[[[1,\"\\n      Annuler\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],null,[[\"@triggerAction\"],[[30,4]]],[[\"default\"],[[[[1,\"Oui\"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@title\",\"@onDeny\",\"@content\",\"@onApprove\"],false,[\"pix-modal\",\"pix-button\"]]",
    "moduleName": "pixeditor/components/pop-in/confirm.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});