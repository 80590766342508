define("pixeditor/routes/competence/skills/single/archive", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class CompetenceSkillsSingleArchiveRoute extends _authenticated.default {
    renderTemplate() {
      this.render('competence/skills/single/archive', {
        into: 'competence',
        outlet: 'mainRight'
      });
    }

  }

  _exports.default = CompetenceSkillsSingleArchiveRoute;
});