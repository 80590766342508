define("pixeditor/services/window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class WindowService extends Ember.Service {
    reload() {
      window.location.reload(true);
    }

  }

  _exports.default = WindowService;
});