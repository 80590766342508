define("pixeditor/components/pop-in/sorting", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal data-test-sorting-pop-in-title
            @title={{@title}}
            @onCloseButtonClick={{fn @onDeny @model}}
  >
    <:content>
      <div data-test-sorting-pop-in-content class="sortable-content">
        <ul {{sortable-group onChange=this.reorderItems}}>
          {{#each this.models as |modelItem|}}
            <li {{sortable-item model=modelItem}} {{sortable-handle}}>
              <i class="ellipsis vertical icon"></i><span>{{modelItem.name}}</span><i class="ellipsis vertical icon"></i>
            </li>
          {{/each}}
        </ul>
      </div>
    </:content>
    <:footer>
      <PixButton data-test-sorting-pop-in-deny
                 @backgroundColor="transparent-light"
                 @isBorderVisible={{true}}
                 @triggerAction={{fn @onDeny @model}}
      >
        <i class="remove icon" ></i>
        {{t 'common.cancel'}}
      </PixButton>
      <PixButton data-test-sorting-pop-in-approve
                 @triggerAction={{fn @onApprove @model}}
      >
        <i class="checkmark icon" ></i>
        Ok
      </PixButton>
    </:footer>
  </PixModal>
  */
  {
    "id": "fEMEwmb5",
    "block": "[[[8,[39,0],[[24,\"data-test-sorting-pop-in-title\",\"\"]],[[\"@title\",\"@onCloseButtonClick\"],[[30,1],[28,[37,1],[[30,2],[30,3]],null]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,\"data-test-sorting-pop-in-content\",\"\"],[14,0,\"sortable-content\"],[12],[1,\"\\n      \"],[11,\"ul\"],[4,[38,2],null,[[\"onChange\"],[[30,0,[\"reorderItems\"]]]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"models\"]]],null]],null],null,[[[1,\"          \"],[11,\"li\"],[4,[38,5],null,[[\"model\"],[[30,4]]]],[4,[38,6],null,null],[12],[1,\"\\n            \"],[10,\"i\"],[14,0,\"ellipsis vertical icon\"],[12],[13],[10,1],[12],[1,[30,4,[\"name\"]]],[13],[10,\"i\"],[14,0,\"ellipsis vertical icon\"],[12],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[4]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,\"data-test-sorting-pop-in-deny\",\"\"]],[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[28,[37,1],[[30,2],[30,3]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"i\"],[14,0,\"remove icon\"],[12],[13],[1,\"\\n      \"],[1,[28,[35,8],[\"common.cancel\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,7],[[24,\"data-test-sorting-pop-in-approve\",\"\"]],[[\"@triggerAction\"],[[28,[37,1],[[30,5],[30,3]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"i\"],[14,0,\"checkmark icon\"],[12],[13],[1,\"\\n      Ok\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@title\",\"@onDeny\",\"@model\",\"modelItem\",\"@onApprove\"],false,[\"pix-modal\",\"fn\",\"sortable-group\",\"each\",\"-track-array\",\"sortable-item\",\"sortable-handle\",\"pix-button\",\"t\"]]",
    "moduleName": "pixeditor/components/pop-in/sorting.hbs",
    "isStrictMode": false
  });

  let PopInSortingComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class PopInSortingComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "sortedModel", _descriptor, this);
    }

    // force tracking of model sorting
    get models() {
      if (this.sortedModel) {
        return this.sortedModel;
      }

      return this.args.model;
    }

    reorderItems(models) {
      models.forEach((model, index) => model.index = index);
      this.sortedModel = models;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sortedModel", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "reorderItems", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "reorderItems"), _class.prototype)), _class));
  _exports.default = PopInSortingComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopInSortingComponent);
});