define("pixeditor/stories/pix-textarea.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.textarea = _exports.argTypes = void 0;

  const textarea = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTextarea 
              @id={{id}}
              @value={{value}}
              @maxlength={{maxlength}}
              @label={{label}}
              @errorMessage={{errorMessage}}
            />
          
      */
      {
        "id": "IySZfJAK",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@value\",\"@maxlength\",\"@label\",\"@errorMessage\"],[[36,1],[36,2],[36,3],[36,4],[36,5]]],null],[1,\"\\n    \"]],[],false,[\"pix-textarea\",\"id\",\"value\",\"maxlength\",\"label\",\"errorMessage\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.textarea = textarea;
  const argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant du champ permettant de lui attacher un label',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: ''
    },
    value: {
      name: 'value',
      description: 'Valeur du champ',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: ''
    },
    maxlength: {
      name: 'maxlength',
      description: 'Nombre de caractères maximal à taper dans le champ',
      type: {
        name: 'number',
        required: false
      },
      defaultValue: 500
    },
    label: {
      name: 'label',
      description: 'Donne un label au champ.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    },
    errorMessage: {
      name: 'errorMessage',
      description: 'Affiche une erreur en dessous du champ.',
      type: {
        name: 'string',
        required: false
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: null
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});