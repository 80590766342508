define("pixeditor/components/power-select/custom-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component @selectedItemComponent extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <span class="ember-power-select-selected-item">{{yield @select.selected @select}}</span>
    {{/if}}
    {{#if (and @allowClear (not @select.disabled))}}
      <span class="ember-power-select-clear-btn" {{on "mouseup" this.clear}} {{on "touchstart" this.clear}}>&times;</span>
    {{/if}}
  {{else}}
    {{component @placeholderComponent placeholder=@placeholder}}
  {{/if}}
  <i class="search icon custom-trigger"></i>
  
  */
  {
    "id": "41WfsXOa",
    "block": "[[[41,[30,1,[\"selected\"]],[[[41,[30,2],[[[1,\"    \"],[46,[30,2],null,[[\"extra\",\"option\",\"select\"],[[28,[37,2],[[30,3]],null],[28,[37,2],[[30,1,[\"selected\"]]],null],[28,[37,2],[[30,1]],null]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"ember-power-select-selected-item\"],[12],[18,7,[[30,1,[\"selected\"]],[30,1]]],[13],[1,\"\\n\"]],[]]],[41,[28,[37,4],[[30,4],[28,[37,5],[[30,1,[\"disabled\"]]],null]],null],[[[1,\"    \"],[11,1],[24,0,\"ember-power-select-clear-btn\"],[4,[38,6],[\"mouseup\",[30,0,[\"clear\"]]],null],[4,[38,6],[\"touchstart\",[30,0,[\"clear\"]]],null],[12],[1,\"×\"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[46,[30,5],null,[[\"placeholder\"],[[30,6]]],null],[1,\"\\n\"]],[]]],[10,\"i\"],[14,0,\"search icon custom-trigger\"],[12],[13],[1,\"\\n\"]],[\"@select\",\"@selectedItemComponent\",\"@extra\",\"@allowClear\",\"@placeholderComponent\",\"@placeholder\",\"&default\"],false,[\"if\",\"component\",\"readonly\",\"yield\",\"and\",\"not\",\"on\"]]",
    "moduleName": "pixeditor/components/power-select/custom-trigger.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});