define("pixeditor/stories/pix-button.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.size = _exports.shape = _exports.loader = _exports.disabled = _exports.colors = _exports.borders = _exports.argsTypes = _exports.Default = void 0;

  const Template = args => ({
    template: Ember.HTMLBars.template(
    /*
      
        <section style={{this.style}}>
          <PixButton
            @triggerAction={{action triggerAction}}
            @loadingColor={{loadingColor}}
            @shape={{shape}}
            @backgroundColor={{backgroundColor}}
            @isDisabled={{isDisabled}}
            @isLoading={{isLoading}}
            @size={{size}}
            @isBorderVisible={{isBorderVisible}}
          >
            {{this.label}}
          </PixButton>
        </section>
        {{#each extraButtons as |button|}}
          <section style={{button.style}}>
            <PixButton
              @triggerAction={{action triggerAction}}
              @loadingColor={{button.loadingColor}}
              @shape={{button.shape}}
              @backgroundColor={{button.backgroundColor}}
              @isDisabled={{button.isDisabled}}
              @isLoading={{button.isLoading}}
              @size={{button.size}}
              @isBorderVisible={{button.isBorderVisible}}
            >
              {{button.label}}
            </PixButton>
          </section>
        {{/each}}
    */
    {
      "id": "ydk+XkWs",
      "block": "[[[1,\"\\n    \"],[10,\"section\"],[15,5,[30,0,[\"style\"]]],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@triggerAction\",\"@loadingColor\",\"@shape\",\"@backgroundColor\",\"@isDisabled\",\"@isLoading\",\"@size\",\"@isBorderVisible\"],[[28,[37,1],[[30,0],[33,2]],null],[36,3],[36,4],[36,5],[36,6],[36,7],[36,8],[36,9]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[42,[28,[37,11],[[28,[37,11],[[33,12]],null]],null],null,[[[1,\"      \"],[10,\"section\"],[15,5,[30,1,[\"style\"]]],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@triggerAction\",\"@loadingColor\",\"@shape\",\"@backgroundColor\",\"@isDisabled\",\"@isLoading\",\"@size\",\"@isBorderVisible\"],[[28,[37,1],[[30,0],[33,2]],null],[30,1,[\"loadingColor\"]],[30,1,[\"shape\"]],[30,1,[\"backgroundColor\"]],[30,1,[\"isDisabled\"]],[30,1,[\"isLoading\"]],[30,1,[\"size\"]],[30,1,[\"isBorderVisible\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[30,1,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null]],[\"button\"],false,[\"pix-button\",\"action\",\"triggerAction\",\"loadingColor\",\"shape\",\"backgroundColor\",\"isDisabled\",\"isLoading\",\"size\",\"isBorderVisible\",\"each\",\"-track-array\",\"extraButtons\"]]",
      "moduleName": "(unknown template module)",
      "isStrictMode": false
    }),
    context: args
  });

  const Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    loadingColor: 'white',
    shape: 'squircle',
    size: 'big',
    backgroundColor: 'blue',
    label: 'Bouton',
    triggerAction: () => {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 2000);
      });
    }
  };
  const borders = Template.bind({});
  _exports.borders = borders;
  borders.args = { ...Default.args,
    label: 'Bouton avec bordure sur fond clair',
    backgroundColor: 'transparent-light',
    loadingColor: 'grey',
    isBorderVisible: true,
    extraButtons: [{ ...Default.args,
      label: 'Bouton avec bordure sur fond sombre',
      style: 'background-color: #775555',
      backgroundColor: 'transparent-dark',
      isBorderVisible: true
    }]
  };
  const colors = Template.bind({});
  _exports.colors = colors;
  colors.args = { ...Default.args,
    label: 'Bouton avec background blue (default)',
    extraButtons: [{ ...Default.args,
      label: 'Bouton avec background green',
      backgroundColor: 'green'
    }, { ...Default.args,
      label: 'Bouton avec background yellow',
      backgroundColor: 'yellow'
    }, { ...Default.args,
      label: 'Bouton avec background red',
      backgroundColor: 'red'
    }, { ...Default.args,
      label: 'Bouton avec background grey',
      backgroundColor: 'grey'
    }, { ...Default.args,
      label: 'Bouton avec bordure sur fond clair',
      backgroundColor: 'transparent-light',
      loadingColor: 'grey',
      isBorderVisible: true
    }, { ...Default.args,
      label: 'Bouton avec bordure sur fond sombre',
      style: 'background-color: #775555',
      backgroundColor: 'transparent-dark',
      isBorderVisible: true
    }]
  };
  const disabled = Template.bind({});
  _exports.disabled = disabled;
  disabled.args = { ...Default.args,
    label: 'Bouton désactivé',
    isDisabled: true
  };
  const loader = Template.bind({});
  _exports.loader = loader;
  loader.args = { ...Default.args,
    label: 'Bouton avec loader au clic',
    backgroundColor: 'yellow',
    loadingColor: 'grey',
    extraButtons: [{ ...Default.args,
      triggerAction: () => {},
      isLoading: true
    }]
  };
  const shape = Template.bind({});
  _exports.shape = shape;
  shape.args = { ...Default.args,
    label: 'Bouton rounded',
    shape: 'rounded'
  };
  const size = Template.bind({});
  _exports.size = size;
  size.args = { ...Default.args,
    label: 'Bouton small',
    size: 'small'
  };
  const argsTypes = {
    type: {
      name: 'type',
      description: 'type du bouton',
      type: {
        required: false
      },
      control: {
        disable: true
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'button'
        }
      }
    },
    triggerAction: {
      name: 'triggerAction',
      description: 'fonction à appeler en cas de clic, optionnel si le bouton est de type submit',
      type: {
        required: true
      },
      control: {
        disable: true
      }
    },
    loadingColor: {
      name: 'loadingColor',
      description: 'couleur de chargement: `white`, `grey`',
      type: {
        name: 'string',
        required: false
      },
      options: ['white', 'grey'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'white'
        }
      }
    },
    shape: {
      name: 'shape',
      description: 'forme: `rounded`,`squircle`',
      type: {
        name: 'string',
        required: false
      },
      options: ['rounded', 'squircle'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'squircle'
        }
      }
    },
    backgroundColor: {
      name: 'backgroundColor',
      description: 'color: `blue`, `green`, `yellow`, `red`, `grey`, `transparent-light`, `transparent-dark`',
      options: ['blue', 'green', 'yellow', 'red', 'grey', 'transparent-light', 'transparent-dark'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'blue'
        }
      }
    },
    isDisabled: {
      name: 'isDisabled',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    isLoading: {
      name: 'isLoading',
      description: 'Affiche un loader. Si `@triggerAction` retourne une promesse alors le loading est géré automatiquement.',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    size: {
      name: 'size',
      description: 'taille: `big`,`small`',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    isBorderVisible: {
      name: 'isBorderVisible',
      description: 'Paramètre utilisé seulement quand le `backgroundColor` est `transparent-light` ou `transparent-dark`',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    route: {
      name: 'route',
      description: 'Déprécié et remplacé par le composant PixButtonLink',
      type: {
        name: 'string',
        required: false
      }
    },
    model: {
      name: 'model',
      description: 'Déprécié et remplacé par le composant PixButtonLink',
      type: {
        required: false
      }
    }
  };
  _exports.argsTypes = argsTypes;
});