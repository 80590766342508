define("pixeditor/routes/competence-management/new", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompetenceManagementNewRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, (_class = class CompetenceManagementNewRoute extends _authenticated.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "templateName", 'competence-management/single');

      _initializerDefineProperty(this, "confirm", _descriptor, this);

      _initializerDefineProperty(this, "currentData", _descriptor2, this);

      _initializerDefineProperty(this, "idGenerator", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    model(params) {
      const area = this.store.peekRecord('area', params.area_id);
      return area.get('competences').then(competences => {
        const competence = this.store.createRecord('competence', {
          code: `${area.code}.${competences.length + 1}`,
          pixId: this.idGenerator.newId('competence')
        });
        return {
          competence,
          area
        };
      });
    }

    async afterModel(model) {
      if (model) {
        const area = model.area;
        const framework = await area.framework;
        this.currentData.setFramework(framework);
      }
    }

    setupController(controller) {
      super.setupController(...arguments);
      controller.edition = true;
    }

    async willTransition(transition) {
      const controller = this.controllerFor('competence-management.new');
      const edition = controller.edition;

      if (edition && !confirm('Êtes-vous sûr de vouloir abandonner la création en cours ?')) {
        transition.abort();
      } else if (edition) {
        controller.store.deleteRecord(controller.competence);
        return true;
      } else {
        return true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "confirm", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "idGenerator", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = CompetenceManagementNewRoute;
});