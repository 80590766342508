define("pixeditor/components/field/illustration", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="field" ...attributes>
    {{#if (or @value @edition)}}
      <label>{{@title}}</label>
    {{/if}}
    {{#if @value}}
      {{#if @value.url}}
        <img src={{@value.url}} alt="" class="clickable" {{on "click" @display}} role="presentation">
      {{else}}
        {{@value.file.name}} ({{@value.file.size}} octets)
      {{/if}}
      {{#if @edition}}
        <button {{on "click" this.remove}} class="ui button file-remove" type="button"><i class="remove icon" data-test-delete-illustration-button></i></button>
      {{/if}}
    {{/if}}
    {{#if @edition}}
      <FileUpload @name="illustration" @accept="image/*" @onFileAdd={{this.add}}>
      {{!-- template-lint-disable link-href-attributes  --}}
        <a class="ui button">
          {{#if @value}}
            Remplacer l'image...
          {{else}}
            Choisir une image...
          {{/if}}
        </a>
      {{!-- template-lint-enable link-href-attributes  --}}
      </FileUpload>
    {{/if}}
  </div>
  
  */
  {
    "id": "5SrvwVt+",
    "block": "[[[11,0],[24,0,\"field\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,2],[30,3]],null],[[[1,\"    \"],[10,\"label\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[41,[30,2],[[[41,[30,2,[\"url\"]],[[[1,\"      \"],[11,\"img\"],[16,\"src\",[30,2,[\"url\"]]],[24,\"alt\",\"\"],[24,0,\"clickable\"],[24,\"role\",\"presentation\"],[4,[38,2],[\"click\",[30,5]],null],[12],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,2,[\"file\",\"name\"]]],[1,\" (\"],[1,[30,2,[\"file\",\"size\"]]],[1,\" octets)\\n\"]],[]]],[41,[30,3],[[[1,\"      \"],[11,\"button\"],[24,0,\"ui button file-remove\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"remove\"]]],null],[12],[10,\"i\"],[14,0,\"remove icon\"],[14,\"data-test-delete-illustration-button\",\"\"],[12],[13],[13],[1,\"\\n\"]],[]],null]],[]],null],[41,[30,3],[[[1,\"    \"],[8,[39,3],null,[[\"@name\",\"@accept\",\"@onFileAdd\"],[\"illustration\",\"image/*\",[30,0,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"      \"],[10,3],[14,0,\"ui button\"],[12],[1,\"\\n\"],[41,[30,2],[[[1,\"          Remplacer l'image...\\n\"]],[]],[[[1,\"          Choisir une image...\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@edition\",\"@title\",\"@display\"],false,[\"if\",\"or\",\"on\",\"file-upload\"]]",
    "moduleName": "pixeditor/components/field/illustration.hbs",
    "isStrictMode": false
  });

  let Illustration = (_dec = Ember._action, _dec2 = Ember._action, (_class = class Illustration extends _component.default {
    remove() {
      this.args.removeIllustration();
    }

    async add(file) {
      const alt = await this.args.removeIllustration();
      this.args.addIllustration(file, alt);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "remove", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype)), _class));
  _exports.default = Illustration;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Illustration);
});