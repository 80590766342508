define("pixeditor/components/form/tube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form action="" class="ui form">
    {{#if @edition}}
      <Field::Input data-test-name-field @value={{@tube.name}} @edition={{@edition}} @label="Nom"/>
    {{/if}}
    <div class="ui raised segment">
      <Field::Input data-test-practical-title-fr-field @value={{@tube.practicalTitleFr}} @edition={{@edition}} @label="Titre pratique (fr)"/>
      <Field::Textarea data-test-practical-description-fr-field @title="Description pratique (fr) :" @value={{@tube.practicalDescriptionFr}} @edition={{@edition}} />
    </div>
    <div class="ui raised segment">
      <Field::Input data-test-practical-title-en-field @value={{@tube.practicalTitleEn}} @edition={{@edition}} @label="Titre pratique (en)"/>
      <Field::Textarea data-test-practical-description-en-field @title="Description pratique (en) :" @value={{@tube.practicalDescriptionEn}} @edition={{@edition}} />
    </div>
    {{#unless @edition}}
      <Field::Input data-test-pix-id-field @value={{@tube.pixId}} @title="Id" @edition={{false}} />
    {{/unless}}
  </form>
  
  */
  {
    "id": "4nMdJzC2",
    "block": "[[[10,\"form\"],[14,\"action\",\"\"],[14,0,\"ui form\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],[[24,\"data-test-name-field\",\"\"]],[[\"@value\",\"@edition\",\"@label\"],[[30,2,[\"name\"]],[30,1],\"Nom\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"ui raised segment\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-practical-title-fr-field\",\"\"]],[[\"@value\",\"@edition\",\"@label\"],[[30,2,[\"practicalTitleFr\"]],[30,1],\"Titre pratique (fr)\"]],null],[1,\"\\n    \"],[8,[39,2],[[24,\"data-test-practical-description-fr-field\",\"\"]],[[\"@title\",\"@value\",\"@edition\"],[\"Description pratique (fr) :\",[30,2,[\"practicalDescriptionFr\"]],[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ui raised segment\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,\"data-test-practical-title-en-field\",\"\"]],[[\"@value\",\"@edition\",\"@label\"],[[30,2,[\"practicalTitleEn\"]],[30,1],\"Titre pratique (en)\"]],null],[1,\"\\n    \"],[8,[39,2],[[24,\"data-test-practical-description-en-field\",\"\"]],[[\"@title\",\"@value\",\"@edition\"],[\"Description pratique (en) :\",[30,2,[\"practicalDescriptionEn\"]],[30,1]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[51,[30,1]],[[[1,\"    \"],[8,[39,1],[[24,\"data-test-pix-id-field\",\"\"]],[[\"@value\",\"@title\",\"@edition\"],[[30,2,[\"pixId\"]],\"Id\",false]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[\"@edition\",\"@tube\"],false,[\"if\",\"field/input\",\"field/textarea\",\"unless\"]]",
    "moduleName": "pixeditor/components/form/tube.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});