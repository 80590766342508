define("pixeditor/stories/pix-tooltip.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.right = _exports.left = _exports.isWide = _exports.isLight = _exports.isInline = _exports.bottom = _exports.argTypes = _exports.WithHTML = _exports.Default = void 0;

  const Template = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTooltip
              @id={{this.id}}
              @position={{this.position}}
              @isLight={{this.isLight}}
              @isInline={{this.isInline}}
              @isWide={{this.isWide}}>
              <:triggerElement>
                <PixButton aria-describedby={{this.id}}>
                  {{this.label}}
                </PixButton>
              </:triggerElement>
      
              <:tooltip>
                {{this.text}}
              </:tooltip>
            </PixTooltip>
          
      */
      {
        "id": "LoSF8ADd",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@position\",\"@isLight\",\"@isInline\",\"@isWide\"],[[30,0,[\"id\"]],[30,0,[\"position\"]],[30,0,[\"isLight\"]],[30,0,[\"isInline\"]],[30,0,[\"isWide\"]]]],[[\"triggerElement\",\"tooltip\"],[[[[1,\"\\n          \"],[8,[39,1],[[16,\"aria-describedby\",[30,0,[\"id\"]]]],null,[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"label\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[1,[30,0,[\"text\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tooltip\",\"pix-button\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  const TemplateWithHTMLElement = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTooltip
              @id={{this.id}}
              @isInline=true>
              <:triggerElement>
                <PixButton aria-describedby={{this.id}}>
                  {{this.label}}
                </PixButton>
              </:triggerElement>
      
              <:tooltip>
                <FaIcon @icon="external-link-alt" /> <strong>HTML/Ember</strong>
              </:tooltip>
            </PixTooltip>
          
      */
      {
        "id": "QdR37b7N",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@isInline\"],[[30,0,[\"id\"]],\"true\"]],[[\"triggerElement\",\"tooltip\"],[[[[1,\"\\n          \"],[8,[39,1],[[16,\"aria-describedby\",[30,0,[\"id\"]]]],null,[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"label\"]]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[39,2],null,[[\"@icon\"],[\"external-link-alt\"]],null],[1,\" \"],[10,\"strong\"],[12],[1,\"HTML/Ember\"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tooltip\",\"pix-button\",\"fa-icon\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  const Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    text: 'Hello World',
    label: 'À survoler pour voir la tooltip'
  };
  const isLight = Template.bind({});
  _exports.isLight = isLight;
  isLight.args = { ...Default.args,
    id: 'tooltip-light',
    isLight: true
  };
  const isWide = Template.bind({});
  _exports.isWide = isWide;
  isWide.args = { ...Default.args,
    id: 'tooltip-wide',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas molestie mauris vel viverra.',
    isWide: true
  };
  const isInline = Template.bind({});
  _exports.isInline = isInline;
  isInline.args = { ...Default.args,
    id: 'tooltip-large',
    text: 'Je suis une trèèèèèèèès longue information',
    isInline: true
  };
  const left = Template.bind({});
  _exports.left = left;
  left.args = { ...Default.args,
    id: 'tooltip-left',
    label: 'Mon infobulle apparaît à gauche',
    position: 'left',
    isInline: true
  };
  const right = Template.bind({});
  _exports.right = right;
  right.args = { ...Default.args,
    id: 'tooltip-right',
    label: 'Mon infobulle apparaît à droite',
    position: 'right',
    isInline: true
  };
  const bottom = Template.bind({});
  _exports.bottom = bottom;
  bottom.args = { ...Default.args,
    id: 'tooltip-bottom',
    label: 'Mon infobulle apparaît en bas',
    position: 'bottom'
  };
  const WithHTML = TemplateWithHTMLElement.bind({});
  _exports.WithHTML = WithHTML;
  WithHTML.args = {
    label: 'À survoler pour voir la tooltip'
  };
  const argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant permettant de référencer le déclencheur via aria-describedby',
      type: {
        name: 'string',
        required: true
      }
    },
    text: {
      name: 'text',
      defaultValue: 'Tooltiptop',
      description: 'Texte à afficher',
      type: {
        name: 'string',
        required: false
      }
    },
    position: {
      name: 'position',
      description: 'Position de la tooltip',
      type: {
        name: 'string',
        required: false
      },
      table: {
        defaultValue: {
          summary: 'top'
        }
      },
      control: {
        type: 'select',
        options: ['top', 'top-left', 'top-right', 'right', 'bottom', 'bottom-left', 'bottom-right', 'left']
      }
    },
    isLight: {
      name: 'isLight',
      description: 'Affichage en mode clair',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isInline: {
      name: 'isInline',
      description: 'Affichage en une seule ligne',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isWide: {
      name: 'isWide',
      description: 'Affichage large',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});