define("pixeditor/components/list/events-log", ["exports", "pixeditor/components/list/sorted"], function (_exports, _sorted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable class="list-log" as |t|>
    <t.head
            @columns={{this.headers}}
            @widthConstraint={{'eq-container'}}
            @resizeMode={{'fluid'}}
            @onUpdateSorts={{this.sortBy}}
            @sortFunction={{this.sort}}
            @sorts={{this.sorts}}/>
    <t.body
            @renderAll={{this.renderAll}}
            @rows={{@list}}
            @rowSelectionMode={{'single'}}
            @checkboxSelectionMode={{'none'}}
             as |b|>
      <b.row class={{b.rowValue.actionCSS}} data-test-skillLog as |r|>
        <r.cell as |value|>
            {{value}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  
  */
  {
    "id": "x/PCk2sO",
    "block": "[[[8,[39,0],[[24,0,\"list-log\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,[[\"@columns\",\"@widthConstraint\",\"@resizeMode\",\"@onUpdateSorts\",\"@sortFunction\",\"@sorts\"],[[30,0,[\"headers\"]],\"eq-container\",\"fluid\",[30,0,[\"sortBy\"]],[30,0,[\"sort\"]],[30,0,[\"sorts\"]]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,[[\"@renderAll\",\"@rows\",\"@rowSelectionMode\",\"@checkboxSelectionMode\"],[[30,0,[\"renderAll\"]],[30,2],\"single\",\"none\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"row\"]],[[16,0,[30,3,[\"rowValue\",\"actionCSS\"]]],[24,\"data-test-skillLog\",\"\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,4,[\"cell\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[1,[30,5]],[1,\"\\n      \"]],[5]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"t\",\"@list\",\"b\",\"r\",\"value\"],false,[\"ember-table\"]]",
    "moduleName": "pixeditor/components/list/events-log.hbs",
    "isStrictMode": false
  });

  class EventsLogList extends _sorted.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "headers", [{
        name: 'Acquis',
        valuePath: 'skillName',
        maxWidth: 150,
        skill: true
      }, {
        name: 'Description',
        valuePath: 'text'
      }, {
        name: 'Date',
        valuePath: 'date',
        maxWidth: 100
      }, {
        name: 'Auteur',
        valuePath: 'author',
        maxWidth: 100
      }, {
        name: 'Action',
        valuePath: 'action',
        maxWidth: 150
      }]);
    }

  }

  _exports.default = EventsLogList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EventsLogList);
});