define("pixeditor/components/competence/competence-grid-tube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td data-test-tube-cell class="tube-cell">
    {{#if (eq @section "skills")}}
      <LinkTo data-test-tube-managment @route="competence.tubes.single" @model={{@tube}}>{{@tube.name}}</LinkTo>
    {{else}}
      {{@tube.name}}
    {{/if}}
  </td>
  
  {{#if (eq @view "workbench")}}
    {{#each @tube.filledSkills as |skills index|}}
      <Competence::Grid::GridCell @section={{@section}}
                                  @view={{@view}}
                                  @skill={{get skills 0}}
                                  @skills={{skills}}
                                  @link={{@link}}
                                  @tube={{@tube}}
                                  @index={{index}} />
    {{/each}}
  {{else if (eq @view "draft")}}
    {{#each @tube.filledLastDraftSkills as |skill index|}}
      <Competence::Grid::GridCell @section={{@section}}
                                  @languageFilter={{@languageFilter}}
                                  @view={{@view}}
                                  @skill={{skill}}
                                  @link={{@link}}
                                  @tube={{@tube}}
                                  @index={{index}} />
    {{/each}}
  {{else}}
    {{#each @tube.filledProductionSkills as |skill index|}}
      <Competence::Grid::GridCell @section={{@section}}
                                  @languageFilter={{@languageFilter}}
                                  @view={{@view}}
                                  @skill={{skill}}
                                  @link={{@link}}
                                  @tube={{@tube}}
                                  @index={{index}} />
    {{/each}}
  {{/if}}
  
  */
  {
    "id": "ldbrhhvH",
    "block": "[[[10,\"td\"],[14,\"data-test-tube-cell\",\"\"],[14,0,\"tube-cell\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1],\"skills\"],null],[[[1,\"    \"],[8,[39,2],[[24,\"data-test-tube-managment\",\"\"]],[[\"@route\",\"@model\"],[\"competence.tubes.single\",[30,2]]],[[\"default\"],[[[[1,[30,2,[\"name\"]]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,2,[\"name\"]]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[41,[28,[37,1],[[30,3],\"workbench\"],null],[[[42,[28,[37,4],[[28,[37,4],[[30,2,[\"filledSkills\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@section\",\"@view\",\"@skill\",\"@skills\",\"@link\",\"@tube\",\"@index\"],[[30,1],[30,3],[28,[37,6],[[30,4],0],null],[30,4],[30,6],[30,2],[30,5]]],null],[1,\"\\n\"]],[4,5]],null]],[]],[[[41,[28,[37,1],[[30,3],\"draft\"],null],[[[42,[28,[37,4],[[28,[37,4],[[30,2,[\"filledLastDraftSkills\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@section\",\"@languageFilter\",\"@view\",\"@skill\",\"@link\",\"@tube\",\"@index\"],[[30,1],[30,9],[30,3],[30,7],[30,6],[30,2],[30,8]]],null],[1,\"\\n\"]],[7,8]],null]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,2,[\"filledProductionSkills\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],null,[[\"@section\",\"@languageFilter\",\"@view\",\"@skill\",\"@link\",\"@tube\",\"@index\"],[[30,1],[30,9],[30,3],[30,10],[30,6],[30,2],[30,11]]],null],[1,\"\\n\"]],[10,11]],null]],[]]]],[]]]],[\"@section\",\"@tube\",\"@view\",\"skills\",\"index\",\"@link\",\"skill\",\"index\",\"@languageFilter\",\"skill\",\"index\"],false,[\"if\",\"eq\",\"link-to\",\"each\",\"-track-array\",\"competence/grid/grid-cell\",\"get\"]]",
    "moduleName": "pixeditor/components/competence/competence-grid-tube.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});