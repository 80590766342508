define("pixeditor/serializers/changelog-entry", ["exports", "pixeditor/serializers/note"], function (_exports, _note) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChangelogEntryClass extends _note.default {}

  _exports.default = ChangelogEntryClass;
});