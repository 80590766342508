define("pixeditor/controllers/competence/skills/single/archive/single", ["exports", "pixeditor/controllers/competence/prototypes/single"], function (_exports, _single) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SingleController = (_dec = Ember.inject.controller('competence.skills.single.archive'), _dec2 = Ember._action, _dec3 = Ember._action, (_class = class SingleController extends _single.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "elementClass", 'archive-challenge');

      _initializerDefineProperty(this, "parentController", _descriptor, this);

      _defineProperty(this, "mayValidate", false);

      _defineProperty(this, "mayMove", false);

      _defineProperty(this, "mayEdit", false);

      _defineProperty(this, "mayDuplicate", false);

      _defineProperty(this, "mayAccessAlternatives", false);
    }

    get maximized() {
      return this.parentController.rightMaximized;
    }

    get challengeTitle() {
      if (this.challenge.isPrototype) {
        return 'Prototype n°' + this.challenge.version;
      } else {
        return `Version n°${this.challenge.version} - Déclinaison n°${this.challenge.alternativeVersion}`;
      }
    }

    maximize() {
      this.parentController.maximizeRight(true);
    }

    minimize() {
      this.parentController.maximizeRight(false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "parentController", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "maximize", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "maximize"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "minimize", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "minimize"), _class.prototype)), _class));
  _exports.default = SingleController;
});