define("pixeditor/stories/pix-tooltip-deprecated.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.unescapeHtml = _exports.right = _exports.left = _exports.isWide = _exports.isLight = _exports.isInline = _exports.bottom = _exports.argTypes = _exports.Default = void 0;

  const Template = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixTooltipDeprecated
              @id="tooltip-1"
              @text={{this.text}}
              @position={{this.position}}
              @isLight={{this.isLight}}
              @isInline={{this.isInline}}
              @isWide={{this.isWide}}
              @unescapeHtml={{this.unescapeHtml}}
            >
              <PixButton aria-describedby="tooltip-1">
                {{this.label}}
              </PixButton>
            </PixTooltipDeprecated>
          
      */
      {
        "id": "kGDTXJAp",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@text\",\"@position\",\"@isLight\",\"@isInline\",\"@isWide\",\"@unescapeHtml\"],[\"tooltip-1\",[30,0,[\"text\"]],[30,0,[\"position\"]],[30,0,[\"isLight\"]],[30,0,[\"isInline\"]],[30,0,[\"isWide\"]],[30,0,[\"unescapeHtml\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],[[24,\"aria-describedby\",\"tooltip-1\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[1,[30,0,[\"label\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-tooltip-deprecated\",\"pix-button\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  const Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    text: 'Hello World',
    label: 'À survoler pour voir la tooltip'
  };
  const isLight = Template.bind({});
  _exports.isLight = isLight;
  isLight.args = { ...Default.args,
    isLight: true
  };
  const isWide = Template.bind({});
  _exports.isWide = isWide;
  isWide.args = { ...Default.args,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut egestas molestie mauris vel viverra.',
    isWide: true
  };
  const isInline = Template.bind({});
  _exports.isInline = isInline;
  isInline.args = { ...Default.args,
    text: 'Je suis une trèèèèèèèès longue information',
    isInline: true
  };
  const left = Template.bind({});
  _exports.left = left;
  left.args = { ...Default.args,
    label: 'Mon infobulle apparaît à gauche',
    position: 'left',
    isInline: true
  };
  const right = Template.bind({});
  _exports.right = right;
  right.args = { ...Default.args,
    label: 'Mon infobulle apparaît à droite',
    position: 'right',
    isInline: true
  };
  const bottom = Template.bind({});
  _exports.bottom = bottom;
  bottom.args = { ...Default.args,
    label: 'Mon infobulle apparaît en bas',
    position: 'bottom'
  };
  const unescapeHtml = Template.bind({});
  _exports.unescapeHtml = unescapeHtml;
  unescapeHtml.args = { ...Default.args,
    text: 'Hello <b style="color: red;">W</b>orld',
    label: "J'affiche du html",
    unescapeHtml: true
  };
  const argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant permettant de référencer le déclencheur via aria-describedby',
      type: {
        name: 'string',
        required: true
      }
    },
    text: {
      name: 'text',
      defaultValue: 'Tooltiptop',
      description: 'Texte à afficher',
      type: {
        name: 'string',
        required: false
      }
    },
    position: {
      name: 'position',
      description: 'Position de la tooltip',
      type: {
        name: 'string',
        required: false
      },
      table: {
        defaultValue: {
          summary: 'top'
        }
      },
      control: {
        type: 'select',
        options: ['top', 'top-left', 'top-right', 'right', 'bottom', 'bottom-left', 'bottom-right', 'left']
      }
    },
    isLight: {
      name: 'isLight',
      description: 'Affichage en mode clair',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isInline: {
      name: 'isInline',
      description: 'Affichage en une seule ligne',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    isWide: {
      name: 'isWide',
      description: 'Affichage large',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    },
    unescapeHtml: {
      name: 'unescapeHtml',
      description: "Évite d'échapper les caractères HTML",
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        defaultValue: {
          summary: false
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});