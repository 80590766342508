define("pixeditor/components/field/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{concat "field" (if @edition "" " disabled")}} ...attributes>
    <p>{{@title}}</p>
    <div class="ui input">
      {{#if @label}}
        <label class="label-input" for="input" >{{@label}} : </label>
      {{/if}}
      <Input id="input" @value={{@value}} readonly={{not @edition}} />
    </div>
  </div>
  
  */
  {
    "id": "HIuoFXcH",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"field\",[52,[30,1],\"\",\" disabled\"]],null]],[17,2],[12],[1,\"\\n  \"],[10,2],[12],[1,[30,3]],[13],[1,\"\\n  \"],[10,0],[14,0,\"ui input\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[10,\"label\"],[14,0,\"label-input\"],[14,\"for\",\"input\"],[12],[1,[30,4]],[1,\" : \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,2],[[24,1,\"input\"],[16,\"readonly\",[28,[37,3],[[30,1]],null]]],[[\"@value\"],[[30,5]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@edition\",\"&attrs\",\"@title\",\"@label\",\"@value\"],false,[\"concat\",\"if\",\"input\",\"not\"]]",
    "moduleName": "pixeditor/components/field/input.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});