define("pixeditor/templates/competence/skills/single/archive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="main-title  {{if this.config.lite "lite" ""}}">
    <h1 class="ui header">
    <div class="ui right floated menu">
      <LinkTo @route="competence.skills.single" class="ui button icon item"><i class="icon window close"></i></LinkTo>
    </div>
        Anciennes épreuves de {{this.skill.name}} ({{this.skill.id}})
    </h1>
  </div>
  {{#unless this.rightMaximized}}
    <div class="ui attached segment competence {{this.size}}">
      <List::Archive @list={{this.challengeList}} />
    </div>
    <div class="ui borderless bottom attached labelled icon menu">
      <div class="item competence-info"><div>Prototypes : {{this.prototypesCount}}</div><div>Déclinaisons : {{this.alternativesCount}}</div></div>
    </div>
  {{/unless}}
  {{outlet}}
  
  */
  {
    "id": "RUEJFywB",
    "block": "[[[10,0],[15,0,[29,[\"main-title  \",[52,[30,0,[\"config\",\"lite\"]],\"lite\",\"\"]]]],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui right floated menu\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"ui button icon item\"]],[[\"@route\"],[\"competence.skills.single\"]],[[\"default\"],[[[[10,\"i\"],[14,0,\"icon window close\"],[12],[13]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n      Anciennes épreuves de \"],[1,[30,0,[\"skill\",\"name\"]]],[1,\" (\"],[1,[30,0,[\"skill\",\"id\"]]],[1,\")\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"rightMaximized\"]]],[[[1,\"  \"],[10,0],[15,0,[29,[\"ui attached segment competence \",[30,0,[\"size\"]]]]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@list\"],[[30,0,[\"challengeList\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ui borderless bottom attached labelled icon menu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item competence-info\"],[12],[10,0],[12],[1,\"Prototypes : \"],[1,[30,0,[\"prototypesCount\"]]],[13],[10,0],[12],[1,\"Déclinaisons : \"],[1,[30,0,[\"alternativesCount\"]]],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"if\",\"link-to\",\"unless\",\"list/archive\",\"component\",\"-outlet\"]]",
    "moduleName": "pixeditor/templates/competence/skills/single/archive.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});