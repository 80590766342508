define("pixeditor/components/competence/grid/cell-skill-workbench", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="skill-cell skill-workbench">
    <LinkTo @route="competence.skills.list" @models={{array @tube.id @skill.level}} class="skill-cell__link">
      <div class="skill-name ">
        {{@skill.name}}
      </div>
      <div class="skill-workbench__status ">
        {{#if this.hasDraftSkill}}
          <span data-test-draft-count class="workbench-status draft-skill ui tiny label" title="En construction">{{this.draftCount}}</span>
        {{/if}}
  
        {{#if this.hasActiveSkill}}
          <span data-test-active-count class="workbench-status active-skill ui tiny label" title="Validé">1</span>
        {{/if}}
  
        {{#if this.hasArchivedSkill}}
          <span data-test-archived-count class="workbench-status archive-skill ui tiny label" title="Archivé">{{this.archivedCount}}</span>
        {{/if}}
  
        {{#if this.hasObsoleteSkill}}
          <span data-test-obsolete-count class="workbench-status obsolete-skill ui tiny label" title="Périmé">{{this.obsoleteCount}}</span>
        {{/if}}
      </div>
    </LinkTo>
  </td>
  
  */
  {
    "id": "LKZ2vy3u",
    "block": "[[[10,\"td\"],[14,0,\"skill-cell skill-workbench\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"skill-cell__link\"]],[[\"@route\",\"@models\"],[\"competence.skills.list\",[28,[37,1],[[30,1,[\"id\"]],[30,2,[\"level\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"skill-name \"],[12],[1,\"\\n      \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"skill-workbench__status \"],[12],[1,\"\\n\"],[41,[30,0,[\"hasDraftSkill\"]],[[[1,\"        \"],[10,1],[14,\"data-test-draft-count\",\"\"],[14,0,\"workbench-status draft-skill ui tiny label\"],[14,\"title\",\"En construction\"],[12],[1,[30,0,[\"draftCount\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasActiveSkill\"]],[[[1,\"        \"],[10,1],[14,\"data-test-active-count\",\"\"],[14,0,\"workbench-status active-skill ui tiny label\"],[14,\"title\",\"Validé\"],[12],[1,\"1\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasArchivedSkill\"]],[[[1,\"        \"],[10,1],[14,\"data-test-archived-count\",\"\"],[14,0,\"workbench-status archive-skill ui tiny label\"],[14,\"title\",\"Archivé\"],[12],[1,[30,0,[\"archivedCount\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"hasObsoleteSkill\"]],[[[1,\"        \"],[10,1],[14,\"data-test-obsolete-count\",\"\"],[14,0,\"workbench-status obsolete-skill ui tiny label\"],[14,\"title\",\"Périmé\"],[12],[1,[30,0,[\"obsoleteCount\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@tube\",\"@skill\"],false,[\"link-to\",\"array\",\"if\"]]",
    "moduleName": "pixeditor/components/competence/grid/cell-skill-workbench.hbs",
    "isStrictMode": false
  });

  class CompetenceGridCellSkillWorkbenchComponent extends _component.default {
    get archivedCount() {
      const archivedSkill = this.args.skills.filter(skill => skill.isArchived);
      return archivedSkill.length;
    }

    get obsoleteCount() {
      const obsoleteSkills = this.args.skills.filter(skill => skill.isObsolete);
      return obsoleteSkills.length;
    }

    get draftCount() {
      const draftSkill = this.args.skills.filter(skill => skill.isDraft);
      return draftSkill.length;
    }

    get hasActiveSkill() {
      const activeSkill = this.args.skills.filter(skill => skill.isActive);
      return activeSkill.length > 0;
    }

    get hasDraftSkill() {
      return this.draftCount > 0;
    }

    get hasArchivedSkill() {
      return this.archivedCount > 0;
    }

    get hasObsoleteSkill() {
      return this.obsoleteCount > 0;
    }

  }

  _exports.default = CompetenceGridCellSkillWorkbenchComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CompetenceGridCellSkillWorkbenchComponent);
});