define("pixeditor/helpers/convert-language-as-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.convertLanguageAsFlag = convertLanguageAsFlag;
  _exports.default = void 0;

  function convertLanguageAsFlag(_ref) {
    let [language] = _ref;

    switch (language) {
      case 'fr-fr':
        return 'fr fr-fr';

      case 'en-us':
      case 'en':
        return 'gb uk';
    }

    return language;
  }

  var _default = Ember.Helper.helper(convertLanguageAsFlag);

  _exports.default = _default;
});