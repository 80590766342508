define("pixeditor/serializers/note", ["exports", "pixeditor/serializers/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NoteSerializer extends _airtable.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "attrs", {
        text: 'Texte',
        recordId: 'Record_Id',
        author: 'Auteur',
        changelog: 'Changelog',
        createdAt: 'Date',
        status: 'Statut',
        elementType: 'Type d\'élément',
        skillName: 'Nom de l\'acquis',
        action: 'Action'
      });
    }

    payloadKeyFromModelName() {
      return 'Notes';
    }

    extractAttributes() {
      const attributes = super.extractAttributes(...arguments);
      ['changelog'].forEach(key => {
        if (attributes[key]) {
          if (attributes[key] === 'oui') {
            attributes[key] = true;
          } else {
            attributes[key] = false;
          }
        }
      });
      return attributes;
    }

    serializeAttribute(snapshot, json, key) {
      if (['changelog'].includes(key)) {
        const payloadKey = this._getMappedKey(key, snapshot.type);

        const value = snapshot.attr(key);

        if (value) {
          json[payloadKey] = 'oui';
        } else {
          json[payloadKey] = 'non';
        }
      } else {
        return super.serializeAttribute(...arguments);
      }
    }

  }

  _exports.default = NoteSerializer;
});