define("pixeditor/mirage/factories/competence", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    title: 'Title',
    titleEn: 'TitleEn',
    code: 'Code',
    description: 'Description',
    descriptionEn: 'DescriptionEn',
    pixId: '',
    source: 'Pix'
  });

  _exports.default = _default;
});