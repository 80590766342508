define("pixeditor/components/field/copy", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Textarea id="copy" @value={{@value}} {{did-insert this.copy}}/>
  <label for="copy"></label>
  */
  {
    "id": "MMo7z2U9",
    "block": "[[[8,[39,0],[[24,1,\"copy\"],[4,[38,1],[[30,0,[\"copy\"]]],null]],[[\"@value\"],[[30,1]]],null],[1,\"\"],[10,\"label\"],[14,\"for\",\"copy\"],[12],[13]],[\"@value\"],false,[\"textarea\",\"did-insert\"]]",
    "moduleName": "pixeditor/components/field/copy.hbs",
    "isStrictMode": false
  });

  let FieldCopyComponent = (_dec = Ember._action, (_class = class FieldCopyComponent extends _component.default {
    copy(element) {
      element.select();

      try {
        var successful = document.execCommand('copy');
        this.args.onCopied(successful);
      } catch (err) {
        this.args.onCopied(false);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "copy", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "copy"), _class.prototype)), _class));
  _exports.default = FieldCopyComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FieldCopyComponent);
});