define("pixeditor/components/pop-in/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
      @title="Illustration"
      @onCloseButtonClick={{@close}}
  >
    <:content>
      <img class="image-modal" src={{@imageSrc}} alt="illustration">
    </:content>
  </PixModal>
  */
  {
    "id": "e2qPHpD7",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[\"Illustration\",[30,1]]],[[\"content\"],[[[[1,\"\\n    \"],[10,\"img\"],[14,0,\"image-modal\"],[15,\"src\",[30,2]],[14,\"alt\",\"illustration\"],[12],[13],[1,\"\\n  \"]],[]]]]]],[\"@close\",\"@imageSrc\"],false,[\"pix-modal\"]]",
    "moduleName": "pixeditor/components/pop-in/image.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});