define("pixeditor/serializers/theme", ["exports", "pixeditor/serializers/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ThemeSerializer extends _airtable.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "primaryKey", 'Record Id');

      _defineProperty(this, "attrs", {
        name: 'Nom',
        nameEnUs: 'Titre en-us',
        rawTubes: 'Tubes',
        competence: 'Competence',
        index: 'Index'
      });
    }

    payloadKeyFromModelName() {
      return 'Thematiques';
    }

  }

  _exports.default = ThemeSerializer;
});