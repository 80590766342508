define("pixeditor/adapters/tube", ["exports", "pixeditor/adapters/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TubeAdapter extends _airtable.default {
    pathForType() {
      return 'Tubes';
    }

  }

  _exports.default = TubeAdapter;
});