define("pixeditor/stories/pix-radio-button.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isDisabled = _exports.disabledChecked = _exports.defaultChecked = _exports.argTypes = _exports.Default = void 0;

  const Template = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixRadioButton
              @label={{label}}
              @value={{value}}
              @isDisabled={{isDisabled}}
            />
          
      */
      {
        "id": "vNxi1HTE",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@label\",\"@value\",\"@isDisabled\"],[[36,1],[36,2],[36,3]]],null],[1,\"\\n    \"]],[],false,[\"pix-radio-button\",\"label\",\"value\",\"isDisabled\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  const Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    label: 'Poivron'
  };
  const isDisabled = Template.bind({});
  _exports.isDisabled = isDisabled;
  isDisabled.args = { ...Default.args,
    isDisabled: true
  };

  const checked = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixRadioButton
              @label={{label}}
              @isDisabled={{isDisabled}}
              checked
            />
          
      */
      {
        "id": "bLRqUBe3",
        "block": "[[[1,\"\\n      \"],[8,[39,0],[[24,\"checked\",\"\"]],[[\"@label\",\"@isDisabled\"],[[36,1],[36,2]]],null],[1,\"\\n    \"]],[],false,[\"pix-radio-button\",\"label\",\"isDisabled\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  const disabledChecked = checked.bind({});
  _exports.disabledChecked = disabledChecked;
  disabledChecked.args = { ...Default.args,
    isDisabled: true
  };
  const defaultChecked = checked.bind({});
  _exports.defaultChecked = defaultChecked;
  defaultChecked.args = Default.args;
  const argTypes = {
    label: {
      name: 'label',
      description: 'Le label du bouton radio',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    value: {
      name: 'value',
      description: "Valeur permettant d'identifier l'option sélectionnée",
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    isDisabled: {
      name: 'isDisabled',
      description: 'Pour désactiver/activer le bouton radio',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false,
      table: {
        defaultValue: {
          summary: 'false'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});