define("pixeditor/routes/target-profile", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TargetProfileRoute extends _authenticated.default {
    model() {
      return this.modelFor('application');
    }

    async afterModel(model) {
      const getAreas = model.map(framework => framework.areas);
      const frameworkAreas = await Promise.all(getAreas);
      const getCompetences = frameworkAreas.map(areas => areas.map(area => area.competences)).flat();
      const areaCompetences = await Promise.all(getCompetences);
      const getTheme = areaCompetences.map(competences => competences.map(competence => competence.rawThemes)).flat();
      const competenceTheme = await Promise.all(getTheme);
      const getTubes = competenceTheme.map(themes => themes.map(theme => theme.rawTubes)).flat();
      const themeTubes = await Promise.all(getTubes);
      const getSkills = themeTubes.map(tubes => tubes.map(tube => tube.rawSkills)).flat();
      return Promise.all(getSkills);
    }

  }

  _exports.default = TargetProfileRoute;
});