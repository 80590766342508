define("pixeditor/serializers/tutorial", ["exports", "pixeditor/serializers/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TutorialSerializer extends _airtable.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "primaryKey", 'Record ID');

      _defineProperty(this, "attrs", {
        title: 'Titre',
        duration: 'Durée',
        source: 'Source',
        format: 'Format',
        link: 'Lien',
        license: 'License',
        tags: 'Tags',
        level: 'niveau',
        date: 'Date maj',
        crush: 'CoupDeCoeur',
        pixId: 'id persistant',
        language: 'Langue'
      });
    }

    payloadKeyFromModelName() {
      return 'Tutoriels';
    }

    extractAttributes() {
      const attributes = super.extractAttributes(...arguments);

      if (attributes['crush']) {
        if (attributes['crush'] === 'YES') {
          attributes['crush'] = true;
        } else {
          attributes['crush'] = false;
        }
      }

      return attributes;
    }

    serializeAttribute(snapshot, json, key) {
      if (key === 'crush') {
        const payloadKey = this._getMappedKey(key, snapshot.type);

        const value = snapshot.attr(key);

        if (value) {
          json[payloadKey] = 'YES';
        } else {
          json[payloadKey] = '';
        }
      } else {
        return super.serializeAttribute(...arguments);
      }
    }

  }

  _exports.default = TutorialSerializer;
});