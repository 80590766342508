define("pixeditor/templates/competence/themes/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="tube-header">
    <div class="ui menu">
      <div class="item header {{if this.creation "creation"}}">
        {{#if this.creation}}
          Nouveau theme
        {{else}}
          {{this.theme.name}}
        {{/if}}
      </div>
      <div class="ui right menu">
        <button class="ui button icon item" {{on "click" this.close}} type="button"><i class="icon window close"></i></button>
      </div>
    </div>
  </div>
  <div class="tube-details">
    <div class="tube-data" {{scroll-top this.edition}}>
      <Form::Theme @theme={{this.theme}} @edition={{this.edition}} />
    </div>
    <div class="ui vertical compact labeled icon menu tube-menu">
      {{#if this.edition}}
        <button class="ui button item important-action" {{on "click" this.save}} type="button">
          <i class="save icon"></i>
          Enregistrer
        </button>
        <button class="ui button item" {{on "click" this.cancelEdit}} type="button">
          <i class="ban icon"></i>
          Annuler
        </button>
      {{else}}
        {{#if this.mayEdit}}
          <button class="ui button item" {{on "click" this.edit}} type="button">
            <i class="edit icon"></i>
            Modifier
          </button>
        {{/if}}
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "WuYEH2Ct",
    "block": "[[[10,0],[14,0,\"tube-header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui menu\"],[12],[1,\"\\n    \"],[10,0],[15,0,[29,[\"item header \",[52,[30,0,[\"creation\"]],\"creation\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"creation\"]],[[[1,\"        Nouveau theme\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"theme\",\"name\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ui right menu\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"ui button icon item\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"close\"]]],null],[12],[10,\"i\"],[14,0,\"icon window close\"],[12],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"tube-details\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"tube-data\"],[4,[38,2],[[30,0,[\"edition\"]]],null],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@theme\",\"@edition\"],[[30,0,[\"theme\"]],[30,0,[\"edition\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ui vertical compact labeled icon menu tube-menu\"],[12],[1,\"\\n\"],[41,[30,0,[\"edition\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"ui button item important-action\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n        \"],[10,\"i\"],[14,0,\"save icon\"],[12],[13],[1,\"\\n        Enregistrer\\n      \"],[13],[1,\"\\n      \"],[11,\"button\"],[24,0,\"ui button item\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"cancelEdit\"]]],null],[12],[1,\"\\n        \"],[10,\"i\"],[14,0,\"ban icon\"],[12],[13],[1,\"\\n        Annuler\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"mayEdit\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"ui button item\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"edit\"]]],null],[12],[1,\"\\n          \"],[10,\"i\"],[14,0,\"edit icon\"],[12],[13],[1,\"\\n          Modifier\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"on\",\"scroll-top\",\"form/theme\"]]",
    "moduleName": "pixeditor/templates/competence/themes/single.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});