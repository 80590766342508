define("pixeditor/stories/pix-message.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withIcon = _exports.warning = _exports.success = _exports.error = _exports.argTypes = _exports.Default = void 0;

  const Template = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixMessage
            @type={{type}}
            @withIcon={{withIcon}}
            >
              Ceci est un message {{type}} avec un texte tellement long qu'il est nécessaire <br /> de l'afficher sur deux lignes.
            </PixMessage>
          
      */
      {
        "id": "3HYt1zz1",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@type\",\"@withIcon\"],[[36,1],[36,2]]],[[\"default\"],[[[[1,\"\\n        Ceci est un message \"],[1,[34,1]],[1,\" avec un texte tellement long qu'il est nécessaire \"],[10,\"br\"],[12],[13],[1,\" de l'afficher sur deux lignes.\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-message\",\"type\",\"withIcon\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  const Default = Template.bind({});
  _exports.Default = Default;
  const error = Template.bind({});
  _exports.error = error;
  error.args = {
    type: 'error',
    withIcon: true
  };
  const warning = Template.bind({});
  _exports.warning = warning;
  warning.args = {
    type: 'warning',
    withIcon: true
  };
  const success = Template.bind({});
  _exports.success = success;
  success.args = {
    type: 'success',
    withIcon: true
  };
  const withIcon = Template.bind({});
  _exports.withIcon = withIcon;
  withIcon.args = {
    withIcon: true
  };
  const argTypes = {
    type: {
      name: 'type',
      description: 'Type du message',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'info',
      control: {
        type: 'select',
        options: ['info', 'success', 'warning', 'alert', 'error']
      }
    },
    withIcon: {
      name: 'withIcon',
      description: 'Icône du message',
      type: {
        name: 'boolean',
        required: false
      },
      defaultValue: false,
      control: {
        type: 'boolean'
      }
    }
  };
  _exports.argTypes = argTypes;
});