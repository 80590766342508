define("pixeditor/routes/competence", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompetenceRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class CompetenceRoute extends _authenticated.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "currentData", _descriptor, this);

      _initializerDefineProperty(this, "paginatedQuery", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    model(params) {
      return this.store.findRecord('competence', params.competence_id);
    }

    async afterModel(model) {
      this.currentData.setCompetence(model);
      const area = await model.area;
      const framework = await area.framework;
      this.currentData.setFramework(framework);

      if (model.needsRefresh) {
        const themes = await model.hasMany('rawThemes').reload();
        const getTubes = themes.map(theme => theme.hasMany('rawTubes').reload());
        const tubes = await Promise.all(getTubes);
        const getSkills = tubes.map(tube => tube.hasMany('rawSkills').reload()).flat();
        const skills = await Promise.all(getSkills);
        const getChallenges = skills.map(skill => skill.hasMany('challenges').reload()).flat();
        await Promise.all(getChallenges);
        model.needsRefresh = false;
      } else {
        const themes = await model.rawThemes;
        const getTubes = themes.map(theme => theme.rawTubes);
        const tubesSet = await Promise.all(getTubes);
        const getSkills = tubesSet.map(tubes => tubes.map(tube => tube.rawSkills)).flat();
        const skillsSet = await Promise.all(getSkills);
        const getChallenges = skillsSet.map(skills => skills.map(skill => skill.challenges)).flat();
        await Promise.all(getChallenges);
      }
    }

    refreshModel() {
      const model = this.modelFor(this.routeName);
      model.needsRefresh = true;
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "paginatedQuery", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshModel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "refreshModel"), _class.prototype)), _class));
  _exports.default = CompetenceRoute;
});