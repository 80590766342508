define("pixeditor/components/list/notes", ["exports", "pixeditor/components/list/sorted"], function (_exports, _sorted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable as |t|>
    <t.head
      @columns={{this.headers}}
      @widthConstraint={{'eq-container'}}
      @containerWidthAdjustment={{-7}}
      @resizeMode={{'fluid'}}
      @onUpdateSorts={{this.sortBy}}
      @sorts={{this.sorts}}
      @sortFunction={{this.sort}}/>
    <t.body
      @renderAll={{this.renderAll}}
      @rows={{this.list}}
      @rowSelectionMode={{'single'}}
      @checkboxSelectionMode={{'none'}}
      @onSelect={{this.selectRow}} as |b|>
      <b.row data-test-note as |r|>
        <r.cell  class="{{if r.columnValue.style r.columnValue.style}}" as |value|>
          {{value}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  
  */
  {
    "id": "qcb5jtpf",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,[[\"@columns\",\"@widthConstraint\",\"@containerWidthAdjustment\",\"@resizeMode\",\"@onUpdateSorts\",\"@sorts\",\"@sortFunction\"],[[30,0,[\"headers\"]],\"eq-container\",-7,\"fluid\",[30,0,[\"sortBy\"]],[30,0,[\"sorts\"]],[30,0,[\"sort\"]]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,[[\"@renderAll\",\"@rows\",\"@rowSelectionMode\",\"@checkboxSelectionMode\",\"@onSelect\"],[[30,0,[\"renderAll\"]],[30,0,[\"list\"]],\"single\",\"none\",[30,0,[\"selectRow\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"row\"]],[[24,\"data-test-note\",\"\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"cell\"]],[[16,0,[29,[[52,[30,3,[\"columnValue\",\"style\"]],[30,3,[\"columnValue\",\"style\"]]]]]]],null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,4]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n    \"]],[3]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"t\",\"b\",\"r\",\"value\"],false,[\"ember-table\",\"if\"]]",
    "moduleName": "pixeditor/components/list/notes.hbs",
    "isStrictMode": false
  });

  let NoteList = (_dec = Ember._action, (_class = class NoteList extends _sorted.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "sortHandlers", {
        date: {
          type: 'date',
          field: 'createdAt'
        }
      });
    }

    get headers() {
      const headers = [{
        name: 'Date',
        valuePath: 'date',
        maxWidth: 150
      }];

      if (this.displayAuthor) {
        headers.push({
          name: 'Auteur',
          valuePath: 'author',
          maxWidth: 150,
          style: 'author-note'
        });
      }

      headers.push({
        name: 'Texte',
        valuePath: 'text'
      });

      if (this.displayStatus) {
        headers.push({
          name: 'Statut',
          valuePath: 'status',
          maxWidth: 150,
          style: 'status-note'
        });
      }

      return headers;
    }

    selectRow(row) {
      this.args.show(row);
    }

    get list() {
      if (Array.isArray(this.args.list)) {
        return this.args.list;
      } else {
        return [];
      }
    }

    get displayAuthor() {
      if (typeof this.args.displayAuthor !== 'undefined') {
        return this.args.displayAuthor;
      }

      return true;
    }

    get displayStatus() {
      if (typeof this.args.displayStatus !== 'undefined') {
        return this.args.displayStatus;
      }

      return true;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectRow", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "selectRow"), _class.prototype)), _class));
  _exports.default = NoteList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, NoteList);
});