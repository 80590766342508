define("pixeditor/stories/pix-input.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withLabel = _exports.withIcon = _exports.withErrorMessage = _exports.argTypes = _exports.Template = _exports.Default = void 0;

  const Template = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixInput
              @id={{id}}
              @label={{label}}
              @information={{information}}
              @errorMessage={{errorMessage}}
              @icon={{icon}}
              @isIconLeft={{isIconLeft}}
              placeholder='Jeanne, Pierre ...' />
          
      */
      {
        "id": "ijm7KD+F",
        "block": "[[[1,\"\\n      \"],[8,[39,0],[[24,\"placeholder\",\"Jeanne, Pierre ...\"]],[[\"@id\",\"@label\",\"@information\",\"@errorMessage\",\"@icon\",\"@isIconLeft\"],[[36,1],[36,2],[36,3],[36,4],[36,5],[36,6]]],null],[1,\"\\n    \"]],[],false,[\"pix-input\",\"id\",\"label\",\"information\",\"errorMessage\",\"icon\",\"isIconLeft\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.Template = Template;
  const Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    id: 'firstName'
  };
  const withLabel = Template.bind({});
  _exports.withLabel = withLabel;
  withLabel.args = {
    id: 'firstName',
    label: 'Prénom',
    information: 'a small information'
  };
  const withErrorMessage = Template.bind({});
  _exports.withErrorMessage = withErrorMessage;
  withErrorMessage.args = {
    id: 'firstName',
    label: 'Prénom',
    information: 'a small information',
    errorMessage: "un message d'erreur"
  };
  const withIcon = Template.bind({});
  _exports.withIcon = withIcon;
  withIcon.args = {
    id: 'firstName',
    label: 'Prénom',
    icon: 'eye'
  };
  const argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant du champ permettant de lui attacher un label',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    value: {
      name: 'value',
      description: "Valeur de l'input",
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    label: {
      name: 'label',
      description: "Le label de l'input",
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    information: {
      name: 'information',
      description: 'Un descriptif complétant le label',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    errorMessage: {
      name: 'errorMessage',
      description: "Affiche le message d'erreur donné et encadre en rouge le champ",
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    icon: {
      name: 'icon',
      description: "Affiche l'icône choisie à la fin de l'input",
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    isIconLeft: {
      name: 'isIconLeft',
      description: "Permet d'afficher l'icône choisie sur la gauche",
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      defaultValue: false,
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});