define("pixeditor/components/pix-return-to", ["exports", "@1024pix/pix-ui/components/pix-return-to"], function (_exports, _pixReturnTo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _pixReturnTo.default;
    }
  });
});