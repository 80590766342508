define("pixeditor/adapters/config", ["exports", "pixeditor/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ConfigAdapter extends _application.default {
    urlForFindRecord() {
      const baseUrl = this.buildURL();
      return `${baseUrl}/config`;
    }

  }

  _exports.default = ConfigAdapter;
});