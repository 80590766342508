define("pixeditor/routes/competence/prototypes/index", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _authenticated.default {
    setupController() {
      super.setupController(...arguments);
      const competenceController = this.controllerFor('competence');
      competenceController.setSection('challenges');
      competenceController.maximizeLeft(false);
      const view = competenceController.view;

      if (!['production', 'workbench', 'workbench-list'].includes(view)) {
        competenceController.setView('production');
      }
    }

  }

  _exports.default = IndexRoute;
});