define("pixeditor/controllers/competence/quality/single", ["exports", "pixeditor/controllers/competence/skills/single"], function (_exports, _single) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SingleController extends _single.default {}

  _exports.default = SingleController;
});