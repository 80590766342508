define("pixeditor/components/field/files", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="field" ...attributes>
    {{#if (or @value.length @edition)}}
      <label>{{@title}}</label>
    {{/if}}
    {{#if @value.length}}
      {{#each @value as |file|}}
        <a href={{file.url}} download={{file.filename}} target="_blank" rel="noopener noreferrer"><i class="file icon"></i> {{file.filename}}</a>
        {{#if @edition}}
          <button {{on "click" (fn this.remove file)}} class="ui button file-remove" data-test-delete-attachment-button type="button"><i class="remove icon"></i></button>
        {{/if}}
      {{/each}}
    {{/if}}
    {{#if @edition}}
      <FileUpload @name="files" @onFileAdd={{this.add}}>
        {{!-- template-lint-disable link-href-attributes  --}}
        <a class="ui button">
          Ajouter un fichier...
        </a>
        {{!-- template-lint-enable link-href-attributes  --}}
      </FileUpload>
      {{#if @value.length}}
        <div class="ui input">
            <label class="label-input" for="name">Nom :</label>
            <Input id="name" @value={{@baseName}} />
        </div>
      {{/if}}
    {{/if}}
  </div>
  
  */
  {
    "id": "QHKBUJsH",
    "block": "[[[11,0],[24,0,\"field\"],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,2,[\"length\"]],[30,3]],null],[[[1,\"    \"],[10,\"label\"],[12],[1,[30,4]],[13],[1,\"\\n\"]],[]],null],[41,[30,2,[\"length\"]],[[[42,[28,[37,3],[[28,[37,3],[[30,2]],null]],null],null,[[[1,\"      \"],[10,3],[15,6,[30,5,[\"url\"]]],[15,\"download\",[30,5,[\"filename\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[10,\"i\"],[14,0,\"file icon\"],[12],[13],[1,\" \"],[1,[30,5,[\"filename\"]]],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"        \"],[11,\"button\"],[24,0,\"ui button file-remove\"],[24,\"data-test-delete-attachment-button\",\"\"],[24,4,\"button\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"remove\"]],[30,5]],null]],null],[12],[10,\"i\"],[14,0,\"remove icon\"],[12],[13],[13],[1,\"\\n\"]],[]],null]],[5]],null]],[]],null],[41,[30,3],[[[1,\"    \"],[8,[39,6],null,[[\"@name\",\"@onFileAdd\"],[\"files\",[30,0,[\"add\"]]]],[[\"default\"],[[[[1,\"\\n\"],[1,\"      \"],[10,3],[14,0,\"ui button\"],[12],[1,\"\\n        Ajouter un fichier...\\n      \"],[13],[1,\"\\n\"],[1,\"    \"]],[]]]]],[1,\"\\n\"],[41,[30,2,[\"length\"]],[[[1,\"      \"],[10,0],[14,0,\"ui input\"],[12],[1,\"\\n          \"],[10,\"label\"],[14,0,\"label-input\"],[14,\"for\",\"name\"],[12],[1,\"Nom :\"],[13],[1,\"\\n          \"],[8,[39,7],[[24,1,\"name\"]],[[\"@value\"],[[30,6]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[13],[1,\"\\n\"]],[\"&attrs\",\"@value\",\"@edition\",\"@title\",\"file\",\"@baseName\"],false,[\"if\",\"or\",\"each\",\"-track-array\",\"on\",\"fn\",\"file-upload\",\"input\"]]",
    "moduleName": "pixeditor/components/field/files.hbs",
    "isStrictMode": false
  });

  let Files = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class Files extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "filePath", _descriptor, this);
    }

    remove(file) {
      this.args.removeAttachment(file);
    }

    add(file) {
      this.args.addAttachment(file);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "filePath", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype)), _class));
  _exports.default = Files;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Files);
});