define("pixeditor/components/target-profile/tube-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div {{on "click" (fn @clickAction @tube)}} ...attributes>
    <div data-test-tube-profile class={{if @selectedSkillLevel "active" ""}}>
      <i class="square icon {{if @selectedSkillLevel "active check" "outline"}}">
      </i>
      {{#if @showTubeDetails}}
        <div class="max-skill-level"><p>{{if @selectedSkillLevel @selectedSkillLevel ""}}</p></div>
      {{/if}}
      <div class="practicalTitle-profile {{if @selectedSkillLevel " active" ""}}">
        <p>
          <span class="practicalTitle-profile__color-red">{{@tube.name}} </span>: {{@tube.practicalTitleFr}}
        </p>
      </div>
    </div>
    <p class="practicalDescription-profile {{if @selectedSkillLevel " active"}}">{{@tube.practicalDescriptionFr}}</p>
  </div>
  
  */
  {
    "id": "B/BMMuvl",
    "block": "[[[11,0],[17,1],[4,[38,0],[\"click\",[28,[37,1],[[30,2],[30,3]],null]],null],[12],[1,\"\\n  \"],[10,0],[14,\"data-test-tube-profile\",\"\"],[15,0,[52,[30,4],\"active\",\"\"]],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[29,[\"square icon \",[52,[30,4],\"active check\",\"outline\"]]]],[12],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,5],[[[1,\"      \"],[10,0],[14,0,\"max-skill-level\"],[12],[10,2],[12],[1,[52,[30,4],[30,4],\"\"]],[13],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[15,0,[29,[\"practicalTitle-profile \",[52,[30,4],\" active\",\"\"]]]],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        \"],[10,1],[14,0,\"practicalTitle-profile__color-red\"],[12],[1,[30,3,[\"name\"]]],[1,\" \"],[13],[1,\": \"],[1,[30,3,[\"practicalTitleFr\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[15,0,[29,[\"practicalDescription-profile \",[52,[30,4],\" active\"]]]],[12],[1,[30,3,[\"practicalDescriptionFr\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@clickAction\",\"@tube\",\"@selectedSkillLevel\",\"@showTubeDetails\"],false,[\"on\",\"fn\",\"if\"]]",
    "moduleName": "pixeditor/components/target-profile/tube-profile.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});