define("pixeditor/components/competence/grid/cell-production", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td data-test-skill-cell class="skill-cell production {{this.alertCSS}}">
    <LinkTo data-test-skill-cell-link @route={{@link}} @model={{@skill.productionPrototype}} class="skill-cell__link">
      {{@skill.name}}
      <div class="alternative">
        <span data-test-production-alternative-length class="production" title="Nombre d'épreuves en production">{{this.productionChallengesFilteredByLanguage}}</span>
        {{#if this.draftAlternativesFilteredByLanguage}}
          <span data-test-draft-alternative-length class="draft" title="Nombre d'épreuves en cours de construction">({{this.draftAlternativesFilteredByLanguage}})</span>
        {{/if}}
        {{#if @skill.productionPrototype.notDeclinable}}
          <span class="not-declinable">NR</span>
        {{/if}}
      </div>
    </LinkTo>
  </td>
  
  */
  {
    "id": "Wx0z4quq",
    "block": "[[[10,\"td\"],[14,\"data-test-skill-cell\",\"\"],[15,0,[29,[\"skill-cell production \",[30,0,[\"alertCSS\"]]]]],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-skill-cell-link\",\"\"],[24,0,\"skill-cell__link\"]],[[\"@route\",\"@model\"],[[30,1],[30,2,[\"productionPrototype\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"],[10,0],[14,0,\"alternative\"],[12],[1,\"\\n      \"],[10,1],[14,\"data-test-production-alternative-length\",\"\"],[14,0,\"production\"],[14,\"title\",\"Nombre d'épreuves en production\"],[12],[1,[30,0,[\"productionChallengesFilteredByLanguage\"]]],[13],[1,\"\\n\"],[41,[30,0,[\"draftAlternativesFilteredByLanguage\"]],[[[1,\"        \"],[10,1],[14,\"data-test-draft-alternative-length\",\"\"],[14,0,\"draft\"],[14,\"title\",\"Nombre d'épreuves en cours de construction\"],[12],[1,\"(\"],[1,[30,0,[\"draftAlternativesFilteredByLanguage\"]]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[41,[30,2,[\"productionPrototype\",\"notDeclinable\"]],[[[1,\"        \"],[10,1],[14,0,\"not-declinable\"],[12],[1,\"NR\"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@link\",\"@skill\"],false,[\"link-to\",\"if\"]]",
    "moduleName": "pixeditor/components/competence/grid/cell-production.hbs",
    "isStrictMode": false
  });

  class CellProduction extends _component.default {
    get productionChallengesFilteredByLanguage() {
      const productionAlternatives = this.args.skill.productionPrototype.productionAlternatives;
      const productionChallenges = [...productionAlternatives, this.args.skill.productionPrototype];
      return this._filterChallengesByLanguage(productionChallenges);
    }

    get draftAlternativesFilteredByLanguage() {
      const draftAlternatives = this.args.skill.productionPrototype.draftAlternatives;
      return this._filterChallengesByLanguage(draftAlternatives);
    }

    _filterChallengesByLanguage(challenges) {
      const languageFilter = this.args.languageFilter;

      if (languageFilter) {
        const filteredChallenges = challenges.filter(challenge => {
          return challenge.locales?.includes(languageFilter);
        });
        return filteredChallenges.length;
      }

      return challenges.length;
    }

    get alertCSS() {
      if (this.productionChallengesFilteredByLanguage > 0) {
        return '';
      }

      if (this.draftAlternativesFilteredByLanguage > 0) {
        return 'warning';
      }

      return 'danger';
    }

  }

  _exports.default = CellProduction;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CellProduction);
});