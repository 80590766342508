define("pixeditor/services/notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class NotifyService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "target", null);
    }

    setTarget(aTarget) {
      this.target = aTarget;
    }

    message(text) {
      this.target.showMessage(text, true);
    }

    error(text) {
      this.target.showMessage(text, false);
    }

  }

  _exports.default = NotifyService;
});