define("pixeditor/templates/competence/i18n/single", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="i18n-header">
    <div class="ui menu">
      <div class="item header">{{this.skill.name}}</div>
      <div class="ui right menu">
        {{#if this.isMaximized}}
          <button class="ui icon button" {{on "click" this.minimize}} type="button"><i class="window minimize icon"></i>
          </button>
        {{else}}
          <button class="ui icon button" {{on "click" this.maximize}} type="button"><i
                  class="window maximize outline icon"></i></button>
        {{/if}}
        <button class="ui icon button" {{on "click" this.close}} type="button"><i class="icon window close"></i></button>
      </div>
    </div>
  </div>
  <div class="single i18n">
   <List::I18n @list={{this.challengesByLanguagesAndAlternativesCount}} @skill={{this.skill}}/>
  </div>
  
  */
  {
    "id": "mNcGXa6L",
    "block": "[[[10,0],[14,0,\"i18n-header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui menu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item header\"],[12],[1,[30,0,[\"skill\",\"name\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"ui right menu\"],[12],[1,\"\\n\"],[41,[30,0,[\"isMaximized\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"ui icon button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"minimize\"]]],null],[12],[10,\"i\"],[14,0,\"window minimize icon\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[11,\"button\"],[24,0,\"ui icon button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"maximize\"]]],null],[12],[10,\"i\"],[14,0,\"window maximize outline icon\"],[12],[13],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[11,\"button\"],[24,0,\"ui icon button\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"close\"]]],null],[12],[10,\"i\"],[14,0,\"icon window close\"],[12],[13],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"single i18n\"],[12],[1,\"\\n \"],[8,[39,2],null,[[\"@list\",\"@skill\"],[[30,0,[\"challengesByLanguagesAndAlternativesCount\"]],[30,0,[\"skill\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"on\",\"list/i18n\"]]",
    "moduleName": "pixeditor/templates/competence/i18n/single.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});