define("pixeditor/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    {{outlet}}
  
  */
  {
    "id": "EDOyBRO9",
    "block": "[[[46,[28,[37,1],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "pixeditor/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});