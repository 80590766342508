define("pixeditor/components/target-profile/theme-profile", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test-theme-profile class="row theme-profile">
    <div class="three wide column theme-name">
      <p>{{@theme.name}}</p>
    </div>
    <div class="thirteen wide column">
      {{#each this.filteredTubes as |tube|}}
        {{#if @isThematicResultMode}}
          <TargetProfile::TubeProfile class="tube-profile thematicResult"
                                      @tube={{tube}}
                                      @clickAction={{this.clickOnThematicResultTube}}
                                      @selectedSkillLevel={{tube.selectedThematicResultLevel}}
                                      @showTubeDetails={{@showTubeDetails}}/>
        {{else}}
          <TargetProfile::TubeProfile class="tube-profile"
                                      @tube={{tube}}
                                      @clickAction={{this.clickOnTube}}
                                      @selectedSkillLevel={{tube.selectedLevel}}
                                      @showTubeDetails={{@showTubeDetails}}/>
        {{/if}}
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "rjYw3L6R",
    "block": "[[[10,0],[14,\"data-test-theme-profile\",\"\"],[14,0,\"row theme-profile\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"three wide column theme-name\"],[12],[1,\"\\n    \"],[10,2],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"thirteen wide column\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"filteredTubes\"]]],null]],null],null,[[[41,[30,3],[[[1,\"        \"],[8,[39,3],[[24,0,\"tube-profile thematicResult\"]],[[\"@tube\",\"@clickAction\",\"@selectedSkillLevel\",\"@showTubeDetails\"],[[30,2],[30,0,[\"clickOnThematicResultTube\"]],[30,2,[\"selectedThematicResultLevel\"]],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[39,3],[[24,0,\"tube-profile\"]],[[\"@tube\",\"@clickAction\",\"@selectedSkillLevel\",\"@showTubeDetails\"],[[30,2],[30,0,[\"clickOnTube\"]],[30,2,[\"selectedLevel\"]],[30,4]]],null],[1,\"\\n\"]],[]]]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@theme\",\"tube\",\"@isThematicResultMode\",\"@showTubeDetails\"],false,[\"each\",\"-track-array\",\"if\",\"target-profile/tube-profile\"]]",
    "moduleName": "pixeditor/components/target-profile/theme-profile.hbs",
    "isStrictMode": false
  });

  let TargetProfileThemeProfileComponent = (_dec = Ember._action, _dec2 = Ember._action, (_class = class TargetProfileThemeProfileComponent extends _component.default {
    get filteredTubes() {
      const theme = this.args.theme;

      if (this.args.filter) {
        return theme.productionTubes.filter(tube => tube.selectedLevel);
      }

      return theme.productionTubes;
    }

    clickOnTube(tube) {
      if (this.args.showTubeDetails) {
        this.args.displayTube(tube);
      } else if (tube.selectedLevel) {
        this.args.clearTube(tube);
      } else {
        this.args.setTubeLevel(tube);
      }
    }

    clickOnThematicResultTube(tube) {
      if (this.args.showTubeDetails) {
        this.args.displayThematicResultTube(tube);
      } else if (tube.selectedThematicResultLevel) {
        tube.selectedThematicResultLevel = false;
      } else {
        tube.selectedThematicResultLevel = tube.selectedLevel;
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "clickOnTube", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clickOnTube"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickOnThematicResultTube", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clickOnThematicResultTube"), _class.prototype)), _class));
  _exports.default = TargetProfileThemeProfileComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TargetProfileThemeProfileComponent);
});