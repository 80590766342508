define("pixeditor/services/paginated-query", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PaginatedQueryService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, (_class = class PaginatedQueryService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "offset", _descriptor2, this);
    }

    query(model, parameters) {
      const store = this.store;

      function queryPage(model, parameters, current) {
        if (!current) {
          current = Ember.A();
        }

        return store.query(model, parameters).then(result => {
          current.pushObjects(result.toArray());

          if (result.meta && result.meta.offset) {
            parameters.offset = result.meta.offset;
            return queryPage(model, parameters, current);
          } else {
            return current;
          }
        });
      }

      return queryPage(model, parameters);
    }

    queryByStep(model, parameters, currentModel, offset) {
      const store = this.store;

      if (offset === false) {
        return currentModel;
      }

      if (!currentModel) {
        currentModel = Ember.A();
      }

      if (offset !== undefined) {
        parameters.offset = offset;
      }

      return store.query(model, parameters).then(result => {
        currentModel.pushObjects(result.toArray());

        if (result.meta && result.meta.offset) {
          this.offset = result.meta.offset;
        } else {
          this.offset = false;
        }

        return currentModel;
      });
    }

    getLastOffset() {
      return this.offset;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "offset", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  _exports.default = PaginatedQueryService;
});