define("pixeditor/controllers/competence/prototypes/single/alternatives", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AlternativesController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.controller('competence'), _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class AlternativesController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", ['rightMaximized']);

      _initializerDefineProperty(this, "competence", _descriptor, this);

      _initializerDefineProperty(this, "rightMaximized", _descriptor2, this);

      _initializerDefineProperty(this, "access", _descriptor3, this);

      _initializerDefineProperty(this, "config", _descriptor4, this);

      _initializerDefineProperty(this, "currentData", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "competenceController", _descriptor7, this);
    }

    get challenge() {
      return this.model;
    }

    get mayCreateAlternative() {
      return this.access.mayCreateAlternative();
    }

    get size() {
      if (this.router.currentRouteName == 'competence.prototypes.single.alternatives.index') {
        return 'full';
      } else {
        return 'half';
      }
    }

    maximizeRight(value) {
      if (this.rightMaximized != value) {
        this.rightMaximized = value;
      }
    }

    newAlternative() {
      this.router.transitionTo('competence.prototypes.single.alternatives.new', this.currentData.getCompetence(), this.challenge);
    }

    closeChildComponent() {
      this.maximizeRight(false);
      this.router.transitionTo('competence.prototypes.single.alternatives', this.currentData.getCompetence(), this.challenge);
    }

    copyChallenge(challenge) {
      this.router.transitionTo('competence.prototypes.single.alternatives.new', this.currentData.getCompetence(), this.challenge, {
        queryParams: {
          from: challenge.id
        }
      });
    }

    refresh() {
      this.competenceController.send('refresh');
    }

    selectView() {// does nothing
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "competence", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "rightMaximized", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "access", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentData", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "competenceController", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "newAlternative", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "newAlternative"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeChildComponent", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "closeChildComponent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "copyChallenge", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "copyChallenge"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refresh", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectView", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "selectView"), _class.prototype)), _class));
  _exports.default = AlternativesController;
});