define("pixeditor/services/access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const READ_PIX_ONLY = 0;
  const READ_ONLY = 1;
  const REPLICATOR = 2;
  const EDITOR = 3;
  const ADMIN = 4;
  let AccessService = (_dec = Ember.inject.service, (_class = class AccessService extends Ember.Service {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "config", _descriptor, this);

      _defineProperty(this, "readOnly", READ_ONLY);
    }

    isReadonly() {
      const level = this.config.accessLevel;
      return level === READ_ONLY;
    }

    mayCreatePrototype() {
      return this.isEditor();
    }

    mayCreateTube() {
      return this.isEditor();
    }

    mayCreateTheme() {
      return this.isEditor();
    }

    mayEditSkills() {
      return this.isEditor();
    }

    mayEditSkill(skill) {
      return this.mayEditSkills() && skill.isLive;
    }

    mayMoveTube(tube) {
      const level = this.config.accessLevel;

      if (tube.hasProductionSkills) {
        return level === ADMIN;
      } else {
        return level >= EDITOR;
      }
    }

    mayDuplicateSkill(skill) {
      if (!skill.isLive) {
        return false;
      }

      const level = this.config.accessLevel;

      if (skill.productionPrototype) {
        return level === ADMIN;
      } else {
        return level >= EDITOR;
      }
    }

    mayArchiveSkill(skill) {
      if (!skill.isLive) {
        return false;
      }

      const level = this.config.accessLevel;

      if (skill.productionPrototype) {
        return level === ADMIN;
      } else {
        return level >= EDITOR;
      }
    }

    mayObsoleteSkill(skill) {
      if (skill.isObsolete) {
        return false;
      }

      const level = this.config.accessLevel;

      if (skill.productionPrototype) {
        return level === ADMIN;
      } else {
        return level >= EDITOR;
      }
    }

    mayCreateAlternative() {
      return this.isReplicator();
    }

    mayEdit(challenge) {
      const level = this.config.accessLevel;
      const production = challenge.isValidated;
      const obsolete = challenge.isObsolete;
      const prototype = challenge.isPrototype;

      if (obsolete) {
        return false;
      }

      return level >= EDITOR || !production && !prototype && level === REPLICATOR;
    }

    mayDuplicate(challenge) {
      const level = this.config.accessLevel;
      const prototype = challenge.isPrototype;
      return level >= EDITOR || !prototype && level === REPLICATOR;
    }

    mayAccessLog(challenge) {
      const level = this.config.accessLevel;
      const prototype = challenge.isPrototype;
      return level >= EDITOR || !prototype && level === REPLICATOR;
    }

    mayAccessAirtable() {
      return this.isAdmin();
    }

    mayValidate(challenge) {
      return this.isAdmin() && challenge.isDraft && !challenge.isWorkbench;
    }

    mayArchive(challenge) {
      if (!challenge.isLive) {
        return false;
      }

      const level = this.config.accessLevel;

      if (challenge.isValidated) {
        return level === ADMIN;
      } else {
        if (challenge.isPrototype) {
          return level >= EDITOR;
        } else {
          return level >= REPLICATOR;
        }
      }
    }

    mayObsolete(challenge) {
      if (challenge.isObsolete) {
        return false;
      }

      const level = this.config.accessLevel;

      if (challenge.isValidated) {
        return level === ADMIN;
      } else {
        if (challenge.isPrototype) {
          return level >= EDITOR;
        } else {
          return level >= REPLICATOR;
        }
      }
    }

    mayMove(challenge) {
      return this.isAdmin() && challenge.isPrototype && challenge.isDraft;
    }

    isReadOnly() {
      const level = this.config.accessLevel;
      return level >= READ_ONLY;
    }

    isReplicator() {
      const level = this.config.accessLevel;
      return level >= REPLICATOR;
    }

    isEditor() {
      const level = this.config.accessLevel;
      return level >= EDITOR;
    }

    isAdmin() {
      const level = this.config.accessLevel;
      return level === ADMIN;
    }

    getLevel(accessString) {
      switch (accessString) {
        case 'readpixonly':
          return READ_PIX_ONLY;

        case 'readonly':
          return READ_ONLY;

        case 'replicator':
          return REPLICATOR;

        case 'editor':
          return EDITOR;

        case 'admin':
          return ADMIN;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "config", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AccessService;
});