define("pixeditor/stories/pix-icon-button.stories", ["exports", "@storybook/addon-actions"], function (_exports, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withBackground = _exports.small = _exports.argTypes = _exports.Default = void 0;

  const Template = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixIconButton
              @ariaLabel={{ariaLabel}}
              @icon={{icon}}
              @iconPrefix={{iconPrefix}}
              @triggerAction={{triggerAction}}
              @withBackground={{withBackground}}
              @size={{size}}
              />
          
      */
      {
        "id": "0+E3nAKM",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@ariaLabel\",\"@icon\",\"@iconPrefix\",\"@triggerAction\",\"@withBackground\",\"@size\"],[[36,1],[36,2],[36,3],[36,4],[36,5],[36,6]]],null],[1,\"\\n    \"]],[],false,[\"pix-icon-button\",\"ariaLabel\",\"icon\",\"iconPrefix\",\"triggerAction\",\"withBackground\",\"size\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  const Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    ariaLabel: 'Action du bouton',
    icon: 'times',
    triggerAction: () => {
      return new Promise().resolves();
    }
  };
  const small = Template.bind({});
  _exports.small = small;
  small.args = { ...Default.args,
    size: 'small'
  };
  const withBackground = Template.bind({});
  _exports.withBackground = withBackground;
  withBackground.args = { ...Default.args,
    withBackground: true
  };
  const argTypes = {
    ariaLabel: {
      name: 'ariaLabel',
      description: "l'action du bouton, pour l'accessibilité",
      type: {
        name: 'string',
        required: true
      },
      table: {
        defaultValue: {
          summary: 'times'
        }
      }
    },
    icon: {
      name: 'icon',
      description: 'Icône font-awesome',
      type: {
        name: 'string',
        required: true
      },
      table: {
        defaultValue: {
          summary: 'times'
        }
      }
    },
    iconPrefix: {
      name: 'iconPrefix',
      description: "Prefix de l'icône font-awesome",
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['far', 'fas']
      }
    },
    triggerAction: {
      name: 'triggerAction',
      description: 'Fonction à appeler au clic du bouton',
      type: {
        required: true
      },
      defaultValue: (0, _addonActions.action)('triggerAction')
    },
    withBackground: {
      name: 'withBackground',
      description: 'Affichage du fond grisé',
      type: {
        name: 'boolean',
        required: false
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    },
    size: {
      name: 'size',
      description: 'Size: `small`, `big`',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['big', 'small']
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    color: {
      name: 'color',
      description: ' ⚠️ **Propriété dépréciée** ⚠️ Color: `light-grey`, `dark-grey`',
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select',
        options: ['light-grey', 'dark-grey']
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'light-grey'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});