define("pixeditor/modifiers/scroll-top", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((element, _ref) => {
    let [isEdition] = _ref;

    if (isEdition) {
      element.scrollTop = 0;
    }
  });

  _exports.default = _default;
});