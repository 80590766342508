define("pixeditor/components/form/theme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <form action="" class="ui form">
    <Field::Input data-test-theme-name-field @value={{@theme.name}} @edition={{@edition}} @label="Nom fr-fr"/>
    <Field::Input data-test-theme-name-en-us-field @value={{@theme.nameEnUs}} @edition={{@edition}} @label="Nom en-us"/>
  </form>
  */
  {
    "id": "DnUPlu1f",
    "block": "[[[10,\"form\"],[14,\"action\",\"\"],[14,0,\"ui form\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-theme-name-field\",\"\"]],[[\"@value\",\"@edition\",\"@label\"],[[30,1,[\"name\"]],[30,2],\"Nom fr-fr\"]],null],[1,\"\\n  \"],[8,[39,0],[[24,\"data-test-theme-name-en-us-field\",\"\"]],[[\"@value\",\"@edition\",\"@label\"],[[30,1,[\"nameEnUs\"]],[30,2],\"Nom en-us\"]],null],[1,\"\\n\"],[13]],[\"@theme\",\"@edition\"],false,[\"field/input\"]]",
    "moduleName": "pixeditor/components/form/theme.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});