define("pixeditor/components/sidebar/export", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p {{on "click" this.shareAreas}}><i class="share square icon"></i> Exporter les sujets</p>
  
  */
  {
    "id": "0m5ifcUh",
    "block": "[[[11,2],[4,[38,0],[\"click\",[30,0,[\"shareAreas\"]]],null],[12],[10,\"i\"],[14,0,\"share square icon\"],[12],[13],[1,\" Exporter les sujets\"],[13],[1,\"\\n\"]],[],false,[\"on\"]]",
    "moduleName": "pixeditor/components/sidebar/export.hbs",
    "isStrictMode": false
  });

  let SidebarExportComponent = (_dec = Ember.inject.service('file-saver'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class SidebarExportComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fileSaver", _descriptor, this);

      _initializerDefineProperty(this, "notify", _descriptor2, this);

      _initializerDefineProperty(this, "loader", _descriptor3, this);
    }

    async shareAreas() {
      this.loader.start('Récupération des sujets');

      try {
        const areas = this.args.areas;
        await this._loadContent(areas);

        const csvContent = this._buildCSVContent(areas);

        const fileName = `Export_Sujets_${new Date().toLocaleString('fr-FR')}.csv`;
        this.fileSaver.saveAs(csvContent, fileName);
        this.notify.message('Sujets exportés');
      } catch (e) {
        console.error(e);
        this.notify.error('Erreur lors de l\'exportation des sujets');
      } finally {
        this.loader.stop();
      }
    }

    async _loadContent(areas) {
      const getCompetences = areas.map(area => area.competences);
      const areaCompetences = await Promise.all(getCompetences);
      const getThemes = areaCompetences.map(competences => competences.map(competence => competence.rawThemes)).flat();
      const competenceThemes = await Promise.all(getThemes);
      const getTubes = competenceThemes.map(themes => themes.map(theme => theme.rawTubes)).flat();
      const competenceTubes = await Promise.all(getTubes);
      const getSkills = competenceTubes.map(tubes => tubes.map(tube => tube.rawSkills)).flat();
      const tubeSkills = await Promise.all(getSkills);
      const getChallenges = tubeSkills.map(skills => skills.map(skill => skill.challenges)).flat();
      await Promise.all(getChallenges);
    }

    _buildCSVContent(areas) {
      return areas.reduce((content, area) => {
        return area.sortedCompetences.reduce((content, competence) => {
          return competence.sortedThemes.reduce((content, theme) => {
            return theme.productionTubes.reduce((content, tube) => {
              let fields = [area.name, competence.name, theme.name, tube.name, tube.title, tube.description, tube.practicalTitleFr, tube.practicalDescriptionFr, tube.productionSkills.reduce((table, skill) => {
                table[skill.level - 1] = skill.name;
                return table;
              }, ['░', '░', '░', '░', '░', '░', '░', '░']).join(',')];
              fields = fields.map(field => this._formatCSVString(field));
              return content + '\n' + fields.join(',');
            }, content);
          }, content);
        }, content);
      }, '"Domaine","Compétence","Thématique","Tube","Titre","Description","Titre pratique","Description pratique","Liste des acquis"');
    }

    _formatCSVString(str) {
      if (str) {
        return `"${str.replace(/"/g, '""')}"`;
      }

      return ' ';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileSaver", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loader", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "shareAreas", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "shareAreas"), _class.prototype)), _class));
  _exports.default = SidebarExportComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SidebarExportComponent);
});