define("pixeditor/services/ajax-queue", ["exports", "p-queue", "pixeditor/config/environment"], function (_exports, _pQueue, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AjaxQueueService extends Ember.Service {
    constructor() {
      super(...arguments);
      this._queue = new _pQueue.default({
        concurrency: _environment.default.APP.MAX_CONCURRENT_AJAX_CALLS
      });
    }

    async add(job) {
      return this._queue.add(job);
    }

  }

  _exports.default = AjaxQueueService;
});