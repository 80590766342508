define("pixeditor/mirage/factories/skill", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'name',
    competence: 'competence',
    clue: 'clue',
    clueEn: 'clueEn',
    clueStatus: 'clueStatus',
    description: 'description',
    descriptionStatus: 'descriptionStatus',
    level: 2,
    status: 'actif',
    pixId: 'pixId',
    i18n: 'Monde',
    createdAt: '2018-12-11T13:38:35.000Z',
    version: 1
  });

  _exports.default = _default;
});