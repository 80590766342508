define("pixeditor/components/competence/grid/cell-skill", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="skill-cell skill-mode {{this.alertCSS}}">
    <LinkTo @route="competence.skills.single" @model={{@skill}} class="skill-cell__link">
      <div class="skill-name">
        {{@skill.name}}
      </div>
      <div class="help">
        {{#if (and @languageFilter this.hasNoClueByLanguage)}}
          <i class="idea icon empty" title="Indice manquant"></i>
        {{else if @languageFilter}}
          <i class="idea icon validated" title="Indice présent"></i>
        {{else if (or @skill.clue @skill.clueNA)}}
          <i class={{concat "idea icon " @skill.clueCSS}} title="Indice {{@skill.clueStatus}}"></i>
        {{/if}}
        <span class="tuto-count">
        <span title="Nombre de tutoriels pour réussir la prochaine fois">{{this.tutoSolutionCountByLanguage}}</span>
        -
        <span title="Nombre de tutoriels pour en savoir plus">{{this.tutoMoreCountByLanguage}}</span>
      </span>
      </div>
    </LinkTo>
  </td>
  
  */
  {
    "id": "2QVjSBRa",
    "block": "[[[10,\"td\"],[15,0,[29,[\"skill-cell skill-mode \",[30,0,[\"alertCSS\"]]]]],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"skill-cell__link\"]],[[\"@route\",\"@model\"],[\"competence.skills.single\",[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"skill-name\"],[12],[1,\"\\n      \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"help\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,2],[30,0,[\"hasNoClueByLanguage\"]]],null],[[[1,\"        \"],[10,\"i\"],[14,0,\"idea icon empty\"],[14,\"title\",\"Indice manquant\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[30,2],[[[1,\"        \"],[10,\"i\"],[14,0,\"idea icon validated\"],[14,\"title\",\"Indice présent\"],[12],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,1,[\"clue\"]],[30,1,[\"clueNA\"]]],null],[[[1,\"        \"],[10,\"i\"],[15,0,[28,[37,4],[\"idea icon \",[30,1,[\"clueCSS\"]]],null]],[15,\"title\",[29,[\"Indice \",[30,1,[\"clueStatus\"]]]]],[12],[13],[1,\"\\n      \"]],[]],null]],[]]]],[]]],[1,\"      \"],[10,1],[14,0,\"tuto-count\"],[12],[1,\"\\n      \"],[10,1],[14,\"title\",\"Nombre de tutoriels pour réussir la prochaine fois\"],[12],[1,[30,0,[\"tutoSolutionCountByLanguage\"]]],[13],[1,\"\\n      -\\n      \"],[10,1],[14,\"title\",\"Nombre de tutoriels pour en savoir plus\"],[12],[1,[30,0,[\"tutoMoreCountByLanguage\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@skill\",\"@languageFilter\"],false,[\"link-to\",\"if\",\"and\",\"or\",\"concat\"]]",
    "moduleName": "pixeditor/components/competence/grid/cell-skill.hbs",
    "isStrictMode": false
  });

  class CompetenceGridCellSkillComponent extends _component.default {
    get hasNoClueByLanguage() {
      switch (this.args.languageFilter) {
        case 'fr':
        case 'fr-fr':
          return !this.args.skill.clue;

        case 'en':
          return !this.args.skill.clueEn;

        default:
          return true;
      }
    }

    get alertCSS() {
      if (!this.args.languageFilter) {
        return '';
      }

      if (this.tutoMoreCountByLanguage + this.tutoSolutionCountByLanguage === 0) {
        return 'danger';
      }

      if (this.tutoMoreCountByLanguage === 0 || this.tutoSolutionCountByLanguage === 0) {
        return 'warning';
      }

      return '';
    }

    get tutoMoreCountByLanguage() {
      return this._getTutorialsCountByLanguage(this.args.skill.tutoMore);
    }

    get tutoSolutionCountByLanguage() {
      return this._getTutorialsCountByLanguage(this.args.skill.tutoSolution);
    }

    _getTutorialsCountByLanguage(tutorials) {
      const languageFilter = this.args.languageFilter;

      if (languageFilter) {
        const filteredTutorials = tutorials.filter(tutorial => {
          const language = this._convertLanguageFilterToLanguageTutorial(languageFilter);

          return tutorial.language === language;
        });
        return filteredTutorials.length;
      }

      return tutorials.length;
    }

    _convertLanguageFilterToLanguageTutorial(language) {
      switch (language) {
        case 'fr':
          return 'fr-fr';

        case 'en':
          return 'en-us';

        default:
          return language;
      }
    }

  }

  _exports.default = CompetenceGridCellSkillComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CompetenceGridCellSkillComponent);
});