define("pixeditor/components/competence/grid/cell-workbench", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="skill-cell workbench">
    <LinkTo @route="competence.prototypes.list" @models={{array @tube.id @skill.id}} class="skill-cell__link">
      {{@skill.name}}
      <div class="workbench__status">
        {{#if this.hasDraftPrototypes}}
          <span data-test-draft-prototype-count class="draft-prototype ui tiny label" title="Proposé">{{this.draftPrototypesCount}}</span>
        {{/if}}
        {{#if this.validatedPrototype}}
          <span data-test-validated-prototype-count class="validated-prototype ui tiny label" title="Validé">1</span>
        {{/if}}
        {{#if this.hasArchivedPrototypes}}
          <span data-test-archived-prototype-count class="archived-prototype ui tiny label" title="Archivé">{{this.archivedPrototypesCount}}</span>
        {{/if}}
        {{#if this.hasObsoletePrototypes}}
          <span data-test-obsolete-prototype-count class="obsolete-prototype ui tiny label" title="Périmé"> {{this.obsoletePrototypesCount}}</span>
        {{/if}}
      </div>
    </LinkTo>
  </td>
  
  */
  {
    "id": "vQkzOsOI",
    "block": "[[[10,\"td\"],[14,0,\"skill-cell workbench\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"skill-cell__link\"]],[[\"@route\",\"@models\"],[\"competence.prototypes.list\",[28,[37,1],[[30,1,[\"id\"]],[30,2,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"name\"]]],[1,\"\\n    \"],[10,0],[14,0,\"workbench__status\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasDraftPrototypes\"]],[[[1,\"        \"],[10,1],[14,\"data-test-draft-prototype-count\",\"\"],[14,0,\"draft-prototype ui tiny label\"],[14,\"title\",\"Proposé\"],[12],[1,[30,0,[\"draftPrototypesCount\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"validatedPrototype\"]],[[[1,\"        \"],[10,1],[14,\"data-test-validated-prototype-count\",\"\"],[14,0,\"validated-prototype ui tiny label\"],[14,\"title\",\"Validé\"],[12],[1,\"1\"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasArchivedPrototypes\"]],[[[1,\"        \"],[10,1],[14,\"data-test-archived-prototype-count\",\"\"],[14,0,\"archived-prototype ui tiny label\"],[14,\"title\",\"Archivé\"],[12],[1,[30,0,[\"archivedPrototypesCount\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"hasObsoletePrototypes\"]],[[[1,\"        \"],[10,1],[14,\"data-test-obsolete-prototype-count\",\"\"],[14,0,\"obsolete-prototype ui tiny label\"],[14,\"title\",\"Périmé\"],[12],[1,\" \"],[1,[30,0,[\"obsoletePrototypesCount\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@tube\",\"@skill\"],false,[\"link-to\",\"array\",\"if\"]]",
    "moduleName": "pixeditor/components/competence/grid/cell-workbench.hbs",
    "isStrictMode": false
  });

  class CellWorkbench extends _component.default {
    get prototypes() {
      return this.args.skills.map(skill => skill.prototypes).flat();
    }

    get validatedPrototype() {
      return this.prototypes.find(prototype => prototype.isValidated);
    }

    get draftPrototypesCount() {
      const draftPrototypes = this.prototypes.filter(prototype => prototype.isDraft);
      return draftPrototypes.length;
    }

    get archivedPrototypesCount() {
      const archivedPrototypes = this.prototypes.filter(prototype => prototype.isArchived);
      return archivedPrototypes.length;
    }

    get obsoletePrototypesCount() {
      const obsoletePrototypes = this.prototypes.filter(prototype => prototype.isObsolete);
      return obsoletePrototypes.length;
    }

    get hasDraftPrototypes() {
      return this.draftPrototypesCount > 0;
    }

    get hasArchivedPrototypes() {
      return this.archivedPrototypesCount > 0;
    }

    get hasObsoletePrototypes() {
      return this.obsoletePrototypesCount > 0;
    }

  }

  _exports.default = CellWorkbench;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CellWorkbench);
});