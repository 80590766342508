define("pixeditor/components/competence/competence-grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="competence-grid">
    <table class="ui celled table definition tubes">
      <tbody>
      {{#each @themes as |theme|}}
       <Competence::CompetenceGridTheme @languageFilter={{@languageFilter}}
                                        @section={{@section}}
                                        @view={{@view}}
                                        @theme={{theme}}
                                        @newTube={{@newTube}}
                                        @displaySortTubesPopIn={{@displaySortTubesPopIn}}
                                        @link={{@link}}/>
      {{/each}}
      </tbody>
    </table>
  </div>
  
  */
  {
    "id": "w9pD/K04",
    "block": "[[[10,0],[14,0,\"competence-grid\"],[12],[1,\"\\n  \"],[10,\"table\"],[14,0,\"ui celled table definition tubes\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"     \"],[8,[39,2],null,[[\"@languageFilter\",\"@section\",\"@view\",\"@theme\",\"@newTube\",\"@displaySortTubesPopIn\",\"@link\"],[[30,3],[30,4],[30,5],[30,2],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@themes\",\"theme\",\"@languageFilter\",\"@section\",\"@view\",\"@newTube\",\"@displaySortTubesPopIn\",\"@link\"],false,[\"each\",\"-track-array\",\"competence/competence-grid-theme\"]]",
    "moduleName": "pixeditor/components/competence/competence-grid.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});