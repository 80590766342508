define("pixeditor/mirage/factories/area", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: '',
    code: '',

    competenceIds() {
      return [];
    },

    titleFrFr: '',
    titleEnUs: ''
  });

  _exports.default = _default;
});