define("pixeditor/components/pop-in/tube-level", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal @title={{@tube.name}}
            @onCloseButtonClick={{this.closeModal}}
            class="popin-tube-level"
  >
    <:content>
      <div class="levels">
        {{#each this.skillsAndSelectedStatus as |skillAndSelectedStatus|}}
          {{#if skillAndSelectedStatus}}
            <div class="level {{if skillAndSelectedStatus.isSelected "selected"}}" {{on "click" (fn this.select skillAndSelectedStatus.skill)}} >
              {{skillAndSelectedStatus.skill.level}}
            </div>
          {{else}}
            <div class="level disabled"></div>
          {{/if}}
        {{/each}}
      </div>
    </:content>
    <:footer>
      <PixButton data-test-cancel-button
                 @backgroundColor="transparent-light"
                 @isBorderVisible={{true}}
                 @triggerAction={{this.closeModal}}
      >
        {{t 'common.cancel'}}
      </PixButton>
      {{#if this.mayUnset}}
        <PixButton data-test-erase-button
                   @triggerAction={{this.clear}}
        >
          Effacer
        </PixButton>
      {{/if}}
    </:footer>
  </PixModal>
  */
  {
    "id": "g8C/XNX/",
    "block": "[[[8,[39,0],[[24,0,\"popin-tube-level\"]],[[\"@title\",\"@onCloseButtonClick\"],[[30,1,[\"name\"]],[30,0,[\"closeModal\"]]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"levels\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"skillsAndSelectedStatus\"]]],null]],null],null,[[[41,[30,2],[[[1,\"          \"],[11,0],[16,0,[29,[\"level \",[52,[30,2,[\"isSelected\"]],\"selected\"]]]],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"select\"]],[30,2,[\"skill\"]]],null]],null],[12],[1,\"\\n            \"],[1,[30,2,[\"skill\",\"level\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,0],[14,0,\"level disabled\"],[12],[13],[1,\"\\n\"]],[]]]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],[[24,\"data-test-cancel-button\",\"\"]],[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,0,[\"closeModal\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,7],[\"common.cancel\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"mayUnset\"]],[[[1,\"      \"],[8,[39,6],[[24,\"data-test-erase-button\",\"\"]],[[\"@triggerAction\"],[[30,0,[\"clear\"]]]],[[\"default\"],[[[[1,\"\\n        Effacer\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]]],[\"@tube\",\"skillAndSelectedStatus\"],false,[\"pix-modal\",\"each\",\"-track-array\",\"if\",\"on\",\"fn\",\"pix-button\",\"t\"]]",
    "moduleName": "pixeditor/components/pop-in/tube-level.hbs",
    "isStrictMode": false
  });

  let PopinTubeLevel = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class PopinTubeLevel extends _component.default {
    get skillsAndSelectedStatus() {
      const skills = this.args.skills;
      const selected = this.args.selectedSkills;
      return skills.reduce((orderedSkills, skill) => {
        const level = skill.level;
        const isSelected = selected.includes(skill.pixId);
        orderedSkills[level - 1] = {
          skill,
          isSelected
        };
        return orderedSkills;
      }, [null, null, null, null, null, null, null, null]);
    }

    get mayUnset() {
      const value = this.args.level;
      return value != false;
    }

    select(skill) {
      const level = skill.level;
      const skillIds = this.skillsAndSelectedStatus.reduce((ids, skillAndSelectedStatus) => {
        if (skillAndSelectedStatus && skillAndSelectedStatus.skill.level <= level) {
          ids.push(skillAndSelectedStatus.skill.pixId);
        }

        return ids;
      }, []);
      this.args.setTubeLevel(this.args.tube, level, skillIds);
      this.closeModal();
    }

    clear() {
      this.args.clearTube(this.args.tube);
      this.closeModal();
    }

    closeModal() {
      this.args.close();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "select", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = PopinTubeLevel;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopinTubeLevel);
});