define("pixeditor/models/tube", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TubeModel = (_dec = (0, _model.belongsTo)('competence'), _dec2 = (0, _model.belongsTo)('theme'), _dec3 = (0, _model.hasMany)('skill'), _dec4 = Ember._tracked, _dec5 = Ember._tracked, (_class = class TubeModel extends _model.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "selectedSkills", []);

      _defineProperty(this, "selectedThematicResultSkills", []);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "title", _descriptor2, this);

      _initializerDefineProperty(this, "description", _descriptor3, this);

      _initializerDefineProperty(this, "practicalTitleFr", _descriptor4, this);

      _initializerDefineProperty(this, "practicalTitleEn", _descriptor5, this);

      _initializerDefineProperty(this, "practicalDescriptionFr", _descriptor6, this);

      _initializerDefineProperty(this, "practicalDescriptionEn", _descriptor7, this);

      _initializerDefineProperty(this, "index", _descriptor8, this);

      _initializerDefineProperty(this, "pixId", _descriptor9, this);

      _initializerDefineProperty(this, "competence", _descriptor10, this);

      _initializerDefineProperty(this, "theme", _descriptor11, this);

      _initializerDefineProperty(this, "rawSkills", _descriptor12, this);

      _initializerDefineProperty(this, "selectedLevel", _descriptor13, this);

      _initializerDefineProperty(this, "selectedThematicResultLevel", _descriptor14, this);
    }

    get liveSkills() {
      return this.rawSkills.filter(skill => skill.isLive);
    }

    get draftSkills() {
      return this.rawSkills.filter(skill => skill.isDraft);
    }

    get deadSkills() {
      return this.rawSkills.filter(skill => !skill.isLive);
    }

    get skillCount() {
      return this.liveSkills.length;
    }

    get productionSkills() {
      return this.sortedSkills.filter(skill => skill.status === 'actif');
    }

    get productionSkillCount() {
      return this.productionSkills.length;
    }

    get sortedSkills() {
      return this.liveSkills.sortBy('level');
    }

    get filledSkills() {
      return this._getFilledOrderedVersions(this.rawSkills);
    }

    get filledProductionSkills() {
      return this._getFilledOrderedVersions(this.productionSkills).flat();
    }

    get filledLiveSkills() {
      return this._getFilledOrderedVersions(this.liveSkills);
    }

    get filledDraftSkills() {
      return this._getFilledOrderedVersions(this.draftSkills);
    }

    get filledLastDraftSkills() {
      return this.filledDraftSkills.map(draftSkills => {
        if (draftSkills) {
          return draftSkills[draftSkills.length - 1];
        }

        return draftSkills;
      });
    }

    get filledDeadSkills() {
      return this._getFilledOrderedVersions(this.deadSkills);
    }

    getNextSkillVersion(level) {
      const skills = this.filledSkills[level - 1];
      return skills.length;
    }

    _getFilledOrderedVersions(skills) {
      const filledSkills = this._getFilledSkillGroupByLevel(skills);

      return filledSkills.map(filledSkill => {
        if (filledSkill && filledSkill.length > 1) {
          return filledSkill.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        }

        return filledSkill;
      });
    }

    _getFilledSkillGroupByLevel(skills) {
      return skills.reduce((grid, skill) => {
        if (grid[skill.level - 1]) {
          grid[skill.level - 1].push(skill);
        } else {
          grid[skill.level - 1] = [skill];
        }

        return grid;
      }, [false, false, false, false, false, false, false]);
    }

    get hasProductionSkills() {
      return this.productionSkillCount > 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "title", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "practicalTitleFr", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "practicalTitleEn", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "practicalDescriptionFr", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "practicalDescriptionEn", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "index", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "pixId", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "competence", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "theme", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "rawSkills", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "selectedLevel", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "selectedThematicResultLevel", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = TubeModel;
});