define("pixeditor/services/id-generator", ["exports", "js-crypto-random", "@samwen/base62-util"], function (_exports, _jsCryptoRandom, _base62Util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const RECORD_ID_PREFIX = 'rec';

  class IdGeneratorService extends Ember.Service {
    newId() {
      let prefix = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : RECORD_ID_PREFIX;

      const randomString = _jsCryptoRandom.default.getRandomAsciiString(10);

      const randomBase62 = (0, _base62Util.base62_encode)(randomString);
      return `${prefix}${randomBase62}`;
    }

  }

  _exports.default = IdGeneratorService;
});