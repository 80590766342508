define("pixeditor/templates/competence/prototypes/single/alternatives", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="main-title  {{if this.config.lite "lite" ""}}">
    <h1 class="ui header">
    <div class="ui right floated menu">
      <LinkTo @route="competence.prototypes.single" @model={{this.challenge}} class="ui button icon item"><i class="icon window close"></i></LinkTo>
    </div>
        Déclinaisons de {{this.challenge.skillName}}
  
    </h1>
  </div>
  {{#unless this.rightMaximized}}
    <div class="ui attached segment competence {{this.size}}">
      <List::Alternatives @list={{this.challenge.alternatives}} />
    </div>
    <div class="ui borderless bottom attached labelled icon menu">
      <div class="item competence-info"><div>En production : <span class="production">{{this.challenge.productionAlternatives.length}}</span></div><div>Brouillons : <span class="workbench">{{this.challenge.draftAlternatives.length}}</span></div></div>
      {{#if this.mayCreateAlternative}}
        <button class="ui button right item" {{on "click" this.newAlternative}} type="button">
          <i class="plus square outline icon" data-test-new-alternative-action></i> Nouvelle déclinaison
        </button>
      {{/if}}
    </div>
  {{/unless}}
  {{outlet}}
  
  */
  {
    "id": "xkFZNBsz",
    "block": "[[[10,0],[15,0,[29,[\"main-title  \",[52,[30,0,[\"config\",\"lite\"]],\"lite\",\"\"]]]],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ui right floated menu\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"ui button icon item\"]],[[\"@route\",\"@model\"],[\"competence.prototypes.single\",[30,0,[\"challenge\"]]]],[[\"default\"],[[[[10,\"i\"],[14,0,\"icon window close\"],[12],[13]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n      Déclinaisons de \"],[1,[30,0,[\"challenge\",\"skillName\"]]],[1,\"\\n\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"rightMaximized\"]]],[[[1,\"  \"],[10,0],[15,0,[29,[\"ui attached segment competence \",[30,0,[\"size\"]]]]],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@list\"],[[30,0,[\"challenge\",\"alternatives\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ui borderless bottom attached labelled icon menu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item competence-info\"],[12],[10,0],[12],[1,\"En production : \"],[10,1],[14,0,\"production\"],[12],[1,[30,0,[\"challenge\",\"productionAlternatives\",\"length\"]]],[13],[13],[10,0],[12],[1,\"Brouillons : \"],[10,1],[14,0,\"workbench\"],[12],[1,[30,0,[\"challenge\",\"draftAlternatives\",\"length\"]]],[13],[13],[13],[1,\"\\n\"],[41,[30,0,[\"mayCreateAlternative\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"ui button right item\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"newAlternative\"]]],null],[12],[1,\"\\n        \"],[10,\"i\"],[14,0,\"plus square outline icon\"],[14,\"data-test-new-alternative-action\",\"\"],[12],[13],[1,\" Nouvelle déclinaison\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"if\",\"link-to\",\"unless\",\"list/alternatives\",\"on\",\"component\",\"-outlet\"]]",
    "moduleName": "pixeditor/templates/competence/prototypes/single/alternatives.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});