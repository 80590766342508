define("pixeditor/adapters/framework", ["exports", "pixeditor/adapters/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FrameworkAdapter extends _airtable.default {
    findAll(store, type, sinceToken) {
      return this.query(store, type, {
        since: sinceToken,
        sort: [{
          field: 'Date',
          direction: 'asc'
        }]
      });
    }

    pathForType() {
      return 'Referentiel';
    }

  }

  _exports.default = FrameworkAdapter;
});