define("pixeditor/models/competence", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CompetenceModel = (_dec = (0, _model.belongsTo)('area'), _dec2 = (0, _model.hasMany)('tube'), _dec3 = (0, _model.hasMany)('theme'), (_class = class CompetenceModel extends _model.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "needsRefresh", false);

      _initializerDefineProperty(this, "title", _descriptor, this);

      _initializerDefineProperty(this, "titleEn", _descriptor2, this);

      _initializerDefineProperty(this, "description", _descriptor3, this);

      _initializerDefineProperty(this, "descriptionEn", _descriptor4, this);

      _initializerDefineProperty(this, "code", _descriptor5, this);

      _initializerDefineProperty(this, "pixId", _descriptor6, this);

      _initializerDefineProperty(this, "source", _descriptor7, this);

      _initializerDefineProperty(this, "area", _descriptor8, this);

      _initializerDefineProperty(this, "rawTubes", _descriptor9, this);

      _initializerDefineProperty(this, "rawThemes", _descriptor10, this);
    }

    get name() {
      return `${this.code} ${this.title}`;
    }

    get tubes() {
      return this.rawTubes.filter(tube => tube.name !== '@workbench');
    }

    get themes() {
      return this.rawThemes.filter(theme => theme.name.indexOf('workbench') === -1);
    }

    get sortedThemes() {
      return this.themes.sortBy('index');
    }

    get productionTubes() {
      const allTubes = this.rawTubes.filter(tube => tube.hasProductionSkills);
      return allTubes.sortBy('index');
    }

    get sortedTubes() {
      return this.tubes.sortBy('index');
    }

    get tubeCount() {
      return this.tubes.length;
    }

    get productionTubeCount() {
      return this.productionTubes.length;
    }

    get selectedProductionTubeCount() {
      return this.productionTubes.filter(tube => tube.selectedLevel).length;
    }

    get selectedThematicResultTubeCount() {
      return this.productionTubes.filter(tube => tube.selectedThematicResultLevel).length;
    }

    get skillCount() {
      return this.tubes.map(tube => tube.skillCount).reduce((count, value) => {
        return count + value;
      }, 0);
    }

    get productionSkillCount() {
      return this.tubes.map(tube => tube.productionSkillCount).reduce((count, value) => {
        return count + value;
      }, 0);
    }

    get workbenchSkill() {
      const workbenchTube = this.rawTubes.find(tube => tube.name === '@workbench');

      if (workbenchTube) {
        return workbenchTube.rawSkills.firstObject;
      }

      return null;
    }

    get workbenchPrototypes() {
      const workbenchSkill = this.workbenchSkill;

      if (workbenchSkill) {
        return workbenchSkill.prototypes.filter(prototype => !prototype.get('isArchived'));
      }

      return [];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "titleEn", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "description", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "descriptionEn", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "code", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pixId", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "source", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "area", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "rawTubes", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "rawThemes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CompetenceModel;
});