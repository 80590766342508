define("pixeditor/services/changelog-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ChangelogEntryService extends Ember.Service {
    constructor() {
      super(...arguments);

      _defineProperty(this, "skill", 'acquis');

      _defineProperty(this, "challenge", 'épreuve');

      _defineProperty(this, "deleteAction", 'suppression');

      _defineProperty(this, "createAction", 'ajout');

      _defineProperty(this, "archiveAction", 'archivage');

      _defineProperty(this, "modifyAction", 'modification');

      _defineProperty(this, "moveAction", 'déplacement');
    }

  }

  _exports.default = ChangelogEntryService;
});