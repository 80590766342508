define("pixeditor/components/field/quality", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{concat "field" (if @edition "" " disabled")}} ...attributes>
      <label>{{@title}}</label>
      <div class="four fields">
          <div class="field">
            <Field::Select @title="Non voyant" @value={{@challenge.accessibility1}} @edition={{@edition}} @multiple={{false}} @options={{this.options.accessibility1}} @setValue={{fn (mut @challenge.accessibility1)}} />
          </div>
          <div class="field">
            <Field::Select @title="Daltonien" @value={{@challenge.accessibility2}} @edition={{@edition}} @multiple={{false}} @options={{this.options.accessibility2}} @setValue={{fn (mut @challenge.accessibility2)}}/>
          </div>
          <div class="field">
            <Field::Select @title="Spoil" @value={{@challenge.spoil}} @edition={{@edition}} @multiple={{false}} @options={{this.options.spoil}} @setValue={{fn (mut @challenge.spoil)}}/>
          </div>
          <div class="field">
            <Field::Select @title="Responsive" @value={{@challenge.responsive}} @edition={{@edition}} @multiple={{false}} @options={{this.options.responsive}} @setValue={{fn (mut @challenge.responsive)}}/>
          </div>
      </div>
  </div>
  
  */
  {
    "id": "JESI0Z/+",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"field\",[52,[30,1],\"\",\" disabled\"]],null]],[17,2],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[30,3]],[13],[1,\"\\n    \"],[10,0],[14,0,\"four fields\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@title\",\"@value\",\"@edition\",\"@multiple\",\"@options\",\"@setValue\"],[\"Non voyant\",[30,4,[\"accessibility1\"]],[30,1],false,[30,0,[\"options\",\"accessibility1\"]],[28,[37,3],[[28,[37,4],[[30,4,[\"accessibility1\"]]],null]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@title\",\"@value\",\"@edition\",\"@multiple\",\"@options\",\"@setValue\"],[\"Daltonien\",[30,4,[\"accessibility2\"]],[30,1],false,[30,0,[\"options\",\"accessibility2\"]],[28,[37,3],[[28,[37,4],[[30,4,[\"accessibility2\"]]],null]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@title\",\"@value\",\"@edition\",\"@multiple\",\"@options\",\"@setValue\"],[\"Spoil\",[30,4,[\"spoil\"]],[30,1],false,[30,0,[\"options\",\"spoil\"]],[28,[37,3],[[28,[37,4],[[30,4,[\"spoil\"]]],null]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n          \"],[8,[39,2],null,[[\"@title\",\"@value\",\"@edition\",\"@multiple\",\"@options\",\"@setValue\"],[\"Responsive\",[30,4,[\"responsive\"]],[30,1],false,[30,0,[\"options\",\"responsive\"]],[28,[37,3],[[28,[37,4],[[30,4,[\"responsive\"]]],null]],null]]],null],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@edition\",\"&attrs\",\"@title\",\"@challenge\"],false,[\"concat\",\"if\",\"field/select\",\"fn\",\"mut\"]]",
    "moduleName": "pixeditor/components/field/quality.hbs",
    "isStrictMode": false
  });

  class Quality extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "options", {
        'accessibility1': ['RAS', 'OK', 'Acquis Non Pertinent', 'KO', 'A tester'],
        'accessibility2': ['RAS', 'OK', 'KO'],
        'responsive': ['Tablette', 'Smartphone', 'Tablette/Smartphone', 'Non'],
        'spoil': ['Non Sp', 'Difficilement Sp', 'Facilement Sp']
      });
    }

  }

  _exports.default = Quality;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Quality);
});