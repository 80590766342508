define("pixeditor/adapters/area", ["exports", "pixeditor/adapters/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AreaAdapter extends _airtable.default {
    findAll(store, type, sinceToken) {
      return this.query(store, type, {
        since: sinceToken,
        sort: [{
          field: 'Code',
          direction: 'asc'
        }]
      });
    }

    pathForType() {
      return 'Domaines';
    }

  }

  _exports.default = AreaAdapter;
});