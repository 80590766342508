define("pixeditor/adapters/user", ["exports", "pixeditor/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class UserAdapter extends _application.default {
    urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return `${super.urlForQueryRecord(...arguments)}/me`;
      }

      return super.urlForQueryRecord(...arguments);
    }

  }

  _exports.default = UserAdapter;
});