define("pixeditor/components/pop-in/confirm-log", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
    @title={{@title}}
    @onCloseButtonClick={{@onDeny}}
  >
    <:content>
      <p>
        {{@content}}
      </p>
      <form class="ui form">
        <Field::Checkbox
          class="checkbox-layout"
          @label="Je veux ajouter une note de changelog"
          @checked={{this.displayTextarea}}
          data-test-confirm-log-check
        />
        {{#if this.displayTextarea}}
          <div class="changelog-layout">
            <label for={{this.inputId}} >
              {{@label}}
            </label>
            <Textarea id={{this.inputId}} @value={{this.defaultValue}} rows="4" class="changelog-textarea" />
          </div>
        {{/if}}
      </form>
    </:content>
    <:footer>
      <PixButton data-test-confirm-log-cancel
        @backgroundColor="transparent-light"
        @isBorderVisible={{true}}
        @triggerAction={{@onDeny}}
      >
        {{t 'common.cancel'}}
      </PixButton>
      <PixButton data-test-confirm-log-approve @triggerAction={{fn @onApprove this.changeLogValue}}>{{t 'common.validate'}}</PixButton>
    </:footer>
  </PixModal>
  */
  {
    "id": "F9pJ5iVK",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[[30,1],[30,2]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"form\"],[14,0,\"ui form\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"checkbox-layout\"],[24,\"data-test-confirm-log-check\",\"\"]],[[\"@label\",\"@checked\"],[\"Je veux ajouter une note de changelog\",[30,0,[\"displayTextarea\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"displayTextarea\"]],[[[1,\"        \"],[10,0],[14,0,\"changelog-layout\"],[12],[1,\"\\n          \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,\"\\n            \"],[1,[30,4]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[8,[39,3],[[16,1,[30,0,[\"inputId\"]]],[24,\"rows\",\"4\"],[24,0,\"changelog-textarea\"]],[[\"@value\"],[[30,0,[\"defaultValue\"]]]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,\"data-test-confirm-log-cancel\",\"\"]],[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,5],[\"common.cancel\"],null]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,4],[[24,\"data-test-confirm-log-approve\",\"\"]],[[\"@triggerAction\"],[[28,[37,6],[[30,5],[30,0,[\"changeLogValue\"]]],null]]],[[\"default\"],[[[[1,[28,[35,5],[\"common.validate\"],null]]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@title\",\"@onDeny\",\"@content\",\"@label\",\"@onApprove\"],false,[\"pix-modal\",\"field/checkbox\",\"if\",\"textarea\",\"pix-button\",\"t\",\"fn\"]]",
    "moduleName": "pixeditor/components/pop-in/confirm-log.hbs",
    "isStrictMode": false
  });

  let PopInConfirmLog = (_dec = Ember._tracked, _dec2 = Ember._tracked, (_class = class PopInConfirmLog extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "displayTextarea", _descriptor, this);

      _initializerDefineProperty(this, "defaultValue", _descriptor2, this);

      _defineProperty(this, "inputId", this.args.inputId);
    }

    get changeLogValue() {
      if (this.displayTextarea) {
        return this.defaultValue;
      }

      return null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "displayTextarea", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "defaultValue", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.defaultValue;
    }
  })), _class));
  _exports.default = PopInConfirmLog;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopInConfirmLog);
});