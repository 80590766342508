define("pixeditor/components/list/i18n", ["exports", "pixeditor/components/list/sorted"], function (_exports, _sorted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable as |t|>
    <t.head
            @columns={{this.headers}}
            @widthConstraint={{'eq-container'}}
            @resizeMode={{'fluid'}}
            @onUpdateSorts={{this.sortBy}}
            @sortFunction={{this.sort}}
            @sorts={{this.sorts}}/>
    <t.body
            @renderAll={{this.renderAll}}
            @rows={{@list}}
            @rowSelectionMode={{'single'}}
            @checkboxSelectionMode={{'none'}}
            @onSelect={{this.selectRow}} as |b|>
      <b.row class="challenge-prototype" as |r|>
        <r.cell class="{{if r.columnValue.alternatives "alternatives-count"}}" as |value|>
          {{#if r.columnValue.language}}
            <span class="language"><i class="{{convert-language-as-flag value}} flag"></i>{{value}}</span>
          {{else}}
            {{value}}
          {{/if}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  
  */
  {
    "id": "ACjg2sp0",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,[[\"@columns\",\"@widthConstraint\",\"@resizeMode\",\"@onUpdateSorts\",\"@sortFunction\",\"@sorts\"],[[30,0,[\"headers\"]],\"eq-container\",\"fluid\",[30,0,[\"sortBy\"]],[30,0,[\"sort\"]],[30,0,[\"sorts\"]]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,[[\"@renderAll\",\"@rows\",\"@rowSelectionMode\",\"@checkboxSelectionMode\",\"@onSelect\"],[[30,0,[\"renderAll\"]],[30,2],\"single\",\"none\",[30,0,[\"selectRow\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"row\"]],[[24,0,\"challenge-prototype\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,4,[\"cell\"]],[[16,0,[29,[[52,[30,4,[\"columnValue\",\"alternatives\"]],\"alternatives-count\"]]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,4,[\"columnValue\",\"language\"]],[[[1,\"          \"],[10,1],[14,0,\"language\"],[12],[10,\"i\"],[15,0,[29,[[28,[37,2],[[30,5]],null],\" flag\"]]],[12],[13],[1,[30,5]],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"      \"]],[5]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"t\",\"@list\",\"b\",\"r\",\"value\"],false,[\"ember-table\",\"if\",\"convert-language-as-flag\"]]",
    "moduleName": "pixeditor/components/list/i18n.hbs",
    "isStrictMode": false
  });

  let I18n = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class I18n extends _sorted.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "headers", [{
        name: 'Consigne',
        valuePath: 'challenge.instruction'
      }, {
        name: 'Langue',
        valuePath: 'language',
        maxWidth: 200,
        language: true
      }, {
        name: 'Déclinaisons',
        valuePath: 'alternativesCount',
        maxWidth: 150,
        alternatives: true
      }]);
    }

    selectRow() {
      const productionPrototype = this.args.skill.productionPrototype;
      this.router.transitionTo('competence.prototypes.single.alternatives', productionPrototype);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectRow", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectRow"), _class.prototype)), _class));
  _exports.default = I18n;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, I18n);
});