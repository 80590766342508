define("pixeditor/models/skill", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SkillModel = (_dec = (0, _model.attr)('string', {
    readOnly: true
  }), _dec2 = (0, _model.attr)({
    readOnly: true
  }), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.belongsTo)('tube'), _dec5 = (0, _model.hasMany)('challenge', {
    readOnly: true
  }), _dec6 = (0, _model.hasMany)('tutorial'), _dec7 = (0, _model.hasMany)('tutorial'), _dec8 = Ember.inject.service('store'), _dec9 = Ember.inject.service, _dec10 = Ember._tracked, (_class = class SkillModel extends _model.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_pinnedRelationships", {});

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "clue", _descriptor2, this);

      _initializerDefineProperty(this, "clueEn", _descriptor3, this);

      _initializerDefineProperty(this, "clueStatus", _descriptor4, this);

      _initializerDefineProperty(this, "createdAt", _descriptor5, this);

      _initializerDefineProperty(this, "description", _descriptor6, this);

      _initializerDefineProperty(this, "descriptionStatus", _descriptor7, this);

      _initializerDefineProperty(this, "level", _descriptor8, this);

      _initializerDefineProperty(this, "status", _descriptor9, this);

      _initializerDefineProperty(this, "pixId", _descriptor10, this);

      _initializerDefineProperty(this, "i18n", _descriptor11, this);

      _initializerDefineProperty(this, "version", _descriptor12, this);

      _initializerDefineProperty(this, "tube", _descriptor13, this);

      _initializerDefineProperty(this, "challenges", _descriptor14, this);

      _initializerDefineProperty(this, "tutoSolution", _descriptor15, this);

      _initializerDefineProperty(this, "tutoMore", _descriptor16, this);

      _initializerDefineProperty(this, "myStore", _descriptor17, this);

      _initializerDefineProperty(this, "idGenerator", _descriptor18, this);

      _initializerDefineProperty(this, "_selected", _descriptor19, this);
    }

    get date() {
      const createdDate = this.createdAt;
      return new Date(createdDate).toLocaleDateString('fr', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    }

    get clueCSS() {
      const status = this.clueStatus;

      if (!status) {
        return 'suggested';
      } else {
        return this._getCSSFromClueStatus(status);
      }
    }

    get statusCSS() {
      const status = this.status;

      switch (status) {
        case 'en construction':
          return 'suggested';

        case 'actif':
          return 'validated';

        case 'archivé':
          return 'archived';

        case 'périmé':
          return 'deleted';

        default:
          return '';
      }
    }

    get clueNA() {
      return this.clueStatus === 'inapplicable';
    }

    get prototypes() {
      return this.challenges.filter(challenge => challenge.isPrototype);
    }

    get sortedPrototypes() {
      return this.prototypes.sort((a, b) => a.version < b.version);
    }

    get productionPrototype() {
      return this.prototypes.find(prototype => prototype.isValidated);
    }

    get productionPrototypes() {
      return this.prototypes.filter(prototype => prototype.isValidated);
    }

    get alternatives() {
      return this.challenges.filter(challenge => !challenge.isPrototype);
    }

    get validatedChallenges() {
      return this.challenges.filter(challenge => challenge.isValidated);
    }

    get liveChallenges() {
      return this.challenges.filter(challenge => challenge.isLive);
    }

    get isActive() {
      return this.status === 'actif';
    }

    get isArchived() {
      return this.status === 'archivé';
    }

    get isDraft() {
      return this.status === 'en construction';
    }

    get isLive() {
      return this.status === 'actif' || this.status === 'en construction';
    }

    get isObsolete() {
      return this.status === 'périmé';
    }

    get languagesAndAlternativesCount() {
      const languagesAndAlternativesCount = this.validatedChallenges.reduce((acc, challenge) => {
        return this._extractLanguagesAndAlternativesCountFromChallenges(acc, challenge.locales);
      }, new Map());
      return new Map([...languagesAndAlternativesCount.entries()].sort());
    }

    get languages() {
      const skillLanguagesMap = this.languagesAndAlternativesCount;

      if (skillLanguagesMap) {
        return [...skillLanguagesMap.keys()];
      }

      return [];
    }

    getNextPrototypeVersion() {
      return this.prototypes.reduce((current, prototype) => {
        const version = prototype.version;

        if (version > current) {
          return version;
        }

        return current;
      }, 0) + 1;
    }

    activate() {
      this.status = 'actif';
      return this.save();
    }

    deactivate() {
      this.status = 'en construction';
      return this.save();
    }

    archive() {
      this.status = 'archivé';
      return this.save();
    }

    obsolete() {
      this.status = 'périmé';
      return this.save();
    }

    pinRelationships() {
      const requests = [this.tutoSolution, this.tutoMore];
      return Promise.all(requests).then(tutorials => {
        this._pinnedRelationships = {
          tutoSolution: tutorials[0].toArray(),
          tutoMore: tutorials[1].toArray()
        };
      });
    }

    rollbackAttributes() {
      super.rollbackAttributes(...arguments);
      const tutoSolution = this._pinnedRelationships.tutoSolution;
      this.tutoSolution = tutoSolution;
      const tutoMore = this._pinnedRelationships.tutoMore;
      this.tutoMore = tutoMore;
    }

    save() {
      return super.save(...arguments).then(result => {
        this.pinRelationships();
        return result;
      });
    }

    clone() {
      const ignoredFields = ['pixId', 'competence', 'level', 'tube', 'createdAt', 'challenges', 'tutoSolution', 'tutoMore', 'version'];

      const data = this._getJSON(ignoredFields);

      data.status = 'en construction';
      data.pixId = this.idGenerator.newId('skill');
      const newSkill = this.myStore.createRecord(this.constructor.modelName, data);
      const requests = [this.tutoSolution, this.tutoMore];
      return Promise.all(requests).then(tutorials => {
        newSkill.tutoSolution = tutorials[0];
        newSkill.tutoMore = tutorials[1];
        return newSkill;
      });
    }

    _getJSON(fieldsToRemove) {
      const data = this.toJSON({
        idIncluded: false
      });
      fieldsToRemove.forEach(current => {
        if (data[current]) {
          delete data[current];
        }
      });
      return data;
    }

    _getCSSFromStatus(status) {
      switch (status) {
        case 'en contruction':
          return 'building';

        case 'actif':
          return 'active';

        case 'périmé':
          return 'out-of-date';

        case 'archivé':
          return 'archived';
      }

      return 'suggested';
    }

    _getCSSFromClueStatus(status) {
      switch (status) {
        case 'pré-validé':
          return 'prevalidated';

        case 'Validé':
          return 'validated';

        case 'à soumettre':
          return 'to-be-submitted';

        case 'à retravailler':
          return 'need-work';

        case 'Proposé':
          return 'suggested';

        case 'archivé':
          return 'archived';

        case 'inapplicable':
          return 'na';
      }

      return 'suggested';
    }

    _extractLanguagesAndAlternativesCountFromChallenges(extractedLanguages, challengeLanguages) {
      if (challengeLanguages) {
        challengeLanguages.forEach(language => {
          if (!extractedLanguages.has(language)) {
            extractedLanguages.set(language, 1);
          } else {
            extractedLanguages.set(language, extractedLanguages.get(language) + 1);
          }
        });
      }

      return extractedLanguages;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "clue", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "clueEn", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "clueStatus", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "descriptionStatus", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "level", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pixId", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "i18n", [_model.attr], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "version", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "tube", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "challenges", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "tutoSolution", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "tutoMore", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "myStore", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "idGenerator", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "_selected", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = SkillModel;
});