define("pixeditor/components/pop-in/threshold-calculation", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal @title={{@title}}
            @onCloseButtonClick={{this.closeModal}}
  >
    <:content>
      <div class="ui column centered grid">
        <table class="ui very basic collapsing celled table">
          <thead>
            <tr>
              <th colspan="2"></th>
              <th>Nombre d'acquis</th>
            </tr>
          </thead>
          <tbody>
            {{#each this.selectedSkillsLevels as |value|}}
              <TargetProfile::ThresholdRow @level={{value}} @selectedSkills={{this.selectedSkills}}/>
            {{/each}}
          </tbody>
          <tfoot>
            <tr>
              <th colspan="2">Nombre total d'acquis :</th>
              <th data-test-selectedSkillsCount >{{this.selectedSkillsCount}}</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </:content>
    <:footer>
      <PixButton data-test-sorting-pop-in-deny
                 @backgroundColor="transparent-light"
                 @isBorderVisible={{true}}
                 @triggerAction={{this.closeModal}}
      >
        Fermer
      </PixButton>
    </:footer>
  
  </PixModal>
  */
  {
    "id": "cNIt92Ez",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[[30,1],[30,0,[\"closeModal\"]]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"ui column centered grid\"],[12],[1,\"\\n      \"],[10,\"table\"],[14,0,\"ui very basic collapsing celled table\"],[12],[1,\"\\n        \"],[10,\"thead\"],[12],[1,\"\\n          \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[13],[1,\"\\n            \"],[10,\"th\"],[12],[1,\"Nombre d'acquis\"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"selectedSkillsLevels\"]]],null]],null],null,[[[1,\"            \"],[8,[39,3],null,[[\"@level\",\"@selectedSkills\"],[[30,2],[30,0,[\"selectedSkills\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,\"tfoot\"],[12],[1,\"\\n          \"],[10,\"tr\"],[12],[1,\"\\n            \"],[10,\"th\"],[14,\"colspan\",\"2\"],[12],[1,\"Nombre total d'acquis :\"],[13],[1,\"\\n            \"],[10,\"th\"],[14,\"data-test-selectedSkillsCount\",\"\"],[12],[1,[30,0,[\"selectedSkillsCount\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,\"data-test-sorting-pop-in-deny\",\"\"]],[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,0,[\"closeModal\"]]]],[[\"default\"],[[[[1,\"\\n      Fermer\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@title\",\"value\"],false,[\"pix-modal\",\"each\",\"-track-array\",\"target-profile/threshold-row\",\"pix-button\"]]",
    "moduleName": "pixeditor/components/pop-in/threshold-calculation.hbs",
    "isStrictMode": false
  });

  let PopinThresholdCalculation = (_dec = Ember._action, (_class = class PopinThresholdCalculation extends _component.default {
    get selectedSkills() {
      const selectedSkills = [];
      const areas = this.args.model;
      const selectedTubes = [];
      areas.forEach(area => {
        area.sortedCompetences.forEach(competence => {
          competence.productionTubes.forEach(tube => {
            if (tube.selectedLevel) {
              selectedTubes.push(tube);
            }
          });
        });
      });
      selectedTubes.forEach(tube => {
        tube.liveSkills.forEach(skill => {
          if (skill.isActive && skill.level <= tube.selectedLevel) {
            selectedSkills.push(skill);
          }
        });
      });
      return selectedSkills;
    }

    get selectedSkillsCount() {
      return this.selectedSkills.length;
    }

    get selectedSkillsLevels() {
      const levels = [];
      this.selectedSkills.forEach(skill => {
        if (!levels.includes(skill.level)) {
          levels.push(skill.level);
        }
      });
      return levels.sort();
    }

    closeModal() {
      this.args.close();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "closeModal", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "closeModal"), _class.prototype)), _class));
  _exports.default = PopinThresholdCalculation;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopinThresholdCalculation);
});