define("pixeditor/routes/competence/quality/index", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _authenticated.default {
    setupController() {
      super.setupController(...arguments);
      const competenceController = this.controllerFor('competence');
      competenceController.setSection('quality');
      competenceController.setView(null);
      competenceController.maximizeLeft(false);
    }

  }

  _exports.default = IndexRoute;
});