define("pixeditor/stories/pix-modal.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modal = _exports.argTypes = void 0;

  const modal = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixModal @title={{this.title}}>
              <:content>
                <p>
                  Une fenêtre modale est, dans une interface graphique, une fenêtre qui prend le contrôle total du clavier et
                  de l'écran. Elle est en général associée à une question à laquelle il est impératif que l'utilisateur
                  réponde avant de poursuivre, ou de modifier quoi que ce soit. La fenêtre modale a pour propos : d'obtenir
                  des informations de l'utilisateur (ces informations sont nécessaires pour réaliser une opération) ; de
                  fournir une information à l'utilisateur (ce dernier doit en prendre connaissance avant de pouvoir continuer
                  à utiliser l'application).
                </p>
              </:content>
              <:footer>
                <PixButton @backgroundColor="transparent-light" @isBorderVisible="true">Annuler</PixButton>
                <PixButton>Valider</PixButton>
              </:footer>
            </PixModal>
          
      */
      {
        "id": "tUNiqkof",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@title\"],[[30,0,[\"title\"]]]],[[\"content\",\"footer\"],[[[[1,\"\\n          \"],[10,2],[12],[1,\"\\n            Une fenêtre modale est, dans une interface graphique, une fenêtre qui prend le contrôle total du clavier et\\n            de l'écran. Elle est en général associée à une question à laquelle il est impératif que l'utilisateur\\n            réponde avant de poursuivre, ou de modifier quoi que ce soit. La fenêtre modale a pour propos : d'obtenir\\n            des informations de l'utilisateur (ces informations sont nécessaires pour réaliser une opération) ; de\\n            fournir une information à l'utilisateur (ce dernier doit en prendre connaissance avant de pouvoir continuer\\n            à utiliser l'application).\\n          \"],[13],[1,\"\\n        \"]],[]],[[[1,\"\\n          \"],[8,[39,1],null,[[\"@backgroundColor\",\"@isBorderVisible\"],[\"transparent-light\",\"true\"]],[[\"default\"],[[[[1,\"Annuler\"]],[]]]]],[1,\"\\n          \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"Valider\"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-modal\",\"pix-button\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.modal = modal;
  const argTypes = {
    title: {
      name: 'title',
      description: 'Titre de la modale',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: "Qu'est-ce qu'une modale ?"
    },
    onCloseButtonClick: {
      name: 'onCloseButtonClick',
      description: 'Fonction à executer lors du clic sur le bouton de fermeture de la modale',
      type: {
        name: 'function',
        required: true
      },
      defaultValue: null
    }
  };
  _exports.argTypes = argTypes;
});