define("pixeditor/services/file-saver", ["exports", "file-saver"], function (_exports, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FileSaverService extends Ember.Service {
    saveAs(content, name) {
      let type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'text/plain;charset=utf-8';
      const file = new File([content], name, {
        type: type
      });

      _fileSaver.default.saveAs(file);
    }

  }

  _exports.default = FileSaverService;
});