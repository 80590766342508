define("pixeditor/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ApplicationController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember.inject.service, _dec13 = Ember.inject.service, _dec14 = Ember.inject.service, _dec15 = Ember.inject.service, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = Ember._action, _dec24 = Ember._action, (_class = class ApplicationController extends Ember.Controller {
    constructor() {
      super(...arguments);

      _defineProperty(this, "displayConfig", false);

      _defineProperty(this, "displayChangelog", false);

      _defineProperty(this, "popinImageSrc", '');

      _defineProperty(this, "confirmCallback", null);

      _initializerDefineProperty(this, "confirmTitle", _descriptor, this);

      _initializerDefineProperty(this, "confirmContent", _descriptor2, this);

      _initializerDefineProperty(this, "displayConfirm", _descriptor3, this);

      _initializerDefineProperty(this, "loading", _descriptor4, this);

      _initializerDefineProperty(this, "loadingMessage", _descriptor5, this);

      _initializerDefineProperty(this, "_menuOpen", _descriptor6, this);

      _initializerDefineProperty(this, "displayLoginForm", _descriptor7, this);

      _initializerDefineProperty(this, "displayLogout", _descriptor8, this);

      _initializerDefineProperty(this, "config", _descriptor9, this);

      _initializerDefineProperty(this, "router", _descriptor10, this);

      _initializerDefineProperty(this, "notify", _descriptor11, this);

      _initializerDefineProperty(this, "loader", _descriptor12, this);

      _initializerDefineProperty(this, "confirm", _descriptor13, this);

      _initializerDefineProperty(this, "store", _descriptor14, this);

      _initializerDefineProperty(this, "window", _descriptor15, this);

      _defineProperty(this, "messages", Ember.A([]));

      this.notify.setTarget(this);
      this.loader.setTarget(this);
      this.confirm.setTarget(this);
    }

    get menuOpen() {
      if (this.router.currentRouteName === 'index' || this.lockedMenu) {
        return true;
      } else {
        return this._menuOpen;
      }
    }

    get lockedMenu() {
      return this.displayLoginForm;
    }

    get isIndex() {
      return this.router.currentRouteName === 'index';
    }

    showMessage(content, positive) {
      const messages = this.messages;
      const id = 'message_' + Date.now();
      messages.pushObject({
        text: content,
        positive: positive ? true : false,
        id: id
      });
      window.setTimeout(() => {
        const nodeMessage = document.getElementById(id);

        if (nodeMessage) {
          nodeMessage.addEventListener('transitionend', () => {
            messages.removeAt(0);
          });
          nodeMessage.style.transition = 'opacity .8s ease';
          nodeMessage.style.opacity = '0';
        }
      }, 3000);
    }

    isLoading(message) {
      this.loading = true;
      this.loadingMessage = message;
    }

    finishedLoading() {
      this.loading = false;
      this.loadingMessage = '';
    }

    openLoginForm() {
      this.displayLoginForm = true;
    }

    closeLoginForm() {
      this.displayLoginForm = false;
    }

    openLogout() {
      this.displayLogout = true;
    }

    closeLogout() {
      this.displayLogout = false;
    }

    configUpdated() {
      this.window.reload();
    }

    confirmAsk(title, message, callback) {
      this.confirmCallback = callback;
      this.confirmTitle = title;
      this.confirmContent = message;
      this.displayConfirm = true;
    }

    confirmApprove() {
      this.displayConfirm = false;

      if (this.confirmCallback) {
        this.confirmCallback(true);
      }
    }

    confirmDeny() {
      this.displayConfirm = false;

      if (this.confirmCallback) {
        this.confirmCallback(false);
      }
    }

    toggleMenu() {
      this._menuOpen = !this._menuOpen;
    }

    closeMenu() {
      if (this._menuOpen) {
        this._menuOpen = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "confirmTitle", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "confirmContent", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "displayConfirm", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loadingMessage", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_menuOpen", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "displayLoginForm", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "displayLogout", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "config", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "loader", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "confirm", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "window", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openLoginForm", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "openLoginForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeLoginForm", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "closeLoginForm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openLogout", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "openLogout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeLogout", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "closeLogout"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "configUpdated", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "configUpdated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmApprove", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "confirmApprove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmDeny", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "confirmDeny"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMenu", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMenu"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMenu", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "closeMenu"), _class.prototype)), _class));
  _exports.default = ApplicationController;
});