define("pixeditor/stories/pix-button-upload.stories", ["exports", "@storybook/addon-actions"], function (_exports, _addonActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buttonUpload = _exports.argTypes = void 0;

  const buttonUpload = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixButtonUpload
              @id="id"
              @onChange={{onChange}}
              @shape={{shape}}
              @backgroundColor={{backgroundColor}}
              @size={{size}}
              @isBorderVisible={{isBorderVisible}}
            >
              Cliquer pour uploader un fichier
            </PixButtonUpload>
          
      */
      {
        "id": "0lYIYEYY",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@id\",\"@onChange\",\"@shape\",\"@backgroundColor\",\"@size\",\"@isBorderVisible\"],[\"id\",[36,1],[36,2],[36,3],[36,4],[36,5]]],[[\"default\"],[[[[1,\"\\n        Cliquer pour uploader un fichier\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-button-upload\",\"onChange\",\"shape\",\"backgroundColor\",\"size\",\"isBorderVisible\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.buttonUpload = buttonUpload;
  buttonUpload.args = {
    onChange: (0, _addonActions.action)('onChange')
  };
  const argTypes = {
    id: {
      name: 'id',
      description: "identifiant du bouton d'upload",
      type: {
        name: 'string',
        required: true
      },
      defaultValue: 'file-upload'
    },
    onChange: {
      name: 'onChange',
      description: "fonction à exécuter au moment de l'upload du fichier, elle prend en entrée la liste des fichiers uploadés.",
      type: {
        name: 'function',
        required: true
      },
      defaultValue: null
    },
    shape: {
      name: 'shape',
      description: 'forme: `rounded`,`squircle`',
      type: {
        name: 'string',
        required: false
      },
      options: ['rounded', 'squircle'],
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'squircle'
        }
      }
    },
    backgroundColor: {
      name: 'backgroundColor',
      description: 'color: `blue`, `green`, `yellow`, `red`, `grey`, `transparent-light`, `transparent-dark`',
      options: ['blue', 'green', 'yellow', 'red', 'grey', 'transparent-light', 'transparent-dark'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'blue'
        }
      }
    },
    size: {
      name: 'size',
      description: 'taille: `big`,`small`',
      options: ['big', 'small'],
      type: {
        name: 'string',
        required: false
      },
      control: {
        type: 'select'
      },
      table: {
        type: {
          summary: 'string'
        },
        defaultValue: {
          summary: 'big'
        }
      }
    },
    isBorderVisible: {
      name: 'isBorderVisible',
      description: 'Paramètre utilisé seulement quand le `backgroundColor` est `transparent-light` ou `transparent-dark`',
      type: {
        name: 'boolean',
        required: false
      },
      control: {
        type: 'boolean'
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: 'false'
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});