define("pixeditor/components/sidebar/search", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @displaySearch}}
    <div data-test-sidebar-search class="sidebar-search">
      <PowerSelect
        @searchEnabled={{true}}
        @search={{this.getSearchResults}}
        @placeholder="Acquix ou recordId"
        @onChange={{this.linkTo}}
        @renderInPlace={{true}}
        @triggerComponent={{ component "power-select/custom-trigger" }}
        @searchMessage=""
        @searchPlaceholder="Rechercher..."
        @loadingMessage="Recherche en cours..."
        @noMatchesMessage="Pas de résultat" as |result|>
        {{#if result.isSkill}}
          {{result.title}} v{{result.version}}<span class="skill-result-status {{result.statusCSS}}" title={{result.status}}></span>
        {{else}}
          {{result.title}}
        {{/if}}
      </PowerSelect>
    </div>
  {{/if}}
  
  */
  {
    "id": "zEUf0wRM",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,\"data-test-sidebar-search\",\"\"],[14,0,\"sidebar-search\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@searchEnabled\",\"@search\",\"@placeholder\",\"@onChange\",\"@renderInPlace\",\"@triggerComponent\",\"@searchMessage\",\"@searchPlaceholder\",\"@loadingMessage\",\"@noMatchesMessage\"],[true,[30,0,[\"getSearchResults\"]],\"Acquix ou recordId\",[30,0,[\"linkTo\"]],true,[50,\"power-select/custom-trigger\",0,null,null],\"\",\"Rechercher...\",\"Recherche en cours...\",\"Pas de résultat\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,2,[\"isSkill\"]],[[[1,\"        \"],[1,[30,2,[\"title\"]]],[1,\" v\"],[1,[30,2,[\"version\"]]],[10,1],[15,0,[29,[\"skill-result-status \",[30,2,[\"statusCSS\"]]]]],[15,\"title\",[30,2,[\"status\"]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,2,[\"title\"]]],[1,\"\\n\"]],[]]],[1,\"    \"]],[2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@displaySearch\",\"result\"],false,[\"if\",\"power-select\",\"component\"]]",
    "moduleName": "pixeditor/components/sidebar/search.hbs",
    "isStrictMode": false
  });

  let SidebarSearchComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class SidebarSearchComponent extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "routeModel", null);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    async getSearchResults(query) {
      query = query.trim();

      if (query.startsWith('@')) {
        this.routeModel = 'skill';
        const skills = await this.store.query('skill', {
          filterByFormula: `FIND('${query.toLowerCase()}', LOWER(Nom))`,
          maxRecords: 20,
          sort: [{
            field: 'Nom',
            direction: 'asc'
          }]
        });
        return skills.map(skill => ({
          isSkill: true,
          title: skill.name,
          name: skill.name,
          status: skill.status,
          statusCSS: skill.statusCSS,
          version: skill.version
        }));
      } else if (query.startsWith('rec') || query.startsWith('challenge')) {
        this.routeModel = 'challenge';
        const challenges = await this.store.query('challenge', {
          filter: {
            ids: [query]
          }
        });
        return challenges.map(challenge => ({
          title: challenge.id,
          id: challenge.id
        }));
      } else {
        this.routeModel = 'challenge';
        const challenges = await this.store.query('challenge', {
          filter: {
            search: query.toLowerCase()
          },
          page: {
            size: 20
          }
        });
        return challenges.map(challenge => ({
          title: challenge.instruction.substr(0, 100),
          id: challenge.id
        }));
      }
    }

    linkTo(item) {
      const route = this.routeModel;
      const router = this.router;
      this.args.close();

      if (route === 'skill') {
        router.transitionTo(route, item.name);
      } else {
        router.transitionTo(route, item.id);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getSearchResults", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "getSearchResults"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "linkTo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "linkTo"), _class.prototype)), _class));
  _exports.default = SidebarSearchComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SidebarSearchComponent);
});