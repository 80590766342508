define("pixeditor/components/competence/grid/cell-quality", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class="skill-cell quality-skill-cell">
    <EmberTooltip @tooltipClass="custom-tooltip quality-tooltip" @arrowClass="custom-arrow" @side="right-start">
      <table class="quality-indication-details">
        <tbody>
          {{this.popupBuild}}
        </tbody>
      </table>
    </EmberTooltip >
    <LinkTo @route="competence.quality.single" @model={{@skill}} class="skill-cell__link">
      <div class="quality {{this.qualityClassColor}}">
        <div class=" quality-indication">
          <span>{{@skill.name}}</span>
          <span>{{this.qualityIndication}}</span>
        </div>
        {{#if this.classTutorial}}
          <i class="lab icon {{this.classTutorial}}"></i>
        {{/if}}
        {{#if @skill.productionPrototype.timer }}
          <i class="time icon"></i>
        {{/if}}
        {{#if (and (not this.classTutorial) (not @skill.productionPrototype.timer))}}
          <br>
        {{/if}}
      </div>
    </LinkTo>
  </td>
  
  */
  {
    "id": "sjFs+ojL",
    "block": "[[[10,\"td\"],[14,0,\"skill-cell quality-skill-cell\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@tooltipClass\",\"@arrowClass\",\"@side\"],[\"custom-tooltip quality-tooltip\",\"custom-arrow\",\"right-start\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,\"table\"],[14,0,\"quality-indication-details\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n        \"],[1,[30,0,[\"popupBuild\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],[[24,0,\"skill-cell__link\"]],[[\"@route\",\"@model\"],[\"competence.quality.single\",[30,1]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[15,0,[29,[\"quality \",[30,0,[\"qualityClassColor\"]]]]],[12],[1,\"\\n      \"],[10,0],[14,0,\" quality-indication\"],[12],[1,\"\\n        \"],[10,1],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n        \"],[10,1],[12],[1,[30,0,[\"qualityIndication\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"classTutorial\"]],[[[1,\"        \"],[10,\"i\"],[15,0,[29,[\"lab icon \",[30,0,[\"classTutorial\"]]]]],[12],[13],[1,\"\\n\"]],[]],null],[41,[30,1,[\"productionPrototype\",\"timer\"]],[[[1,\"        \"],[10,\"i\"],[14,0,\"time icon\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,3],[[28,[37,4],[[30,0,[\"classTutorial\"]]],null],[28,[37,4],[[30,1,[\"productionPrototype\",\"timer\"]]],null]],null],[[[1,\"        \"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@skill\"],false,[\"ember-tooltip\",\"link-to\",\"if\",\"and\",\"not\"]]",
    "moduleName": "pixeditor/components/competence/grid/cell-quality.hbs",
    "isStrictMode": false
  });

  class CellQuality extends _component.default {
    get qualityIndication() {
      const productionPrototype = this.args.skill.productionPrototype;
      const allWeight = 19;

      const spoil = this._spoilWeight(productionPrototype.spoil);

      const responsive = this._responsiveWeight(productionPrototype.responsive);

      const colorblind = this._colorblindWeight(productionPrototype.accessibility2);

      const a11Y = this._a11YWeight(productionPrototype.accessibility1);

      const clue = this._clueWeight(this.args.skill.clueStatus);

      const result = (spoil + responsive + colorblind + a11Y + clue) / allWeight;
      return Math.round(result * 100);
    }

    get qualityClassColor() {
      const qualityIndication = this.qualityIndication;

      if (qualityIndication < 50) {
        return 'quality bad-quality';
      }

      if (qualityIndication < 80) {
        return 'quality medium-quality';
      }

      return 'quality good-quality';
    }

    get classTutorial() {
      const hasTutoSolution = this.args.skill.tutoSolution.length > 0;
      const hasTutoMore = this.args.skill.tutoMore.length > 0;

      if (hasTutoSolution && hasTutoMore) {
        return 'have-tutorial';
      }

      if (hasTutoSolution || hasTutoMore) {
        return 'half-tutorial';
      }

      return false;
    }

    get popupBuild() {
      const productionPrototype = this.args.skill.productionPrototype;

      const spoil = this._isNonTested(productionPrototype.spoil);

      const responsive = this._isNonTested(productionPrototype.responsive);

      const blind = this._isNonTested(productionPrototype.accessibility1);

      const colorblind = this._isNonTested(productionPrototype.accessibility2);

      const skillClue = this.args.skill.clueStatus;
      const clue = skillClue ? skillClue : 'Pas d\'indice';
      const skillTimer = productionPrototype.timer;
      const timer = skillTimer ? `<tr><td>Timer</td><td>${skillTimer} s</td></tr>` : '';
      const classTuto = this.classTutorial;
      const tutoSolutionCount = this.args.skill.tutoSolution.length;
      const tutoMoreCount = this.args.skill.tutoMore.length;
      const haveTuto = classTuto ? `<tr><td>Tuto comprendre </td><td> ${tutoSolutionCount}</td></tr>
                                <tr><td>Tuto en savoir + </td><td> ${tutoMoreCount}</td></tr>` : '';
      return Ember.String.htmlSafe(`<tr><td>Spoil </td><td> ${spoil} </td></tr>
            <tr><td>Responsive </td><td> ${responsive} </td></tr>
            <tr><td>Non/Mal voyant </td><td> ${blind} </td></tr>
            <tr><td>Daltonien </td><td> ${colorblind} </td></tr>
            <tr><td>Indice </td><td> ${clue} </td></tr>
            ${haveTuto}
            ${timer}`);
    }

    _spoilWeight(spoil) {
      const weight = 5;
      const quality = {
        'Non Sp': 3,
        'Difficilement Sp': 2,
        'Facilement Sp': 1,
        'default': 0
      };
      return (quality[spoil] || quality['default']) / 3 * weight;
    }

    _responsiveWeight(responsive) {
      const weight = 4;
      const quality = {
        'Tablette': 1,
        'Smartphone': 1,
        'Tablette/Smartphone': 2,
        'default': 0
      };
      return (quality[responsive] || quality['default']) / 2 * weight;
    }

    _colorblindWeight(colorblind) {
      const weight = 3;
      const quality = {
        'RAS': 1,
        'OK': 1,
        'default': 0
      };
      return (quality[colorblind] || quality['default']) * weight;
    }

    _a11YWeight(a11Y) {
      const weight = 3;
      const quality = {
        'RAS': 2,
        'OK': 2,
        'Acquis Non Pertinent': 2,
        'KO': 0,
        'default': 0
      };
      return (quality[a11Y] || quality['default']) / 2 * weight;
    }

    _clueWeight(clue) {
      const weight = 4;
      const quality = {
        'Validé': 4,
        'pré-validé': 3,
        'Proposé': 2,
        'à soumettre': 2,
        'à retravailler': 1,
        'archiver': 0,
        'default': 0
      };
      return (quality[clue] || quality['default']) / 4 * weight;
    }

    _isNonTested(skillDetail) {
      if (skillDetail) {
        return skillDetail;
      }

      return 'Non testé';
    }

  }

  _exports.default = CellQuality;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CellQuality);
});