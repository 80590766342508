define("pixeditor/routes/competence/prototypes/single/alternatives", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AlternativesRoute extends _authenticated.default {
    model() {
      return this.modelFor('competence.prototypes.single');
    }

    setupController(controller) {
      super.setupController(...arguments);
      controller.maximizeRight(false);
    }

    renderTemplate() {
      this.render('competence/prototypes/single/alternatives', {
        into: 'competence',
        outlet: 'mainRight'
      });
    }

    resetController(controller) {
      controller.rightMaximized = false;
    }

  }

  _exports.default = AlternativesRoute;
});