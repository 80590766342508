define("pixeditor/components/pop-in/new-framework", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PixModal
    @title="Créer un référentiel"
    @onCloseButtonClick={{@close}}
  >
    <:content>
      <form action="" class="ui form" {{on "submit" this.saveOnSubmit}}>
        <Field::Input data-test-framework-name-input @value={{@framework.name}} @edition={{true}} @title="Nom"/>
      </form>
    </:content>
    <:footer>
      <PixButton @backgroundColor="transparent-light"
                 @isBorderVisible={{true}}
                 @triggerAction={{@close}}
      >
        Annuler
      </PixButton>
      <PixButton data-test-save-action
                 @triggerAction={{@save}}
                 @isDisabled={{this.hasEmptyMandatoryField}}
      >
        Enregistrer
        <i class="save icon"></i>
      </PixButton>
    </:footer>
  </PixModal>
  
  */
  {
    "id": "mbovCyBX",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@onCloseButtonClick\"],[\"Créer un référentiel\",[30,1]]],[[\"content\",\"footer\"],[[[[1,\"\\n    \"],[11,\"form\"],[24,\"action\",\"\"],[24,0,\"ui form\"],[4,[38,1],[\"submit\",[30,0,[\"saveOnSubmit\"]]],null],[12],[1,\"\\n      \"],[8,[39,2],[[24,\"data-test-framework-name-input\",\"\"]],[[\"@value\",\"@edition\",\"@title\"],[[30,2,[\"name\"]],true,\"Nom\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@backgroundColor\",\"@isBorderVisible\",\"@triggerAction\"],[\"transparent-light\",true,[30,1]]],[[\"default\"],[[[[1,\"\\n      Annuler\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,3],[[24,\"data-test-save-action\",\"\"]],[[\"@triggerAction\",\"@isDisabled\"],[[30,3],[30,0,[\"hasEmptyMandatoryField\"]]]],[[\"default\"],[[[[1,\"\\n      Enregistrer\\n      \"],[10,\"i\"],[14,0,\"save icon\"],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[\"@close\",\"@framework\",\"@save\"],false,[\"pix-modal\",\"on\",\"field/input\",\"pix-button\"]]",
    "moduleName": "pixeditor/components/pop-in/new-framework.hbs",
    "isStrictMode": false
  });

  let PopInNewFrameworkComponent = (_dec = Ember._action, (_class = class PopInNewFrameworkComponent extends _component.default {
    get hasEmptyMandatoryField() {
      const framework = this.args.framework;
      return this._fieldIsEmpty(framework.name);
    }

    _fieldIsEmpty(field) {
      return field === undefined || field.trim() === '';
    }

    saveOnSubmit(e) {
      e.preventDefault();

      if (!this.hasEmptyMandatoryField) {
        this.args.save();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "saveOnSubmit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "saveOnSubmit"), _class.prototype)), _class));
  _exports.default = PopInNewFrameworkComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PopInNewFrameworkComponent);
});