define("pixeditor/adapters/changelog-entry", ["exports", "pixeditor/adapters/note"], function (_exports, _note) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ChangelogEntryAdapter extends _note.default {}

  _exports.default = ChangelogEntryAdapter;
});