define("pixeditor/routes/competence/quality", ["exports", "pixeditor/routes/authenticated"], function (_exports, _authenticated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class QualityRoute extends _authenticated.default {}

  _exports.default = QualityRoute;
});