define("pixeditor/components/target-profile/navigation", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <BasicDropdown @horizontalPosition="left" as |dd|>
    <dd.Trigger class="trigger-menu"><i class="bars icon"></i></dd.Trigger>
    <dd.Content class="dropdown-multi profile-menu slide-fade">
      {{#each @areas as |area|}}
          <BasicDropdown class="sub-menu" @calculatePosition={{this.calculatePosition}} as |dd|>
          <dd.Trigger class="sub-trigger">
            {{area.name}} <i class="icon dropdown"></i>
          </dd.Trigger>
          <dd.Content @onMouseEnter={{this.open}} @onMouseLeave={{this.close}} class="dropdown-multi slide-fade">
            {{#each area.sortedCompetences as |competence|}}
              <div class="item area-profile-{{area.code}}" {{on "click" (fn this.scrollTo (concat "competence-profile-anchor-" competence.id))}}>
                <b>{{competence.code}}</b> {{competence.title}}</div>
            {{/each}}
          </dd.Content>
        </BasicDropdown>
      {{/each}}
    </dd.Content>
  </BasicDropdown>
  
  */
  {
    "id": "Tsql0gs2",
    "block": "[[[8,[39,0],null,[[\"@horizontalPosition\"],[\"left\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Trigger\"]],[[24,0,\"trigger-menu\"]],null,[[\"default\"],[[[[10,\"i\"],[14,0,\"bars icon\"],[12],[13]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Content\"]],[[24,0,\"dropdown-multi profile-menu slide-fade\"]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,2]],null]],null],null,[[[1,\"        \"],[8,[39,0],[[24,0,\"sub-menu\"]],[[\"@calculatePosition\"],[[30,0,[\"calculatePosition\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"Trigger\"]],[[24,0,\"sub-trigger\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3,[\"name\"]]],[1,\" \"],[10,\"i\"],[14,0,\"icon dropdown\"],[12],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,4,[\"Content\"]],[[24,0,\"dropdown-multi slide-fade\"]],[[\"@onMouseEnter\",\"@onMouseLeave\"],[[30,0,[\"open\"]],[30,0,[\"close\"]]]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,3,[\"sortedCompetences\"]]],null]],null],null,[[[1,\"            \"],[11,0],[16,0,[29,[\"item area-profile-\",[30,3,[\"code\"]]]]],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"scrollTo\"]],[28,[37,5],[\"competence-profile-anchor-\",[30,5,[\"id\"]]],null]],null]],null],[12],[1,\"\\n              \"],[10,\"b\"],[12],[1,[30,5,[\"code\"]]],[13],[1,\" \"],[1,[30,5,[\"title\"]]],[13],[1,\"\\n\"]],[5]],null],[1,\"        \"]],[]]]]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n\"]],[3]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"dd\",\"@areas\",\"area\",\"dd\",\"competence\"],false,[\"basic-dropdown\",\"each\",\"-track-array\",\"on\",\"fn\",\"concat\"]]",
    "moduleName": "pixeditor/components/target-profile/navigation.hbs",
    "isStrictMode": false
  });

  let TargetProfileNavigationComponent = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class TargetProfileNavigationComponent extends _component.default {
    calculatePosition(trigger) {
      const {
        top,
        left,
        width
      } = trigger.getBoundingClientRect();
      const style = {
        left: left + width,
        top: top + window.pageYOffset
      };
      return {
        style
      };
    }

    open(dropdown) {
      dropdown.actions.open();
    }

    close(dropdown) {
      dropdown.actions.close();
    }

    scrollTo(anchor) {
      const target = document.querySelector(`#${anchor}`);
      document.querySelector('.target-profile').scrollTo({
        top: target.offsetTop - 154,
        left: 0,
        behavior: 'smooth'
      });
    }

    prevent() {
      return false;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "open", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "open"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollTo", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "scrollTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "prevent", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "prevent"), _class.prototype)), _class));
  _exports.default = TargetProfileNavigationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TargetProfileNavigationComponent);
});