define("pixeditor/controllers/competence/skills/new", ["exports", "pixeditor/controllers/competence/skills/single", "@sentry/ember"], function (_exports, _single, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._action, _dec6 = Ember._action, (_class = class NewController extends _single.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "competence", null);

      _defineProperty(this, "tubeId", null);

      _defineProperty(this, "defaultSaveSkillChangelog", 'Création de l\'acquis');

      _initializerDefineProperty(this, "changelogEntry", _descriptor, this);

      _initializerDefineProperty(this, "loader", _descriptor2, this);

      _initializerDefineProperty(this, "notify", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    get skill() {
      return this.model.skill;
    }

    get tube() {
      return this.model.tube;
    }

    cancelEdit() {
      this.store.deleteRecord(this.skill);
      this.edition = false;
      this.notify.message('Création annulée');
      this.parentController.send('closeChildComponent');
    }

    async save() {
      this.loader.start();
      const skill = this.skill;
      const tube = this.tube;

      try {
        skill.tube = tube;
        skill.version = tube.getNextSkillVersion(skill.level);
        await skill.save();
        await this._handleSkillChangelog(skill, this.defaultSaveSkillChangelog, this.changelogEntry.createAction);
        this.edition = false;
        this.loader.stop();
        this.notify.message('Acquis créé');
      } catch (error) {
        console.error(error);

        _ember.default.captureException(error);

        this.loader.stop();
        this.notify.error('Erreur lors de la création de l\'acquis');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "changelogEntry", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loader", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notify", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "cancelEdit", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cancelEdit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));
  _exports.default = NewController;
});