define("pixeditor/stories/pix-selectable-tag.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.selectableTagSelected = _exports.selectableTagMultiple = _exports.selectableTagDefault = _exports.argTypes = void 0;

  const selectableTagDefault = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixSelectableTag  
              @label="non sélectionné"
              @id="123"
              @onChange={{onChange}}
              @checked={{false}}
            />
          
      */
      {
        "id": "p+01fXsR",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@label\",\"@id\",\"@onChange\",\"@checked\"],[\"non sélectionné\",\"123\",[36,1],false]],null],[1,\"\\n    \"]],[],false,[\"pix-selectable-tag\",\"onChange\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.selectableTagDefault = selectableTagDefault;

  const selectableTagSelected = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixSelectableTag  
              @label="Sélectionné"
              @id="456"
              @onChange={{onChange}}
              @checked={{true}}
            />
          
      */
      {
        "id": "rOV4CrGb",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@label\",\"@id\",\"@onChange\",\"@checked\"],[\"Sélectionné\",\"456\",[36,1],true]],null],[1,\"\\n    \"]],[],false,[\"pix-selectable-tag\",\"onChange\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.selectableTagSelected = selectableTagSelected;

  const selectableTagMultiple = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <div style="display:flex;justify-content:space-around;width:400px;height:100%;padding:10px">
              <PixSelectableTag
                @label="Lorem ipsum"
                @id="1"
                @onChange={{onChange}}
                @checked={{false}}
              />
              <PixSelectableTag
                @label="Lorem ipsum"
                @id="2"
                @onChange={{onChange}}
                @checked={{false}}
              />
              <PixSelectableTag
                @label="Lorem ipsum"
                @id="3"
                @onChange={{onChange}}
                @checked={{false}}
              />
            </div>
          
      */
      {
        "id": "wsTAJY2g",
        "block": "[[[1,\"\\n      \"],[10,0],[14,5,\"display:flex;justify-content:space-around;width:400px;height:100%;padding:10px\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@label\",\"@id\",\"@onChange\",\"@checked\"],[\"Lorem ipsum\",\"1\",[36,1],false]],null],[1,\"\\n        \"],[8,[39,0],null,[[\"@label\",\"@id\",\"@onChange\",\"@checked\"],[\"Lorem ipsum\",\"2\",[36,1],false]],null],[1,\"\\n        \"],[8,[39,0],null,[[\"@label\",\"@id\",\"@onChange\",\"@checked\"],[\"Lorem ipsum\",\"3\",[36,1],false]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[],false,[\"pix-selectable-tag\",\"onChange\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.selectableTagMultiple = selectableTagMultiple;
  const argTypes = {
    label: {
      name: 'label',
      description: 'Le label du tag sélectionnable',
      type: {
        name: 'string',
        required: true
      },
      table: {
        type: {
          summary: 'string'
        }
      }
    },
    id: {
      name: 'id',
      description: "L'id du tag sélectionnable",
      type: {
        name: 'string',
        required: true
      },
      table: {
        type: {
          summary: 'string'
        }
      }
    },
    onChange: {
      name: 'onChange',
      description: 'Fonction à appeler si le tag est sélectionné',
      type: {
        required: true
      },
      control: {
        disable: true
      }
    },
    checked: {
      name: 'checked',
      description: 'Indiquez si le tag doit être coché',
      type: {
        name: 'boolean',
        required: true
      },
      table: {
        type: {
          summary: 'boolean'
        },
        defaultValue: {
          summary: false
        }
      }
    }
  };
  _exports.argTypes = argTypes;
});