define("pixeditor/routes/competence/quality/single", ["exports", "pixeditor/routes/competence/skills/single"], function (_exports, _single) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SingleRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class SingleRoute extends _single.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "templateName", 'competence/skills/single');

      _initializerDefineProperty(this, "currentData", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);
    }

    setupController() {
      super.setupController(...arguments);
      const competenceController = this.controllerFor('competence');
      competenceController.setSection('quality');
      competenceController.setView(null);
    }

    willTransition(transition) {
      if (this.controllerFor('competence.quality.single').edition && !confirm('Êtes-vous sûr de vouloir abandonner la modification en cours ?')) {
        transition.abort();
      } else {
        this.controllerFor('competence.quality.single').model.rollbackAttributes();

        if (transition.targetName === 'competence.prototypes.index') {
          const skill = this.controllerFor('competence.quality.single').skill;
          const prototype = skill.productionPrototype;

          if (prototype) {
            return this.router.transitionTo('competence.prototypes.single', this.currentData.getCompetence(), prototype);
          }
        } else if (transition.targetName === 'competence.skills.index') {
          return this.router.transitionTo('competence.skills.single', this.currentData.getCompetence(), this.controllerFor('competence.quality.single').model);
        }

        return true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentData", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype)), _class));
  _exports.default = SingleRoute;
});