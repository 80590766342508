define("pixeditor/stories/pix-input-password.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.withPrefix = _exports.withLabelAndInformation = _exports.withErrorMessage = _exports.argTypes = _exports.Template = _exports.Default = void 0;

  const Template = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixInputPassword
              @ariaLabel={{ariaLabel}}
              @id={{id}}
              @label={{label}}
              @information={{information}}
              @errorMessage={{errorMessage}}
              @prefix={{prefix}}
            />
          
      */
      {
        "id": "hZ7fxzcr",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@ariaLabel\",\"@id\",\"@label\",\"@information\",\"@errorMessage\",\"@prefix\"],[[36,1],[36,2],[36,3],[36,4],[36,5],[36,6]]],null],[1,\"\\n    \"]],[],false,[\"pix-input-password\",\"ariaLabel\",\"id\",\"label\",\"information\",\"errorMessage\",\"prefix\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.Template = Template;
  const Default = Template.bind({});
  _exports.Default = Default;
  Default.args = {
    id: 'firstName',
    ariaLabel: 'Mot de passe'
  };
  const withLabelAndInformation = Template.bind({});
  _exports.withLabelAndInformation = withLabelAndInformation;
  withLabelAndInformation.args = {
    id: 'password',
    label: 'Mot de passe',
    information: 'Une brève information'
  };
  const withErrorMessage = Template.bind({});
  _exports.withErrorMessage = withErrorMessage;
  withErrorMessage.args = {
    id: 'password',
    label: 'Mot de passe',
    errorMessage: "Un message d'erreur."
  };
  const withPrefix = Template.bind({});
  _exports.withPrefix = withPrefix;
  withPrefix.args = {
    id: 'password',
    label: 'Mot de passe',
    prefix: 'C -'
  };
  const argTypes = {
    id: {
      name: 'id',
      description: 'Identifiant du champ permettant de lui attacher un label',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    value: {
      name: 'value',
      description: "Valeur de l'input",
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    label: {
      name: 'label',
      description: "Label de l'input. Requis si ariaLabel n'est pas définit.",
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    ariaLabel: {
      name: 'ariaLabel',
      description: "L'action du bouton, pour l'accessibilité. Requis si label n'est pas définit.",
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    information: {
      name: 'information',
      description: 'Un descriptif complétant le label',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    errorMessage: {
      name: 'errorMessage',
      description: "Affiche le message d'erreur donné et encadre en rouge le champ",
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    },
    prefix: {
      name: 'prefix',
      description: 'Affiche un préfixe avant la zone de saisie du champ',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: null
    }
  };
  _exports.argTypes = argTypes;
});