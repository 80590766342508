define("pixeditor/serializers/tag", ["exports", "pixeditor/serializers/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class TagSerializer extends _airtable.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "primaryKey", 'Record ID');

      _defineProperty(this, "attrs", {
        title: 'Nom',
        description: 'Description',
        notes: 'Notes',
        skills: 'Acquis',
        tutorials: 'Tutoriels',
        pixId: 'id persistant'
      });
    }

    payloadKeyFromModelName() {
      return 'Tags';
    }

  }

  _exports.default = TagSerializer;
});