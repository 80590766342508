define("pixeditor/components/list/alternatives", ["exports", "pixeditor/components/list/sorted"], function (_exports, _sorted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable as |t|>
    <t.head
            @columns={{this.headers}}
            @widthConstraint={{'eq-container'}}
            @resizeMode={{'fluid'}}
            @onUpdateSorts={{this.sortBy}}
            @sortFunction={{this.sort}}
            @sorts={{this.sorts}}/>
    <t.body
            @renderAll={{this.renderAll}}
            @rows={{@list}}
            @rowSelectionMode={{'single'}}
            @checkboxSelectionMode={{'none'}}
            @onSelect={{this.selectRow}} as |b|>
      <b.row as |r|>
        <r.cell class="{{if r.columnValue.style r.rowValue.statusCSS}}" as |value|>
          {{#if r.columnValue.locales}}
          <div class="challenge_languages">
            {{#each r.rowValue.locales as |language| }}
              <i class="{{convert-language-as-flag language}} flag" title="{{language}}"></i>
            {{/each}}
          </div>
          {{else}}
            {{value}}
          {{/if}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  
  */
  {
    "id": "vPC23E4G",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,[[\"@columns\",\"@widthConstraint\",\"@resizeMode\",\"@onUpdateSorts\",\"@sortFunction\",\"@sorts\"],[[30,0,[\"headers\"]],\"eq-container\",\"fluid\",[30,0,[\"sortBy\"]],[30,0,[\"sort\"]],[30,0,[\"sorts\"]]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,[[\"@renderAll\",\"@rows\",\"@rowSelectionMode\",\"@checkboxSelectionMode\",\"@onSelect\"],[[30,0,[\"renderAll\"]],[30,2],\"single\",\"none\",[30,0,[\"selectRow\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"row\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,4,[\"cell\"]],[[16,0,[29,[[52,[30,4,[\"columnValue\",\"style\"]],[30,4,[\"rowValue\",\"statusCSS\"]]]]]]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,4,[\"columnValue\",\"locales\"]],[[[1,\"        \"],[10,0],[14,0,\"challenge_languages\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,4,[\"rowValue\",\"locales\"]]],null]],null],null,[[[1,\"            \"],[10,\"i\"],[15,0,[29,[[28,[37,4],[[30,6]],null],\" flag\"]]],[15,\"title\",[29,[[30,6]]]],[12],[13],[1,\"\\n\"]],[6]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[30,5]],[1,\"\\n\"]],[]]],[1,\"      \"]],[5]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"t\",\"@list\",\"b\",\"r\",\"value\",\"language\"],false,[\"ember-table\",\"if\",\"each\",\"-track-array\",\"convert-language-as-flag\"]]",
    "moduleName": "pixeditor/components/list/alternatives.hbs",
    "isStrictMode": false
  });

  let AlternativesList = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class AlternativesList extends _sorted.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "headers", [{
        name: 'Indice',
        valuePath: 'alternativeVersion',
        maxWidth: 50
      }, {
        name: 'Consigne',
        valuePath: 'instruction'
      }, {
        name: 'Langue(s)',
        valuePath: 'locales',
        maxWidth: 80,
        minWidth: 75,
        locales: true
      }, {
        name: 'Auteur',
        valuePath: 'author',
        maxWidth: 80
      }, {
        name: 'Statut',
        valuePath: 'status',
        maxWidth: 130,
        style: true
      }]);
    }

    selectRow(row) {
      this.router.transitionTo('competence.prototypes.single.alternatives.single', row);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectRow", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectRow"), _class.prototype)), _class));
  _exports.default = AlternativesList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AlternativesList);
});