define("pixeditor/templates/statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template(
  /*
    <div class="main-left">
    <div class="main-title">
      <h1 class="ui header">Statistiques</h1>
    </div>
    <div class="ui fluid container statistics">
      <div class="ui two column padded grid">
        <div class="column">
          <Statistics::Production @areas={{this.model}} @competenceCodes={{this.competenceCodes}}/>
        </div>
        <div class="column">
          <Statistics::I18n @areas={{this.model}}  @competenceCodes={{this.competenceCodes}}/>
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "r9E84Q2V",
    "block": "[[[10,0],[14,0,\"main-left\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"main-title\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"ui header\"],[12],[1,\"Statistiques\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ui fluid container statistics\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ui two column padded grid\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"column\"],[12],[1,\"\\n        \"],[8,[39,0],null,[[\"@areas\",\"@competenceCodes\"],[[30,0,[\"model\"]],[30,0,[\"competenceCodes\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"column\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@areas\",\"@competenceCodes\"],[[30,0,[\"model\"]],[30,0,[\"competenceCodes\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"statistics/production\",\"statistics/i18n\"]]",
    "moduleName": "pixeditor/templates/statistics.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});