define("pixeditor/adapters/challenge", ["exports", "pixeditor/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ChallengeAdapter extends _application.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "coalesceFindRequests", true);
    }

    groupRecordsForFindMany(store, snapshots) {
      const groups = [];

      for (let i = 0; i < snapshots.length; i += 100) {
        groups.push(snapshots.slice(i, i + 100));
      }

      return groups;
    }

    async findMany(store, type, ids, snapshots) {
      const url = this.buildURL(type.modelName, ids, snapshots, 'findMany');
      return this.ajax(url, 'GET', {
        data: {
          filter: {
            ids
          }
        }
      });
    }

  }

  _exports.default = ChallengeAdapter;
});