define("pixeditor/serializers/skill", ["exports", "pixeditor/serializers/airtable"], function (_exports, _airtable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class SkillSerializer extends _airtable.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "primaryKey", 'Record Id');

      _defineProperty(this, "attrs", {
        name: 'Nom',
        clue: 'Indice fr-fr',
        clueEn: 'Indice en-us',
        clueStatus: 'Statut de l\'indice',
        challenges: 'Epreuves (id persistant)',
        createdAt: 'Date',
        description: 'Description',
        descriptionStatus: 'Statut de la description',
        tutoSolution: 'Comprendre',
        tutoMore: 'En savoir plus',
        tube: 'Tube',
        level: 'Level',
        status: 'Status',
        i18n: 'Internationalisation',
        pixId: 'id persistant',
        version: 'Version'
      });
    }

    payloadKeyFromModelName() {
      return 'Acquis';
    }

  }

  _exports.default = SkillSerializer;
});