define("pixeditor/components/target-profile/area-profile", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.filteredCompetences as |competence|}}
    <TargetProfile::CompetenceProfile @competence={{competence}}
                                      @areaCode={{@area.code}}
                                      @displayTube={{@displayTube}}
                                      @level={{@level}}
                                      @selectedSkills={{@selectedSkills}}
                                      @showTubeDetails={{@showTubeDetails}}
                                      @clearTube={{@clearTube}}
                                      @setTubeLevel={{@setTubeLevel}}
                                      @filter={{@filter}}
                                      @isThematicResultMode={{@isThematicResultMode}}
                                      @displayThematicResultTube={{@displayThematicResultTube}}/>
  {{/each}}
  
  */
  {
    "id": "G38Je5JT",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"filteredCompetences\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@competence\",\"@areaCode\",\"@displayTube\",\"@level\",\"@selectedSkills\",\"@showTubeDetails\",\"@clearTube\",\"@setTubeLevel\",\"@filter\",\"@isThematicResultMode\",\"@displayThematicResultTube\"],[[30,1],[30,2,[\"code\"]],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11]]],null],[1,\"\\n\"]],[1]],null]],[\"competence\",\"@area\",\"@displayTube\",\"@level\",\"@selectedSkills\",\"@showTubeDetails\",\"@clearTube\",\"@setTubeLevel\",\"@filter\",\"@isThematicResultMode\",\"@displayThematicResultTube\"],false,[\"each\",\"-track-array\",\"target-profile/competence-profile\"]]",
    "moduleName": "pixeditor/components/target-profile/area-profile.hbs",
    "isStrictMode": false
  });

  class AreaProfile extends _component.default {
    get filteredCompetences() {
      const area = this.args.area;

      if (this.args.filter) {
        return area.sortedCompetences.filter(competence => competence.selectedProductionTubeCount > 0);
      }

      return area.sortedCompetences;
    }

  }

  _exports.default = AreaProfile;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AreaProfile);
});