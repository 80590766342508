define("pixeditor/components/field/checkbox", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="{{concat "ui checkbox" (if @toggle " toggle" "")}}" ...attributes>
    <Input id={{this.elementId}} @type="checkbox" @checked={{@checked}} disabled={{@disabled}} />
    <label for={{this.elementId}} >
      {{@label}}
    </label>
  </div>
  
  */
  {
    "id": "wesfoqj3",
    "block": "[[[11,0],[16,0,[29,[[28,[37,0],[\"ui checkbox\",[52,[30,1],\" toggle\",\"\"]],null]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,2],[[16,1,[30,0,[\"elementId\"]]],[16,\"disabled\",[30,3]]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,4]]],null],[1,\"\\n  \"],[10,\"label\"],[15,\"for\",[30,0,[\"elementId\"]]],[12],[1,\"\\n    \"],[1,[30,5]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@toggle\",\"&attrs\",\"@disabled\",\"@checked\",\"@label\"],false,[\"concat\",\"if\",\"input\"]]",
    "moduleName": "pixeditor/components/field/checkbox.hbs",
    "isStrictMode": false
  });

  class Checkbox extends _component.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "ignorableAttrs", ['checked', 'label', 'disabled']);

      _defineProperty(this, "elementId", 'checkbox-' + Ember.guidFor(this));
    }

  }

  _exports.default = Checkbox;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Checkbox);
});