define("pixeditor/controllers/statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class StatisticsController extends Ember.Controller {
    get competenceCodes() {
      return this.model.reduce((current, area) => {
        current.push(area.sortedCompetences.map(competence => competence.code));
        return current;
      }, []).flat();
    }

  }

  _exports.default = StatisticsController;
});