define("pixeditor/stories/pix-return-to.stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.returnToWithText = _exports.returnTo = _exports.argTypes = void 0;

  const returnTo = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixReturnTo @route='profile' @shade={{shade}} />
          
      */
      {
        "id": "m+jY1Fih",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@shade\"],[\"profile\",[36,1]]],null],[1,\"\\n    \"]],[],false,[\"pix-return-to\",\"shade\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.returnTo = returnTo;
  returnTo.args = {
    shade: 'blue'
  };

  const returnToWithText = args => {
    return {
      template: Ember.HTMLBars.template(
      /*
        
            <PixReturnTo @route='profile' @shade={{shade}}>
              Retour vers mon profil
            </PixReturnTo>
          
      */
      {
        "id": "E0LrWBCU",
        "block": "[[[1,\"\\n      \"],[8,[39,0],null,[[\"@route\",\"@shade\"],[\"profile\",[36,1]]],[[\"default\"],[[[[1,\"\\n        Retour vers mon profil\\n      \"]],[]]]]],[1,\"\\n    \"]],[],false,[\"pix-return-to\",\"shade\"]]",
        "moduleName": "(unknown template module)",
        "isStrictMode": false
      }),
      context: args
    };
  };

  _exports.returnToWithText = returnToWithText;
  const argTypes = {
    route: {
      name: 'route',
      description: 'Route de redirection',
      type: {
        name: 'string',
        required: true
      },
      defaultValue: null
    },
    model: {
      name: 'model',
      description: 'Model Ember',
      type: {
        required: false
      }
    },
    shade: {
      name: 'shade',
      description: 'Couleur du lien',
      type: {
        name: 'string',
        required: false
      },
      defaultValue: 'black',
      control: {
        type: 'select',
        options: ['white', 'black', 'blue']
      }
    }
  };
  _exports.argTypes = argTypes;
});