define("pixeditor/components/list/workbench", ["exports", "pixeditor/components/list/sorted"], function (_exports, _sorted) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <EmberTable as |t|>
    <t.head
      @columns={{this.headers}}
      @widthConstraint={{'eq-container'}}
      @resizeMode={{'fluid'}}
      @onUpdateSorts={{this.sortBy}}
      @sorts={{this.sorts}}
      @sortFunction={{this.sort}}/>
    <t.body
      @renderAll={{this.renderAll}}
      @rows={{@list}}
      @rowSelectionMode={{'single'}}
      @checkboxSelectionMode={{'none'}}
      @onSelect={{this.selectRow}}
      @selection={{this.current}} as |b|>
      <b.row as |r|>
        <r.cell class="{{if r.columnValue.style r.rowValue.statusCSS}}" as |value|>
          {{value}}
        </r.cell>
      </b.row>
    </t.body>
  </EmberTable>
  
  */
  {
    "id": "/qrvRrN0",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"head\"]],null,[[\"@columns\",\"@widthConstraint\",\"@resizeMode\",\"@onUpdateSorts\",\"@sorts\",\"@sortFunction\"],[[30,0,[\"headers\"]],\"eq-container\",\"fluid\",[30,0,[\"sortBy\"]],[30,0,[\"sorts\"]],[30,0,[\"sort\"]]]],null],[1,\"\\n  \"],[8,[30,1,[\"body\"]],null,[[\"@renderAll\",\"@rows\",\"@rowSelectionMode\",\"@checkboxSelectionMode\",\"@onSelect\",\"@selection\"],[[30,0,[\"renderAll\"]],[30,2],\"single\",\"none\",[30,0,[\"selectRow\"]],[30,0,[\"current\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,3,[\"row\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,4,[\"cell\"]],[[16,0,[29,[[52,[30,4,[\"columnValue\",\"style\"]],[30,4,[\"rowValue\",\"statusCSS\"]]]]]]],null,[[\"default\"],[[[[1,\"\\n        \"],[1,[30,5]],[1,\"\\n      \"]],[5]]]]],[1,\"\\n    \"]],[4]]]]],[1,\"\\n  \"]],[3]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"t\",\"@list\",\"b\",\"r\",\"value\"],false,[\"ember-table\",\"if\"]]",
    "moduleName": "pixeditor/components/list/workbench.hbs",
    "isStrictMode": false
  });

  let CompetencesList = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, (_class = class CompetencesList extends _sorted.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "currentData", _descriptor2, this);

      _defineProperty(this, "headers", [{
        name: 'Auteur',
        valuePath: 'authorText',
        maxWidth: 150
      }, {
        name: 'Consigne',
        valuePath: 'instruction'
      }, {
        name: 'Type',
        valuePath: 'type',
        maxWidth: 150
      }, {
        name: 'Statut',
        valuePath: 'status',
        maxWidth: 150,
        style: true
      }]);

      _defineProperty(this, "sortTypes", {
        'authorText': 'string',
        'instruction': 'string',
        'type': 'string',
        'status': 'string'
      });
    }

    selectRow(row) {
      this.router.transitionTo(this.args.link, this.args.competenceModel, row);
    }

    get current() {
      return this.currentData.getPrototype();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentData", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectRow", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "selectRow"), _class.prototype)), _class));
  _exports.default = CompetencesList;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CompetencesList);
});