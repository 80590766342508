define("pixeditor/components/field/toggle-field", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @edition}}
    <div class="ui text menu note-menu">
      <button data-test-toggle-field-button class="ui button item" {{on "click" this.toggleFieldDisplay}} type="button">
        <i class="{{this.buttonIcon}} icon"></i>
        {{this.buttonTitle}}
      </button>
    </div>
  {{/if}}
  {{#if this.shouldDisplayField}}
    {{yield}}
  {{/if}}
  
  */
  {
    "id": "2haqAvHS",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,0],[14,0,\"ui text menu note-menu\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,\"data-test-toggle-field-button\",\"\"],[24,0,\"ui button item\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"toggleFieldDisplay\"]]],null],[12],[1,\"\\n      \"],[10,\"i\"],[15,0,[29,[[30,0,[\"buttonIcon\"]],\" icon\"]]],[12],[13],[1,\"\\n      \"],[1,[30,0,[\"buttonTitle\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"shouldDisplayField\"]],[[[1,\"  \"],[18,2,null],[1,\"\\n\"]],[]],null]],[\"@edition\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "pixeditor/components/field/toggle-field.hbs",
    "isStrictMode": false
  });

  let FieldToggleFieldComponent = (_dec = Ember.inject.service, _dec2 = Ember._action, (_class = class FieldToggleFieldComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "confirm", _descriptor, this);
    }

    get shouldDisplayField() {
      return this.args.displayField || !!this.args.model.get(`${this.args.modelField}`);
    }

    get buttonIcon() {
      return this.shouldDisplayField ? 'minus' : 'plus';
    }

    get buttonTitle() {
      return this.shouldDisplayField ? this.args.hideTextButton : this.args.displayTextButton;
    }

    async toggleFieldDisplay() {
      if (this.shouldDisplayField) {
        await this.confirm.ask('Suppression', `Êtes-vous sûr de vouloir supprimer ${this.args.confirmText} ?`);
        this.args.model.set(`${this.args.modelField}`, '');
        this.args.setDisplayField(false);
      } else {
        this.args.setDisplayField(true);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "confirm", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "toggleFieldDisplay", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFieldDisplay"), _class.prototype)), _class));
  _exports.default = FieldToggleFieldComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FieldToggleFieldComponent);
});