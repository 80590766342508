define("pixeditor/components/field/textarea", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{concat "field textArea" (if @edition "" " disabled") (if this.maximized " maximized" "")}} ...attributes>
      <label for="textarea">
          {{#if @edition}}
              <button {{on "click" this.toggleMaximized}} class={{concat "ui compact icon right floated button" (if this.maximized " primary" " basic")}} type="button"><i class={{concat (if this.maximized "compress " "expand ")  "icon"}}></i></button>
              {{#if @helpContent}}
              <div class="ui compact icon right floated button basic">
                <i class="help icon">
                  <EmberTooltip @side="left-start" @tooltipClass="custom-tooltip">
                    {{this.safeHelpContent}}
                  </EmberTooltip>
                </i>
              </div>
              {{/if}}
          {{/if}}
          {{@title}}
      </label>
      <Textarea id="textarea" @value={{@value}} rows="4" readonly={{not @edition}} class="attached" />
  </div>
  
  */
  {
    "id": "OUyghN/g",
    "block": "[[[11,0],[16,0,[28,[37,0],[\"field textArea\",[52,[30,1],\"\",\" disabled\"],[52,[30,0,[\"maximized\"]],\" maximized\",\"\"]],null]],[17,2],[12],[1,\"\\n    \"],[10,\"label\"],[14,\"for\",\"textarea\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[11,\"button\"],[16,0,[28,[37,0],[\"ui compact icon right floated button\",[52,[30,0,[\"maximized\"]],\" primary\",\" basic\"]],null]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"toggleMaximized\"]]],null],[12],[10,\"i\"],[15,0,[28,[37,0],[[52,[30,0,[\"maximized\"]],\"compress \",\"expand \"],\"icon\"],null]],[12],[13],[13],[1,\"\\n\"],[41,[30,3],[[[1,\"            \"],[10,0],[14,0,\"ui compact icon right floated button basic\"],[12],[1,\"\\n              \"],[10,\"i\"],[14,0,\"help icon\"],[12],[1,\"\\n                \"],[8,[39,3],null,[[\"@side\",\"@tooltipClass\"],[\"left-start\",\"custom-tooltip\"]],[[\"default\"],[[[[1,\"\\n                  \"],[1,[30,0,[\"safeHelpContent\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"        \"],[1,[30,4]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],[[24,1,\"textarea\"],[24,\"rows\",\"4\"],[16,\"readonly\",[28,[37,5],[[30,1]],null]],[24,0,\"attached\"]],[[\"@value\"],[[30,5]]],null],[1,\"\"],[13],[1,\"\\n\"]],[\"@edition\",\"&attrs\",\"@helpContent\",\"@title\",\"@value\"],false,[\"concat\",\"if\",\"on\",\"ember-tooltip\",\"textarea\",\"not\"]]",
    "moduleName": "pixeditor/components/field/textarea.hbs",
    "isStrictMode": false
  });

  let Textarea = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class Textarea extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "maximized", _descriptor, this);
    }

    get safeHelpContent() {
      return Ember.String.htmlSafe(this.args.helpContent);
    }

    toggleMaximized() {
      this.maximized = !this.maximized;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "maximized", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleMaximized", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMaximized"), _class.prototype)), _class));
  _exports.default = Textarea;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Textarea);
});